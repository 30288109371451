// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import "config";

function AddYantra() {
  const [inputName, setName] = useState("");
  const [addYantraError, setAddYantraError] = useState("");
  const [formOperation, setFormOperation] = useState("Add Yantra");
  const [inputImage, setImage] = useState("");
  const [nameError, setNameError] = useState("");
  const [yantraDiscription, setYantraDiscription] = useState("");
  const { id } = queryString.parse(location.search);
  const navigate = useNavigate();

  if (id) {
    useEffect(() => {
      axios
        .get(global.config.DATA.domain + "/yantra/edit/" + id)
        .then((response) => {
          if (response.data["status"] == true) {
            setFormOperation("Update Yantra");
            setName(response.data["data"]["name"]);
            setImage(response.data["data"]["image"]);
            setYantraDiscription(response.data["data"]["discription"]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  }

  function updateYantra(event) {
    setNameError("");
    setAddYantraError("");

    if (inputName == "") {
      setNameError("Please Enter Yantra Name");
    } else {
      const formData = new FormData();
      for (let i = 0; i < inputImage.length; i++) {
        formData.append("consultationFiles", inputImage[i]);
      }
      axios
        .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response1) => {
          var imageName = "";
          if (response1.data.data["consultationFiles"]) {
            imageName = response1.data.data["consultationFiles"][0]["filename"];
          }

          if (response1.data["status"] == true) {
            axios
              .put(global.config.DATA.domain + "/yantra/update/" + id, {
                name: inputName,
                image: imageName ? imageName : inputImage,
                discription: yantraDiscription,
              })
              .then((response) => {
                if (response.data["status"] == true) {
                  navigate("/yantra");
                } else {
                  setAddYantraError(response.data["message"]);
                }
              })
              .catch((error) => {
                setAddYantraError("Unable to add Yantra");
              });
          } else {
            setAddYantraError("file not added");
          }
        })
        .catch((error) => {
          console.log(error);
          setAddYantraError("Unable to add file");
        });
    }
  }

  function createYantra(event) {
    setNameError("");
    setAddYantraError("");

    if (inputName == "") {
      setNameError("Please Enter Yantra Name");
    } else {
      const formData = new FormData();
      for (let i = 0; i < inputImage.length; i++) {
        formData.append("consultationFiles", inputImage[i]);
      }
      axios
        .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response1) => {
          var imageName = "";
          if (response1.data.data["consultationFiles"]) {
            imageName = response1.data.data["consultationFiles"][0]["filename"];
          }
          if (response1.data["status"] == true) {
            axios
              .post(global.config.DATA.domain + "/yantra/create", {
                name: inputName,
                image: imageName,
                discription: yantraDiscription,
              })
              .then((response) => {
                if (response.data["status"] == true) {
                  navigate("/yantra");
                } else {
                  setAddYantraError(response.data["message"]);
                }
              })
              .catch((error) => {
                setAddYantraError("Unable to add Yantra");
              });
          } else {
            setAddYantraError("file not added");
          }
        })
        .catch((error) => {
          console.log(error);
          setAddYantraError("Unable to add file");
        });
    }
  }

  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Card sx={{ height: "100%" }}>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
              px={2}
            >
              <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Add Yantra&apos;s
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox pt={3} pb={2} px={2}>
              <Card style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                  paddingTop="10px"
                  style={{
                    backgroundColor: "#fee1d9",
                    padding: "15px",
                    fontSize: "14px",
                    color: "#000",
                  }}
                >
                  Yantra Details
                </ArgonTypography>
                <Card style={{ padding: "30px", marginBottom: "15px" }}>
                  <Grid container columnSpacing={3} rowSpacing={0}>
                    <Grid item xs={12} sm={6}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Yantra Name *
                        </ArgonTypography>
                        <ArgonInput
                          type="name"
                          placeholder="Enter Yantra Name"
                          size="large"
                          value={inputName}
                          onChange={(event) => {
                            setName(event.target.value), setNameError("");
                          }}
                        />
                        <p style={{ color: "red", fontSize: "12px" }}>{nameError}</p>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Upload Image
                        </ArgonTypography>
                        <ArgonInput
                          type="file"
                          placeholder="Company Name"
                          size="large"
                          // value={inputImage}
                          onChange={(event) => setImage(event.target.files)}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Yantra Discription
                        </ArgonTypography>
                        <textarea
                          id="outlined-multiline-static"
                          label=""
                          multiline
                          rows={6}
                          placeholder="Yantra Discription"
                          size="large"
                          value={yantraDiscription}
                          fullWidth={true}
                          onChange={(event) => {
                            setYantraDiscription(event.target.value);
                          }}
                          inputProps={{ style: { width: "100% !important", padding: 8 } }}
                          style={{
                            fontFamily: "Open Sans, sans-serif",
                            width: "100% !important",
                            padding: "8px",
                            borderColor: "#E9EBED",
                            marginTop: 8,
                            borderRadius: 7,
                            borderWidth: "2px",
                          }}
                        />
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </Card>
              </Card>
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={1}
                m={0}
                sx={{ listStyle: "none" }}
              >
                <ArgonBox component="form" role="form">
                  <ArgonBox mt={4} mb={3} justifyContent="center">
                    <ArgonButton
                      color="info"
                      size="large"
                      onClick={formOperation == "Add Yantra" ? createYantra : updateYantra}
                    >
                      {formOperation}
                    </ArgonButton>
                    <p style={{ color: "red", fontSize: "12px" }}>{addYantraError}</p>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </Card>
          {/* </Grid> */}
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default AddYantra;
