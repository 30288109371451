// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-wuhq1r-MuiInputBase-input-MuiOutlinedInput-input{
    width: 100% !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-content{
width:92% !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-icon{
    left: 0% !important;
}

.vertical-timeline.vertical-timeline--two-columns:before{
    left: 0% !important;
}

.css-k2w9po-MuiTypography-root{
    font-family:"Public Sans"!important;
    font-weight: 600 !important;
    line-height:0 !important;
    color:"inherit" !important
}

/* .css-mf5c9z-MuiTypography-root {
    font-family:"Public Sans"!important;
    font-weight: 600 !important;
} */`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;AACA,oBAAoB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mCAAmC;IACnC,2BAA2B;IAC3B,wBAAwB;IACxB;AACJ;;AAEA;;;GAGG","sourcesContent":[".css-wuhq1r-MuiInputBase-input-MuiOutlinedInput-input{\n    width: 100% !important;\n}\n\n.vertical-timeline--two-columns .vertical-timeline-element-content{\nwidth:92% !important;\n}\n\n.vertical-timeline--two-columns .vertical-timeline-element-icon{\n    left: 0% !important;\n}\n\n.vertical-timeline.vertical-timeline--two-columns:before{\n    left: 0% !important;\n}\n\n.css-k2w9po-MuiTypography-root{\n    font-family:\"Public Sans\"!important;\n    font-weight: 600 !important;\n    line-height:0 !important;\n    color:\"inherit\" !important\n}\n\n/* .css-mf5c9z-MuiTypography-root {\n    font-family:\"Public Sans\"!important;\n    font-weight: 600 !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
