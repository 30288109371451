import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavFooter from "examples/Sidenav/SidenavFooter";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import logo from "assets/images/kulkarni-1.png";

// Argon Dashboard 2 MUI context
import { useArgonController, setMiniSidenav } from "context";
import { textAlign } from "@mui/system";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, darkSidenav, layout } = controller;
  const location = useLocation();
  const { pathname } = location;
  const itemName = pathname.split("/").slice(1)[0];
  const { sidenavColor } = controller;

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);
  const [showReportDropDown, setShowReportDropDown] = useState(false);
  const [showCMSDropDown, setShowCMSDropDown] = useState(false);
  const [showTotalProfitDropDown, setShowTotalProfitDropDown] = useState(false);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, key, href, route }) => {
    let returnValue;

    if (type === "route") {
      if (href) {
        returnValue = (
          <Link href={href} key={key} target="_blank" rel="noreferrer">
            <SidenavItem
              name={name}
              icon={icon}
              active={key === itemName}
              noCollapse={noCollapse}
            />
          </Link>
        );
      } else {
        // f(showReportDropDown)
        returnValue = (
          <NavLink to={route} key={key}>
            {showReportDropDown &&
            (name == "Numerology" ||
              name == "Astrology" ||
              name == "Vastu" ||
              name == "Tarot Card") ? (
              <SidenavItem name={name} icon={icon} active={key === itemName} />
            ) : null}
            {showCMSDropDown && (name == "Gemstone" || name == "Yantra" || name == "Crystal" || name=="Class Banner") ? (
              <SidenavItem name={name} icon={icon} active={key === itemName} />
            ) : null}
            {showTotalProfitDropDown && (name == "Consultation Report" || name == "Profit Report" || name == "Individual Profit") ? (
              <SidenavItem name={name} icon={icon} active={key === itemName} />
            ) : null}
            {name == "Dashboard" ||
            name == "Admins" ||
            name == "Customers" ||
            name == "Add Customer" ||
            name == "Blogs" ||
            name == "Awards" ||
            name == "Profit" ? (
              <SidenavItem name={name} icon={icon} active={key === itemName} />
            ) : null}
          </NavLink>
        );
      }
    } else if (type === "title") {
      returnValue = (
        <ArgonBox style={{ paddingBottom: "17px" }}>
          <ArgonTypography
            key={key}
            color={darkSidenav ? "white" : "dark"}
            display="block"
            variant="caption"
            opacity={0.6}
            onClick={() => {
              title == "CMS"
                ? setShowCMSDropDown(!showCMSDropDown)
                : setShowReportDropDown(!showReportDropDown);
            }}
            marginLeft="35px"
            marginTop="20px"
            fontWeight="400"
          >
            <Icon style={{ fontSize: "30px" }}>insert_drive_file</Icon>
            <span
              style={{
                marginLeft: "0.7em",
                marginRight: "0.5em",
                fontFamily: "Public Sans",
                fontSize: "15px",
              fontWeight:"bold"
              }}
            >
              {title}
            </span>
            {title == "CMS" ? (
              showCMSDropDown ? (
                <Icon style={{ fontSize: "30px !important" }}>remove </Icon>
              ) : (
                <Icon style={{ fontSize: "30px !important" }}>add</Icon>
              )
            ) : showReportDropDown ? (
              <Icon style={{ fontSize: "30px !important" }}>remove </Icon>
            ) : (
              <Icon style={{ fontSize: "30px !important" }}>add</Icon>
            )}
          </ArgonTypography>
        </ArgonBox>
      );
    } else if (type === "title1") {
      returnValue = (
        <ArgonBox style={{ paddingBottom: "17px" }}>
          <ArgonTypography
            key={key}
            color={darkSidenav ? "white" : "dark"}
            display="block"
            variant="caption"
            opacity={0.6}
            onClick={() => {
              title == "Total Profit"
                ? setShowTotalProfitDropDown(!showTotalProfitDropDown)
                : setShowReportDropDown(!showReportDropDown);
            }}
            marginLeft="35px"
            marginTop="20px"
            fontWeight="400"
          >
            <Icon style={{ fontSize: "30px" }}>insert_drive_file</Icon>
            <span
              style={{
                marginLeft: "0.7em",
                marginRight: "0.5em",
                fontFamily: "Public Sans",
                fontSize: "15px",
              fontWeight:"bold"              }}
            >
              {title}
            </span>
            {title == "Total Profit" ? (
              showTotalProfitDropDown ? (
                <Icon style={{ fontSize: "30px !important" }}>remove </Icon>
              ) : (
                <Icon style={{ fontSize: "30px !important" }}>add</Icon>
              )
            ) : showReportDropDown ? (
              <Icon style={{ fontSize: "30px !important" }}>remove </Icon>
            ) : (
              <Icon style={{ fontSize: "30px !important" }}>add</Icon>
            )}
          </ArgonTypography>
        </ArgonBox>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} light={darkSidenav} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ darkSidenav, miniSidenav, layout }}>
      <ArgonBox pt={3} pb={1} px={4} textAlign="center">
        <ArgonBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <ArgonTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox component={NavLink} to="/dashboard" display="flex" alignItems="center">
          {/* {brand && (
            <ArgonBox component="img" src={brand} alt="Argon Logo" width="2rem" mr={0.25} />
          )} */}
          <ArgonBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            {/* <ArgonTypography
              fontSize="25px"
              component="h6"
              variant="button"
              fontWeight="medium"
              color={darkSidenav ? "white" : "dark"}
            >
              {brandName}
            </ArgonTypography> */}
            <span style={{color:"#ec4400",fontSize:23}}>Kulkarnni.com</span>
            {/* <img src={logo} style={{ width: "170px", objectFit: "contain" }} /> */}
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
      <Divider light={darkSidenav} />
      <List>{renderRoutes}</List>

      <ArgonBox pt={1} mt="auto" mb={2} mx={2}>
        <SidenavFooter />
      </ArgonBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
