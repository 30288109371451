import { useNavigate, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

// @mui material components
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import "config";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
// Image

// import signinImage from "assets/images/sign-in.png";
import signinImage from "assets/images/meditation.webp";
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg";

function Illustration() {
  document.title = "Sign In";
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [inputEmail, setEmail] = useState("");
  const [inputPassword, setPassword] = useState("");
  const [emailError, setemailError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [loginError, setloginError] = useState("");
  const navigate = useNavigate();
  var md5 = require("md5");

  function handleEmailChange(event) {
    setEmail(event.target.value);
    if (inputEmail != "") {
      setemailError("");
    }
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
    if (inputPassword != "") {
      setpasswordError("");
    }
  }
  function handleKeyPress(event){
    if(event.key === 'Enter'){
      handleSubmit();
    }
  }

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const [checklocalstorege, setchecklocalstorege] = useState(true);
  if (checklocalstorege) {
    setchecklocalstorege(false);
    const loggedInEmail = localStorage.getItem("loggedInEmail");
    const loggedInPassword = localStorage.getItem("loggedInPassword");
    if (loggedInEmail != null && loggedInPassword != null) {
      setEmail(loggedInEmail);
      setPassword(loggedInPassword);
      // handleSubmit();
    }
  }
  
  function handleSubmit() {
    setemailError("");
    setloginError("");
    setpasswordError("");
    if (validateEmail(inputEmail) && inputPassword != "") {
      axios
        .post(global.config.DATA.domain + "/admins/login", {
          email: inputEmail,
          password: md5(inputPassword),
        })
        .then((response) => {
          if (response.data["status"] == true) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("loggedInEmail", inputEmail);
            localStorage.setItem("loggedInPassword", inputPassword);
            navigate("/dashboard");
          } else {
            setloginError("Invalid Email or Password");
          }
        })
        .catch((error) => {
          setloginError("Invalid Email or Password");
        });
    } else {
      // Email is not valid, show an error message
      if (inputEmail == "") {
        setemailError("Email Required");
      } else if (!validateEmail(inputEmail)) {
        setemailError("Invalid Email");
      }
      if (inputPassword == "") {
        setpasswordError("Password  Required");
      }
    }
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter email and password to sign in"
      illustration={{
        image: signinImage,
        title: 'Kulkarnni.com',
        description:
          "Kulkarnni.com is Pune's one-stop solution for all your questions about Numerology, Astrology, Tarot card readings, and Vaastu Consultancy.",
      }}
    >
      <ArgonBox component="form" role="form">
        <ArgonBox mb={2}>
          <ArgonInput
            type="email"
            placeholder="Email"
            size="large"
            value={inputEmail}
            onChange={handleEmailChange}
          />
          <p style={{ color: "red", fontSize: "12px" }}>{emailError}</p>
        </ArgonBox>

        <ArgonBox mb={2}>
          <ArgonInput
            type="password"
            placeholder="Password"
            size="large"
            value={inputPassword}
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress} 
          />
          <p style={{ color: "red", fontSize: "12px" }}>{passwordError}</p>
        </ArgonBox>


        <ArgonBox mt={4} mb={1}>
          <ArgonButton color="info" size="large" fullWidth onClick={handleSubmit}>
            Sign In
          </ArgonButton>
          <p style={{ color: "red", fontSize: "12px" }}>{loginError}</p>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

export default Illustration;
