// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import React, { useState, useEffect } from "react";
import axios from "axios";

// Images and Icons
import numerologyImage from "assets/images/numerology.png";
import astrologyImage from "assets/images/astrology.png";
import tarotCardImage from "assets/images/tarot-card.png";
import vastuImage from "assets/images/vastu.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CallIcon from '@mui/icons-material/Call';
import SchoolIcon from "@mui/icons-material/School";

function Default() {
  const colors = global.config.COLORS;
  const [totalCustomer, setTotalCustomer] = useState([]);
  const [totalNumerology, setTotalNumerology] = useState([]);
  const [totalAstrology, setTotalAstrology] = useState([]);
  const [totalTarotCard, setTotalTarotCard] = useState([]);
  const [totalVastu, setTotalVastu] = useState([]);
  const [totalReceived, setTotalReceived] = useState([]);
  const [totalPending, setTotalPending] = useState([]);
  const [totalTraining, setTotalTraining] = useState([]);
  const [totalTrainingPending, setTotalTrainingPending] = useState([]);
  const [totalTrainingAmount, setTotalTrainingAmount] = useState([]);
  const [totalContact, setTotalContact] = useState([]);

  // API to count total Received and pending
  useEffect(() => {
    axios
      .get(global.config.DATA.domain + "/dashboard")
      .then((response) => {
        setTotalNumerology(response.data.data["numerology"]);
        setTotalAstrology(response.data.data["astrology"]);
        setTotalTarotCard(response.data.data["tarotCards"]);
        setTotalVastu(response.data.data["vastu"]);
        setTotalReceived(response.data.data["totalBill"]);
        setTotalPending(response.data.data["totalPending"]);
        setTotalCustomer(response.data.data["totalCustomers"]);
        setTotalTraining(response.data.data["totalTrainingCount"]);
        setTotalTrainingAmount(response.data.data["totalTrainingAmount"]);
        setTotalTrainingPending(response.data.data["totalTrainingPending"]);
        setTotalContact(response.data.data["totalContacts"]);
      })
      .catch((error) => {});
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Customers"
              count={totalCustomer}
              icon={{
                color: "secondary",
                component: (
                  <PeopleAltIcon style={{ width: "30px", height: "30px" }}></PeopleAltIcon>
                ),
              }}
              percentage={{ linkk: "/customers", text: "" }}
              countColor={0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Consultation Amount"
              count={totalReceived}
              icon={{
                color: "success",
                component: (
                  <CurrencyRupeeIcon style={{ width: "30px", height: "30px" }}></CurrencyRupeeIcon>
                ),
              }}
              percentage={{ linkk: "/customers", text: "" }}
              countColor={1}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Pending Amount"
              count={totalPending}
              icon={{
                color: "error",
                component: (
                  <CurrencyRupeeIcon style={{ width: "30px", height: "30px" }}></CurrencyRupeeIcon>
                ),
              }}
              percentage={{ linkk: "/customers", text: "" }}
              countColor={2}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Website Contact Request"
              count={totalContact}
              icon={{
                color: "secondary",
                component: (
                  <PeopleAltIcon style={{ width: "30px", height: "30px" }}></PeopleAltIcon>
                ),
              }}
              percentage={{ linkk: "/contacts", text: "" }}
              countColor={0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Numerology"
              count={totalNumerology}
              icon={{
                color: "info",
                component: <img src={numerologyImage} style={{ width: "40px", height: "40px" }} />,
              }}
              percentage={{ linkk: "/ViewNumerology", text: "" }}
              countColor={0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Astrology"
              count={totalAstrology}
              icon={{
                color: "error",
                component: <img src={astrologyImage} style={{ width: "40px", height: "40px" }} />,
              }}
              percentage={{ linkk: "/ViewAstrology", text: "" }}
              countColor={0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Tarot Card"
              count={totalTarotCard}
              icon={{
                color: "success",
                component: <img src={tarotCardImage} style={{ width: "40px", height: "40px" }} />,
              }}
              percentage={{ linkk: "/ViewTarotCard", text: "" }}
              countColor={0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Vastu"
              count={totalVastu}
              icon={{
                color: "warning",
                component: <img src={vastuImage} style={{ width: "40px", height: "40px" }} />,
              }}
              percentage={{ linkk: "/ViewVastu", text: "" }}
              countColor={0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Training"
              count={totalTraining}
              icon={{
                color: "primary",
                component: <SchoolIcon style={{ width: "30px", height: "30px" }}></SchoolIcon>,
              }}
              percentage={{ linkk: "/training", text: "" }}
              countColor={0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Training Amount"
              count={totalTrainingAmount}
              icon={{
                color: "warning",
                component: <CurrencyRupeeIcon style={{ width: "30px", height: "30px" }}></CurrencyRupeeIcon>,
              }}
              percentage={{ linkk: "/training", text: "" }}
              countColor={0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Pending Training Amount"
              count={totalTrainingPending}
              icon={{
                color: "dark",
                component: <CurrencyRupeeIcon style={{ width: "30px", height: "30px" }}></CurrencyRupeeIcon>,
              }}
              percentage={{ linkk: "/training", text: "" }}
              countColor={0}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Contact"
              count={totalContact}
              icon={{
                color: "dark",
                component: <CallIcon style={{ width: "30px", height: "30px" }}></CallIcon>,
              }}
              percentage={{ linkk: "/contacts", text: "" }}
              countColor={0}
            />
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
