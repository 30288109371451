import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { Icon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import queryString from "query-string";
import ReactSelect from "react-select";
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import { Checkbox, MenuItem, ListItemText } from "@mui/material";
import { Country, State, City } from "country-state-city";

import "assets/css/style.css";
import "config";

function Consultation() {
  const [whichFlag, setwhichFlag] = useState("");
  const [submitOperation, setSubmitOperation] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [previousSelectedState, setPreviousSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCities, setSelectedCities] = useState("");
  const getstate = State.getStatesOfCountry("IN");
  const [stateOptions, getStateOption] = useState(getstate);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const countrys = Country.getAllCountries();
  const [countryCode, setCountryCode] = useState("IN");
  const [stateCode, setStateCode] = useState("");
  const [givenName, setGivenName] = useState("");
  const [givenMiddleName, setGivenMiddleName] = useState("");
  const [givenLastName, setGivenLastName] = useState("");
  const [consultationDate, setConsultationDate] = useState(new Date().toISOString().split("T")[0]);
  const [topicOfVisit, setTopicOfVisit] = useState("Select");
  const [specifyTopicOfVisit, setSpecifyTopicOfVisit] = useState("");
  const [remedies, setRemedies] = useState("");
  const [meetingNotes, setMeetingNotes] = useState("");
  const [consultationCharge, setconsultationCharge] = useState();
  const [place, setPlace] = useState();
  const [address, setAddress] = useState();
  const [totalBill, setTotalBill] = useState(0);
  const [consultationChargeReceived, setconsultationChargeReceived] = useState();
  const [pastConsultationChargeReceived, setPastConsultationChargeReceived] = useState(0);
  const [consultationChargeReceivedList, setconsultationChargeReceivedList] = useState([]);
  const [amountReceivedList, setAmountReceivedList] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [uploadImagesList, setUploadImagesList] = useState([]);
  const [amountReceivedDate, setAmountReceivedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [formOperation, setFormOperation] = useState("Add Customer");
  const [addCustomerError, setAddCustomerError] = useState("");
  const [consultationChargeError, setconsultationChargeError] = useState("");
  const [dateChangeError, setDateChangeError] = useState("");
  const [birthDateChangeError, setBirthDateChangeError] = useState("");

  // only for display
  const [inputFirstName, setFirstName] = useState("");
  const [inputMiddleName, setMiddleName] = useState("");
  const [inputLastName, setLastName] = useState("");
  const [birthPlaceManual, setBirthPlaceManual] = useState("");
  const [inputBirthDate, setBirthDate] = useState("");
  const [inputBirthTime, setBirthTime] = useState("");
  const [inputBirthPlace, setBirthPlace] = useState("");

  const [gemstones, setGemstones] = useState([]);
  const [gemstonesList, setGemstonesList] = useState([]);
  const [gemstonesDict, setGemstonesDict] = useState([]);

  const [yantras, setYantras] = useState([]);
  const [yantrasList, setYantrasList] = useState([]);
  const [yantraDict, setYantraDict] = useState([]);

  const [crystals, setCrystals] = useState([]);
  const [crystalsList, setCrystalsList] = useState([]);
  const [crystalDict, setCrystalDict] = useState([]);

  const [showHistory, setShowHistory] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showGemstone, setShowGemstone] = useState(false);
  const [showYantra, setShowYantra] = useState(false);
  const [showCrystal, setShowCrystal] = useState(false);
  const [topicOfVisitError, setTopicOfVisitError] = useState("");
  const navigate = useNavigate();

  const { id } = queryString.parse(location.search);
  const { cid } = queryString.parse(location.search);
  const { type } = queryString.parse(location.search);

  const [isVisitOpen, setIsVisitOpen] = useState(false);
  const [isGemstoneOpen, setIsGemstoneOpen] = useState(false);
  const [isYantraOpen, setIsYantraOpen] = useState(false);
  const [isCrystalOpen, setIsCrystalOpen] = useState(false);
  const [editDisableDetails, setEditDisableDetails] = useState(true);

  const [displayCountry, setdisplayCountry] = useState(false);
  const [displayState, setdisplayState] = useState(false);
  const [displayCity, setdisplayCity] = useState(false);

  const [kundaliResponse, setKundaliResponse] = useState();
  const [inputKundali, setKundali] = useState([]);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [consultationChargeReceivedError, setconsultationChargeReceivedError] = useState("");

  const handleCountryChange = (country) => {
    const [countryName, countryCode] = country.value.toString().split("//");
    setSelectedCountry(countryName);
    setCountryCode(countryCode);
    const states = State.getStatesOfCountry(countryCode);
    getStateOption(states);
    setSelectedState("");
  };

  const handleStateChange = (state) => {
    const [stateName, stateCode] = state.value.toString().split("//");
    setSelectedState(stateName);
    setStateCode(stateCode);
    const cityOptions = City.getCitiesOfState(countryCode, stateCode).map((city) => ({
      name: city.name,
      label: city.name,
    }));
    setSelectedCities(cityOptions);
    setSelectedCity("");
  };

  const handleCityChange = (city) => {
    setSelectedCity(city.label);
  };

  useEffect(() => {
    document.title = "Add Consultation";
    var loadAllCities = City.getCitiesOfState("IN", "MH").map((city) => ({
      name: city.name,
      label: city.name,
    }));
    setSelectedCities(loadAllCities);
  }, []);

  // Get all gemstone
  useEffect(() => {
    axios
      .get(global.config.DATA.domain + "/gemstone")
      .then((response) => {
        const glist = [];
        if (response.data["status"] == true) {
          response.data["data"].map((item) => {
            glist.push(item["name"]);
            gemstonesDict[item["name"]] = {
              itemId: item["_id"],
              purchasePrice: "",
              salePrice: "",
              makingCharges: "0",
              gemstoneType: "Select",
              isSold: false,
            };
          });
          setGemstonesList(glist);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    gemstonesList.map((item) => {
      if (!gemstones.includes(item)) {
        gemstonesDict[item] = {
          itemId: gemstonesDict[item]["itemId"],
          purchasePrice: "",
          salePrice: "",
          makingCharges: "0",
          gemstoneType: "Select",
          isSold: false,
        };
      }
    });
  }, [gemstones]);

  // Get all yantra
  useEffect(() => {
    axios
      .get(global.config.DATA.domain + "/yantra")
      .then((response) => {
        const ylist = [];
        if (response.data["status"] == true) {
          response.data["data"].map((item) => {
            ylist.push(item["name"]);
            yantraDict[item["name"]] = {
              itemId: item["_id"],
              purchasePrice: "",
              salePrice: "",
              makingCharges: "0",
              yantraType: "Select",
              isSold: false,
            };
          });
          setYantrasList(ylist);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    yantrasList.map((item) => {
      if (!yantras.includes(item)) {
        yantraDict[item] = {
          itemId: yantraDict[item]["itemId"],
          purchasePrice: "",
          salePrice: "",
          makingCharges: "0",
          yantraType: "Select",
          isSold: false,
        };
      }
    });
  }, [yantras]);

  // Get all crystals
  useEffect(() => {
    axios
      .get(global.config.DATA.domain + "/crystals")
      .then((response) => {
        const clist = [];
        if (response.data["status"] == true) {
          response.data["data"].map((item) => {
            clist.push(item["name"]);
            crystalDict[item["name"]] = {
              itemId: item["_id"],
              purchasePrice: "",
              salePrice: "",
              makingCharges: "0",
              crystalType: "Select",
              isSold: false,
            };
          });
          setCrystalsList(clist);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    crystalsList.map((item) => {
      if (!crystals.includes(item)) {
        crystalDict[item] = {
          itemId: crystalDict[item]["itemId"],
          purchasePrice: "",
          salePrice: "",
          makingCharges: "0",
          crystalType: "Select",
          isSold: false,
        };
      }
    });
  }, [crystals]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  // Load Customer Data
  useEffect(() => {
    if (cid) {
      if (id) {
        setSubmitOperation("updateConsultation");
      } else {
        setSubmitOperation("createConsultation");
      }
      axios
        .get(global.config.DATA.domain + "/customer/find/" + cid)
        .then((response) => {
          if (response.data["status"] == true) {
            setFormOperation("Update Customer");
            setFirstName(response.data["data"]["firstName"]);
            setMiddleName(response.data["data"]["middleName"]);
            setLastName(response.data["data"]["lastName"]);
            setBirthPlaceManual(response.data["data"]["birthPlaceManual"]);
            setwhichFlag(response.data["data"]["flag"]);
            setBirthDate(formatDate(response.data["data"]["dateOfBirth"]));
            setKundaliResponse(response.data["data"]["kundali"]);

            // const [state, city] = response.data["data"]["birthPlace"].split(",");
            // setBirthPlace(state);
            const [country, state, city] = response.data["data"]["birthPlace"].split(",");

            setSelectedCountry(country.split("//")[0]);
            setCountryCode(country.split("//")[1]);

            setSelectedState(state.split("//")[0]);
            setStateCode(state.split("//")[1]);

            setSelectedCity(city);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [editDisableDetails]);

  // Load Consultation Data
  useEffect(() => {
    if (id) {
      axios
        .get(global.config.DATA.domain + "/consultation/edit/" + id)
        .then((response) => {
          if (response.data["status"] == true) {
            let total = 0;
            response.data["data"]["yantras"].map((item) => {
              yantras.push(item["type"]["name"]);
              yantraDict[item["type"]["name"]] = {
                itemId: item["type"]["_id"],
                purchasePrice: parseInt(item["purchasePrice"]),
                salePrice: parseInt(item["salePrice"]),
                isSold: item["isSold"],
                makingCharges: isNaN(item["makingCharges"]) ? 0 : item["makingCharges"],
                yantraType: item["yantraType"],
              };
            });
            response.data["data"]["gemstones"].map((item) => {
              gemstones.push(item["type"]["name"]);
              gemstonesDict[item["type"]["name"]] = {
                itemId: item["type"]["_id"],
                purchasePrice: parseInt(item["purchasePrice"]),
                salePrice: parseInt(item["salePrice"]),
                isSold: item["isSold"],
                makingCharges: isNaN(item["makingCharges"]) ? 0 : item["makingCharges"],
                gemstoneType: item["gemstoneType"],
              };
            });
            response.data["data"]["crystals"].map((item) => {
              crystals.push(item["type"]["name"]);
              crystalDict[item["type"]["name"]] = {
                itemId: item["type"]["_id"],
                purchasePrice: parseInt(item["purchasePrice"]),
                salePrice: parseInt(item["salePrice"]),
                isSold: item["isSold"],
                makingCharges: isNaN(item["makingCharges"]) ? 0 : item["makingCharges"],
                crystalType: item["crystalType"],
              };
            });
            if (yantras.length > 0) setShowYantra(true);
            if (gemstones.length > 0) setShowGemstone(true);
            if (crystals.length > 0) setShowCrystal(true);
            if (response.data["data"]["amountReceived"]) {
              setAmountReceivedList(response.data["data"]["amountReceived"]);
              response.data["data"]["amountReceived"].map((item) => {
                total += parseInt(item["aReceived"]);
              });
              setconsultationChargeReceivedList(response.data["data"]["amountReceived"]);
            }
            setPastConsultationChargeReceived(total);
            setFormOperation("Update Customer");
            setGivenName(response.data["data"]["givenName"]);
            setGivenLastName(response.data["data"]["givenLastName"]);
            setGivenMiddleName(response.data["data"]["givenMiddleName"]);
            setUploadImagesList(response.data["data"]["uploadImage"]);
            setPlace(response.data["data"]["place"]);
            setAddress(response.data["data"]["address"]);
            setTopicOfVisit(response.data["data"]["topicOfVisit"].split("///")[0]);
            setSpecifyTopicOfVisit(response.data["data"]["topicOfVisit"].split("///")[1]);
            setconsultationCharge(response.data["data"]["consultationCharge"]);
            setTotalBill(response.data["data"]["totalBill"]);
            setRemedies(response.data["data"]["remedies"]);
            setMeetingNotes(response.data["data"]["meetingNotes"]);
            setAdditionalNotes(response.data["data"]["additionalNotes"]);
            setConsultationDate(response.data["data"]["dateOfConsultation"].split("T")[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  // handleEditDetails
  function handleEditDetails() {
    if (editDisableDetails == false) {
      const formData = new FormData();
      formData.append("kundaliFile", inputKundali[0]);
      axios
        .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response1) => {
          var kundaliName = "";
          if (response1.data["data"]["kundaliFile"]) {
            kundaliName = response1.data["data"]["kundaliFile"][0]["filename"];
          }
          if (response1.data["status"] == true) {
            if (inputFirstName && inputLastName) {
              axios
                .put(global.config.DATA.domain + "/customer/update/" + cid, {
                  firstName: inputFirstName,
                  middleName: inputMiddleName,
                  lastName: inputLastName,
                  dateOfBirth: inputBirthDate,
                  kundali: kundaliName ? kundaliName : kundaliResponse,
                  birthPlaceManual:birthPlaceManual,
                  birthPlace:
                    selectedCountry +
                    "//" +
                    countryCode +
                    "," +
                    selectedState +
                    "//" +
                    stateCode +
                    "," +
                    selectedCity,
                })
                .then((response) => {
                  if (response.data["status"] == true) {
                    setEditDisableDetails(!editDisableDetails);
                  }
                })
                .catch((error) => {
                  setAddCustomerError("Unable to update customer");
                });
            } else {
              if (!inputFirstName) setFirstNameError("First Name Required");

              if (!inputLastName) setLastNameError("Last Name Required");
            }
          }
        })
        .catch((error) => {
          setAddCustomerError("Unable to add file");
        });
    } else {
      setEditDisableDetails(!editDisableDetails);
    }
  }

  function displayHistory() {
    setShowHistory(!showHistory);
  }

  function displayPayment() {
    setShowPayment(!showPayment);
  }
  function displayGemstone() {
    setShowGemstone(!showGemstone);
  }
  function displayYantra() {
    setShowYantra(!showYantra);
  }
  function displayCrystal() {
    setShowCrystal(!showCrystal);
  }

  function handleReceivedAmount(value) {
    setconsultationChargeReceived(value);
    if (
      (consultationCharge ? parseInt(consultationCharge) : 0) +
        parseInt(testDummy) -
        parseInt(pastConsultationChargeReceived) -
        (value ? parseInt(value) : 0) <
      0
    ) {
      alert("Amount Received should be smaller than Total Bill");
      setconsultationChargeReceived(0);
    }
  }

  function handleGemstonesSoldCheck(key, newValue) {
    setGemstonesDict((prevState) => {
      const updatedGemstonesDict = { ...prevState };
      updatedGemstonesDict[key]["isSold"] = !newValue;
      return updatedGemstonesDict;
    });
  }

  function handleGemstonesMakingType(key, newValue) {
    setGemstonesDict((prevState) => {
      const updatedGemstonesDict = { ...prevState };
      updatedGemstonesDict[key]["gemstoneType"] = newValue;
      return updatedGemstonesDict;
    });
  }

  function toSetZeroPriceToGemstone(value) {
    const priceType1 = "purchasePrice";
    const priceType2 = "salePrice";
    for (let i = 0; i < value.length; i++) {
      setGemstonesDict((prevState) => {
        const updatedGemstonesDict = { ...prevState };
        updatedGemstonesDict[value[i]][priceType1] = 0;
        updatedGemstonesDict[value[i]][priceType2] = 0;
        return updatedGemstonesDict;
      });
    }
  }
  function toSetZeroPriceTocrystal(value) {
    const priceType1 = "purchasePrice";
    const priceType2 = "salePrice";
    for (let i = 0; i < value.length; i++) {
      setCrystalDict((prevState) => {
        const updatedCrystalsDict = { ...prevState };
        updatedCrystalsDict[value[i]][priceType1] = 0;
        updatedCrystalsDict[value[i]][priceType2] = 0;
        return updatedCrystalsDict;
      });
    }
  }

  function toSetZeroPriceToYantra(value) {
    const priceType1 = "purchasePrice";
    const priceType2 = "salePrice";
    for (let i = 0; i < value.length; i++) {
      setYantraDict((prevState) => {
        const updatedYantrasDict = { ...prevState };
        updatedYantrasDict[value[i]][priceType1] = 0;
        updatedYantrasDict[value[i]][priceType2] = 0;
        return updatedYantrasDict;
      });
    }
  }

  function handleGemstonesPriceChange(gemstone, priceType, newValue) {
    setGemstonesDict((prevState) => {
      const updatedGemstonesDict = { ...prevState };
      updatedGemstonesDict[gemstone][priceType] = newValue;
      return updatedGemstonesDict;
    });
  }

  function handleYantrasSoldCheck(key, newValue) {
    setYantraDict((prevState) => {
      const updatedYantrasDict = { ...prevState };
      updatedYantrasDict[key]["isSold"] = !newValue;
      return updatedYantrasDict;
    });
  }

  function handleYantrasMakingType(key, newValue) {
    setYantraDict((prevState) => {
      const updatedYantrasDict = { ...prevState };
      updatedYantrasDict[key]["yantraType"] = newValue;
      return updatedYantrasDict;
    });
  }

  function handleYantrasPriceChange(yantra, priceType, newValue) {
    setYantraDict((prevState) => {
      const updatedYantrasDict = { ...prevState };
      updatedYantrasDict[yantra][priceType] = newValue;
      return updatedYantrasDict;
    });
  }

  function handleCrystalsPriceChange(crystal, priceType, newValue) {
    setCrystalDict((prevState) => {
      const updatedCrystalsDict = { ...prevState };
      updatedCrystalsDict[crystal][priceType] = newValue;
      return updatedCrystalsDict;
    });
  }

  function handleCrystalsMakingType(key, newValue) {
    setCrystalDict((prevState) => {
      const updatedCrystalsDict = { ...prevState };
      updatedCrystalsDict[key]["crystalType"] = newValue;
      return updatedCrystalsDict;
    });
  }

  function handleCrystalsSoldCheck(key, newValue) {
    setCrystalDict((prevState) => {
      const updatedCrystalsDict = { ...prevState };
      updatedCrystalsDict[key]["isSold"] = !newValue;
      return updatedCrystalsDict;
    });
  }

  function removeItemFromList(list, index) {
    if (confirm("Are you sure you want to detele this Entry?")) {
      if (index > -1) {
        setPastConsultationChargeReceived(pastConsultationChargeReceived - list[index].aReceived);
        list.splice(index, 1);
      }
      setAmountReceivedList(list);
      if (amountReceivedList.length < 1) setShowHistory(false);
    } else {
    }
  }

  function deleteUploadedImages(index, fileid) {
    axios
      .get(global.config.DATA.domain + "/imagefile/delete/" + fileid)
      .then((response) => {
        if (response.data["status"] == true) {
          const newUploadedImagesResponse = [...uploadImagesList];
          newUploadedImagesResponse.splice(index, 1);
          setUploadImagesList(newUploadedImagesResponse);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  let testDummy = 0;

  for (const gemstonesName in gemstonesDict) {
    const item = gemstonesDict[gemstonesName];
    if (item.salePrice !== "" && item.isSold == true) {
      testDummy = parseInt(testDummy) + parseInt(item.salePrice);
      if (item.makingCharges !== "") {
        testDummy = parseInt(testDummy) + parseInt(item.makingCharges);
      }
    }
  }

  for (const yantrasName in yantraDict) {
    const item = yantraDict[yantrasName];
    if (item.salePrice !== "" && item.isSold == true) {
      testDummy = parseInt(testDummy) + parseInt(item.salePrice);
      if (item.makingCharges !== "") {
        testDummy = parseInt(testDummy) + parseInt(item.makingCharges);
      }
    }
  }

  for (const crystalsName in crystalDict) {
    const item = crystalDict[crystalsName];
    if (item.salePrice !== "" && item.isSold == true) {
      testDummy = parseInt(testDummy) + parseInt(item.salePrice);
      if (item.makingCharges !== "") {
        testDummy = parseInt(testDummy) + parseInt(item.makingCharges);
      }
    }
  }

  function updateConsultation(event) {
    setTopicOfVisitError("");
    const uploadgemstonesDict = [];
    for (const gemstonesName in gemstonesDict) {
      const gemstone = gemstonesDict[gemstonesName];
      if (gemstone.purchasePrice !== "" && gemstone.salePrice !== "") {
        uploadgemstonesDict.push({
          g_id: gemstone.itemId,
          purchasePrice: gemstone.purchasePrice,
          salePrice: gemstone.salePrice,
          isSold: gemstone.isSold,
          makingCharges: isNaN(gemstone.makingCharges) ? "" : gemstone.makingCharges,
          gemstoneType: gemstone.gemstoneType,
        });
      }
    }
    const uploadyantrasDict = [];
    for (const yantraName in yantraDict) {
      const yantra = yantraDict[yantraName];
      if (yantra.purchasePrice !== "" && yantra.salePrice !== "") {
        uploadyantrasDict.push({
          y_id: yantra.itemId,
          purchasePrice: yantra.purchasePrice,
          salePrice: yantra.salePrice,
          isSold: yantra.isSold,
          makingCharges: isNaN(yantra.makingCharges) ? "" : yantra.makingCharges,
          yantraType: yantra.gemstoneType,
        });
      }
    }
    const uploadcrystalsDict = [];
    for (let i = 0; i < crystals.length; i++) {
      const crystalName = crystals[i];
      const crystal = crystalDict[crystalName];
      if (crystal.purchasePrice !== "" && crystal.salePrice !== "") {
        uploadcrystalsDict.push({
          c_id: crystal.itemId,
          purchasePrice: crystal.purchasePrice,
          salePrice: crystal.salePrice,
          isSold: crystal.isSold,
          makingCharges: isNaN(crystal.makingCharges) ? "" : crystal.makingCharges,
          crystalType: crystal.gemstoneType,
        });
      }
    }

    if (!consultationCharge) {
      setconsultationChargeError("Consultation Charge required");
    }
    if (!consultationDate) {
      setDateChangeError("Consultation date required");
    }

    if (topicOfVisit == "Select" || (topicOfVisit == "Other" && !specifyTopicOfVisit)) {
      setTopicOfVisitError("Topic of Visit Required");
    } else {
      if (
        (topicOfVisit != "Select" || (topicOfVisit == "Other" && specifyTopicOfVisit)) &&
        consultationCharge &&
        consultationDate
      ) {
        // console.log("consultationCharge",consultationCharge)
        // console.log("testDummy",testDummy)
        console.log("consultationChargeReceived");
        console.log(consultationChargeReceived);
        console.log("consultationChargeReceivedList", consultationChargeReceivedList);
        let totalReceived = 0;
        if (consultationChargeReceived > 0) {
          console.log("inside");
          consultationChargeReceivedList.push({
            aReceived: consultationChargeReceived,
            receiveDate: amountReceivedDate,
          });
          totalReceived = consultationChargeReceivedList.reduce(
            (acc, obj) => acc + parseInt(obj.aReceived),
            0
          );
        } else {
          totalReceived = consultationChargeReceivedList.reduce(
            (acc, obj) => acc + parseInt(obj.aReceived),
            0
          );
        }

        const formData = new FormData();
        for (let i = 0; i < uploadImages.length; i++) {
          formData.append("uploadImage", uploadImages[i]);
        }
        axios
          .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response1) => {
            const uploadImagesNames = [...uploadImagesList];
            if (response1.data["data"]["uploadImage"]) {
              response1.data["data"]["uploadImage"].forEach((file) => {
                uploadImagesNames.push({
                  filePath: file.filename,
                  isDeleted: false,
                });
              });
            }
            if (response1.data["status"] == true) {
              axios
                .put(global.config.DATA.domain + "/consultation/update/" + id, {
                  consultationType: type,
                  givenName: givenName,
                  givenMiddleName: givenMiddleName,
                  givenLastName: givenLastName,
                  place: place,
                  uploadImaged: uploadImagesNames ? uploadImagesNames : uploadImagesList,
                  address: address,
                  topicOfVisit:
                    topicOfVisit == "Other"
                      ? "Other///" + specifyTopicOfVisit
                      : topicOfVisit + "///",
                  consultationCharge: consultationCharge,
                  totalBill: parseInt(consultationCharge) + parseInt(testDummy),
                  amountReceived: consultationChargeReceivedList,
                  totalPending: parseInt(consultationCharge) + parseInt(testDummy) - totalReceived,
                  remedies: remedies,
                  meetingNotes: meetingNotes,
                  additionalNotes: additionalNotes,
                  dateOfConsultation: consultationDate,
                  gemstone_id: uploadgemstonesDict,
                  yantra_id: uploadyantrasDict,
                  crystal_id: uploadcrystalsDict,
                  isDeleted: false,
                })
                .then((response) => {
                  if (response.data["status"] == true) {
                    navigate(`/viewCustomer?id=${cid}`);
                  } else {
                    setAddCustomerError("Consultation not Updated");
                  }
                })
                .catch((error) => {
                  setAddCustomerError("Unable to Update Consultation");
                });
            }
          })
          .catch((error) => {
            setAddCustomerError("Unable to add file");
          });
      }
    }
  }

  function createConsultation(event) {
    setTopicOfVisitError("");
    setconsultationChargeError("");

    const uploadgemstonesDict = [];
    for (const gemstonesName in gemstonesDict) {
      const gemstone = gemstonesDict[gemstonesName];
      if (gemstone.purchasePrice !== "" && gemstone.salePrice !== "") {
        uploadgemstonesDict.push({
          g_id: gemstone.itemId,
          purchasePrice: gemstone.purchasePrice,
          salePrice: gemstone.salePrice,
          isSold: gemstone.isSold,
          makingCharges: isNaN(gemstone.makingCharges) ? "" : gemstone.makingCharges,
          gemstoneType: gemstone.gemstoneType,
        });
      }
    }
    const uploadyantrasDict = [];
    for (const yantraName in yantraDict) {
      const yantra = yantraDict[yantraName];
      if (yantra.purchasePrice !== "" && yantra.salePrice !== "") {
        uploadyantrasDict.push({
          y_id: yantra.itemId,
          purchasePrice: yantra.purchasePrice,
          salePrice: yantra.salePrice,
          isSold: yantra.isSold,
          makingCharges: isNaN(yantra.makingCharges) ? "" : yantra.makingCharges,
          yantraType: yantra.gemstoneType,
        });
      }
    }
    const uploadcrystalsDict = [];
    for (let i = 0; i < crystals.length; i++) {
      const crystalName = crystals[i];
      const crystal = crystalDict[crystalName];
      if (crystal.purchasePrice !== "" && crystal.salePrice !== "") {
        uploadcrystalsDict.push({
          c_id: crystal.itemId,
          purchasePrice: crystal.purchasePrice,
          salePrice: crystal.salePrice,
          isSold: crystal.isSold,
          makingCharges: isNaN(crystal.makingCharges) ? "" : crystal.makingCharges,
          crystalType: crystal.gemstoneType,
        });
      }
    }

    if (!consultationCharge) {
      setconsultationChargeError("Consultation Charges required");
    }
    if (!consultationDate) {
      setDateChangeError("Consultation date required");
    }

    if (topicOfVisit == "Select" || (topicOfVisit == "Other" && specifyTopicOfVisit == "")) {
      setTopicOfVisitError("Topic of Visit Required");
    } else {
      if (
        (topicOfVisit != "Select" || (topicOfVisit == "Other" && specifyTopicOfVisit)) &&
        consultationCharge &&
        consultationDate
      ) {
        let totalReceived = 0;
        if (consultationChargeReceived > 0) {
          consultationChargeReceivedList.push({
            aReceived: consultationChargeReceived,
            receiveDate: amountReceivedDate,
          });
          totalReceived = consultationChargeReceivedList.reduce(
            (acc, obj) => acc + parseInt(obj.aReceived),
            0
          );
        }
        const formData = new FormData();
        for (let i = 0; i < uploadImages.length; i++) {
          formData.append("uploadImage", uploadImages[i]);
        }
        axios
          .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response1) => {
            const uploadImagesNames = [];
            if (response1.data["data"]["uploadImage"]) {
              response1.data["data"]["uploadImage"].forEach((file) => {
                uploadImagesNames.push({
                  filePath: file.filename,
                  isDeleted: false,
                });
              });
            }
            if (response1.data["status"] == true) {
              axios
                .post(global.config.DATA.domain + "/consultation/create", {
                  customer_id: cid,
                  consultationType: type,
                  givenName: givenName,
                  givenMiddleName: givenMiddleName,
                  givenLastName: givenLastName,
                  uploadImage: uploadImagesNames,
                  place: place,
                  address: address,
                  topicOfVisit:
                    topicOfVisit == "Other"
                      ? "Other///" + specifyTopicOfVisit
                      : topicOfVisit + "///",
                  consultationCharge: consultationCharge,
                  totalBill: parseInt(consultationCharge) + parseInt(testDummy),
                  amountReceived: consultationChargeReceivedList,
                  totalPending: parseInt(consultationCharge) + parseInt(testDummy) - totalReceived,
                  remedies: remedies,
                  meetingNotes: meetingNotes,
                  additionalNotes: additionalNotes,
                  dateOfConsultation: consultationDate,
                  gemstone_id: uploadgemstonesDict,
                  yantra_id: uploadyantrasDict,
                  crystal_id: uploadcrystalsDict,
                  isDeleted: false,
                })
                .then((response) => {
                  if (response.data["status"] == true) {
                    navigate(`/viewCustomer?id=${cid}`);
                  } else {
                    setAddCustomerError("Consultation not added");
                  }
                })
                .catch((error) => {
                  console.error("error".error);
                  setAddCustomerError("Unable to add Consultation");
                });
            }
          })
          .catch((error) => {
            setAddCustomerError("Unable to add file");
          });
      }
    }
  }

  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Card sx={{ height: "100%" }}>
            {/* <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
              px={2}
            >
              <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Numerology
              </ArgonTypography>
            </ArgonBox> */}
            <ArgonBox pt={3} pb={2} px={2}>
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                sx={{ listStyle: "none" }}
              >
                <ArgonBox component="form" role="form">
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      Basic Details
                    </ArgonTypography>
                    <Card style={{ padding: "30px" }}>
                      <Grid container columnSpacing={3} rowSpacing={0}>
                        {/* first name */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              First Name *&nbsp;
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              disabled={editDisableDetails}
                              placeholder="First Name"
                              size="large"
                              value={inputFirstName}
                              onChange={(event) => {
                                setFirstName(event.target.value), setFirstNameError("");
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{firstNameError}</p>
                          </ArgonBox>
                        </Grid>

                        {/* middle name */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Middle Name &nbsp;
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              disabled={editDisableDetails}
                              placeholder="Middle Name"
                              size="large"
                              value={inputMiddleName}
                              onChange={(event) => {
                                setMiddleName(event.target.value);
                              }}
                            />
                          </ArgonBox>
                        </Grid>

                        {/* last name */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Last Name *&nbsp;
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              disabled={editDisableDetails}
                              placeholder="Last Name"
                              size="large"
                              value={inputLastName}
                              onChange={(event) => {
                                setLastName(event.target.value), setLastNameError("");
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{lastNameError}</p>
                          </ArgonBox>
                        </Grid>

                        {/* date of Birth */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Date of Birth &nbsp;
                            </ArgonTypography>
                            <ArgonInput
                              type="date"
                              disabled={editDisableDetails}
                              size="large"
                              value={inputBirthDate}
                              onChange={(event) => {
                                const selectedDate = event.target.value;
                                const currentDate = new Date().toISOString().split("T")[0];
                                setBirthDateChangeError("");
                                if (selectedDate <= currentDate) {
                                  setBirthDate(selectedDate);
                                } else {
                                  setBirthDate("");
                                  setBirthDateChangeError("Future date not valid");
                                }
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{birthDateChangeError}</p>
                          </ArgonBox>
                        </Grid>

                        {type == 2 ? (
                          editDisableDetails ? (
                            <Grid item xs={12} sm={4}>
                              <ArgonBox mb={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold"
                                  fontSize="12px"
                                >
                                  Birth Place *&nbsp;
                                </ArgonTypography>
                                <ArgonInput
                                  type="text"
                                  disabled={editDisableDetails}
                                  size="large"
                                  value={
                                    selectedCountry + ", " + selectedState + ", " + selectedCity
                                  }
                                  onChange={(event) => {
                                    setBirthPlace(event.target.value);
                                  }}
                                />
                              </ArgonBox>
                            </Grid>
                          ) : (
                            //  country
                            <Grid item xs={12} sm={4}>
                              <ArgonBox mb={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold"
                                  fontSize="12px"
                                >
                                  Birth Country
                                </ArgonTypography>
                                {displayCountry ? (
                                  <ReactSelect
                                    id="state"
                                    name="state"
                                    label="State"
                                    defaultMenuIsOpen={displayCountry}
                                    defaultValue={selectedCountry}
                                    onChange={(selectedOption) => {
                                      handleCountryChange(selectedOption);
                                    }}
                                    options={countrys.map((item) => ({
                                      label: item.name,
                                      value: item.name + "//" + item.isoCode,
                                    }))}
                                  />
                                ) : (
                                  <ArgonBox
                                    mb={2}
                                    style={{
                                      height: "45px",
                                      border: "1px solid #cac5c5",
                                      borderRadius: "6px",
                                    }}
                                    onClick={() => {
                                      setdisplayCountry(true);
                                    }}
                                  >
                                    <p
                                      style={{ marginLeft: "8px", marginTop: "10px", fontSize: 13 }}
                                    >
                                      {selectedCountry}
                                    </p>
                                  </ArgonBox>
                                )}
                              </ArgonBox>
                            </Grid>
                          )
                        ) : (
                          <span></span>
                        )}
                        {type == 2 && !editDisableDetails ? (
                          // state
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Birth State
                              </ArgonTypography>
                              {displayState ? (
                                <ReactSelect
                                  id="state"
                                  name="state"
                                  label="State"
                                  defaultMenuIsOpen={displayState}
                                  defaultValue={previousSelectedState || selectedState}
                                  onChange={(selectedOption) => {
                                    handleStateChange(selectedOption);
                                  }}
                                  options={stateOptions.map((item) => ({
                                    label: item.name,
                                    value: item.name + "//" + item.isoCode,
                                  }))}
                                />
                              ) : (
                                <ArgonBox
                                  mb={2}
                                  style={{
                                    height: "45px",
                                    border: "1px solid #cac5c5",
                                    borderRadius: "6px",
                                  }}
                                  onClick={() => {
                                    setdisplayState(true);
                                  }}
                                >
                                  <p style={{ marginLeft: "8px", marginTop: "10px", fontSize: 13 }}>
                                    {selectedState}
                                  </p>
                                </ArgonBox>
                              )}
                            </ArgonBox>
                          </Grid>
                        ) : null}
                        {type == 2 && !editDisableDetails ? (
                          // city
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Birth City
                              </ArgonTypography>
                              {displayCity ? (
                                <ReactSelect
                                  id="city"
                                  name="city"
                                  label="city"
                                  defaultValue={selectedCity}
                                  defaultMenuIsOpen={displayCity}
                                  onChange={handleCityChange}
                                  options={selectedCities.map((item) => ({
                                    label: item.name,
                                    value: item.name + "//" + item.isoCode,
                                  }))}
                                  // options={selectedCities}
                                />
                              ) : (
                                <ArgonBox
                                  mb={2}
                                  style={{
                                    height: "45px",
                                    border: "1px solid #cac5c5",
                                    borderRadius: "6px",
                                  }}
                                  onClick={() => setdisplayCity(true)}
                                >
                                  <p style={{ marginLeft: "8px", marginTop: "10px", fontSize: 13 }}>
                                    {selectedCity}
                                  </p>
                                </ArgonBox>
                              )}
                            </ArgonBox>
                          </Grid>
                        ) : null}

                        {type == 2 && !editDisableDetails ? (
                          // birth place
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Birth Place
                              </ArgonTypography>
                              <ArgonInput
                                type="text"
                                // disabled={editDisableDetails}
                                placeholder="Birth Place"
                                size="large"
                                value={birthPlaceManual}
                                onChange={(event) => {
                                  setBirthPlaceManual(event.target.value)
                                }}
                              />
                              <p style={{ color: "red", fontSize: "12px" }}>{lastNameError}</p>
                            </ArgonBox>
                          </Grid>
                        ) : null}

                        {type == 2 ? (
                          !editDisableDetails ? (
                            <Grid item xs={12} sm={4}>
                              <ArgonBox mb={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold"
                                  fontSize="12px"
                                >
                                  Kundali
                                </ArgonTypography>
                                <ArgonInput
                                  type="file"
                                  size="large"
                                  multiple
                                  // value={inputKundali}
                                  onChange={(event) => setKundali(event.target.files)}
                                />
                              </ArgonBox>
                              {isNaN(kundaliResponse) ? (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <a
                                    href={`${global.config.DATA.filePath}/../uploads/${kundaliResponse}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <p
                                      style={{
                                        color: "#4b4b4b",
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        flex: "1",
                                        marginRight: "8px",
                                      }}
                                    >
                                      {kundaliResponse}
                                    </p>
                                  </a>
                                  {/* <span style={{ color: "red", fontSize: "11px", fontWeight:"bold" }}>&#x2718;</span> */}
                                </div>
                              ) : null}
                            </Grid>
                          ) : isNaN(kundaliResponse) ? (
                            <Grid item xs={12} sm={4}>
                              <a
                                href={`${global.config.DATA.filePath}/../uploads/${kundaliResponse}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    Kundali
                                  </ArgonTypography>

                                  <p
                                    style={{
                                      color: "#4b4b4b",
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      flex: "1",
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    {kundaliResponse}
                                  </p>
                                </ArgonBox>
                              </a>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={4}>
                              <ArgonBox mb={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold"
                                  fontSize="12px"
                                >
                                  Kundali
                                </ArgonTypography>
                                <ArgonInput
                                  type="file"
                                  size="large"
                                  disabled={editDisableDetails}
                                  multiple
                                  // value={inputKundali}
                                  onChange={(event) => setKundali(event.target.files)}
                                />
                              </ArgonBox>
                            </Grid>
                          )
                        ) : null}
                      </Grid>
                      <ArgonButton
                        color="info"
                        size="small"
                        style={{ width: "20px", float: "right" }}
                        onClick={handleEditDetails}
                      >
                        {editDisableDetails ? "Edit" : "Save"}
                      </ArgonButton>
                    </Card>
                  </Card>
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {type == 1 ? (
                        <span>Numerology Details</span>
                      ) : type == 2 ? (
                        <span>Astrology Details</span>
                      ) : type == 3 ? (
                        <span>Tarot Card Details</span>
                      ) : type == 4 ? (
                        <span>Vastu Details</span>
                      ) : (
                        <span></span>
                      )}
                    </ArgonTypography>
                    <Card style={{ padding: "30px" }}>
                      <Grid container columnSpacing={3} rowSpacing={0}>
                        {type == 1 && whichFlag && whichFlag == "Individual" ? (
                          <>
                            <Grid item xs={12} sm={4}>
                              <ArgonBox mb={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold"
                                  fontSize="12px"
                                >
                                  Given Name &nbsp;
                                </ArgonTypography>
                                <ArgonInput
                                  type="text"
                                  placeholder="Given Name"
                                  size="large"
                                  value={givenName}
                                  onChange={(event) => {
                                    setGivenName(event.target.value);
                                  }}
                                />
                              </ArgonBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <ArgonBox mb={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold"
                                  fontSize="12px"
                                >
                                  Given Middle Name &nbsp;
                                </ArgonTypography>
                                <ArgonInput
                                  type="text"
                                  placeholder="Given Middle Name"
                                  size="large"
                                  value={givenMiddleName}
                                  onChange={(event) => {
                                    setGivenMiddleName(event.target.value);
                                  }}
                                />
                              </ArgonBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <ArgonBox mb={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold"
                                  fontSize="12px"
                                >
                                  Given Last Name &nbsp;
                                </ArgonTypography>
                                <ArgonInput
                                  type="text"
                                  placeholder="Given Last Name"
                                  size="large"
                                  value={givenLastName}
                                  onChange={(event) => {
                                    setGivenLastName(event.target.value);
                                  }}
                                />
                              </ArgonBox>
                            </Grid>
                          </>
                        ) : (
                          <span></span>
                        )}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Consultation Date *&nbsp;
                            </ArgonTypography>

                            <ArgonInput
                              type="date"
                              size="large"
                              max={new Date().toISOString().split("T")[0]}
                              value={consultationDate}
                              onChange={(event) => {
                                const selectedDate = event.target.value;
                                const currentDate = new Date().toISOString().split("T")[0];
                                setDateChangeError("");
                                if (selectedDate <= currentDate) {
                                  setConsultationDate(selectedDate);
                                } else {
                                  setConsultationDate("");
                                  setDateChangeError("Future date not valid");
                                }
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{dateChangeError}</p>
                          </ArgonBox>
                        </Grid>
                        {/* Consultation Charges * */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Consultation Charges *
                            </ArgonTypography>
                            <ArgonInput
                              inputProps={{ min: 0 }}
                              type="number"
                              placeholder="consultationCharge"
                              size="large"
                              value={consultationCharge}
                              onChange={(event) => {
                                {
                                  const conCharges = event.target.value;
                                  if (conCharges < 0) {
                                    setconsultationCharge(0);
                                    setconsultationChargeError(
                                      "Consultation Charges can not be negative"
                                    );
                                  } else {
                                    setconsultationCharge(event.target.value);
                                    setconsultationChargeError("");
                                  }
                                }
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {consultationChargeError}
                            </p>
                          </ArgonBox>
                        </Grid>

                        {/* Purpose of Visit */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox
                            mb={2}
                            onClick={() => {
                              setIsVisitOpen(!isVisitOpen);
                            }}
                          >
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Purpose of Visit *
                            </ArgonTypography>
                            <select
                              style={{
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              id="dropdownreference"
                              value={topicOfVisit}
                              onChange={(event) => {
                                setTopicOfVisit(event.target.value),
                                  setTopicOfVisitError(""),
                                  setIsVisitOpen(false);
                              }}
                            >
                              <option value="Select">
                                <em>Select</em>
                              </option>
                              <option value="Education">Education</option>
                              <option value="Career">Career</option>
                              <option value="Marriage">Marriage</option>
                              <option value="Finance">Finance</option>
                              <option value="Business">Business</option>
                              <option value="Other">
                                <em>Other</em>
                              </option>
                            </select>
                            <p style={{ color: "red", fontSize: "12px" }}>{topicOfVisitError}</p>
                          </ArgonBox>
                        </Grid>
                        {topicOfVisit == "Other" ? (
                          // Specify Topic
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Specify Topic *&nbsp;
                              </ArgonTypography>
                              <ArgonInput
                                type="text"
                                placeholder="Specify Topic Of Visit"
                                size="large"
                                value={specifyTopicOfVisit}
                                onChange={(event) => {
                                  setSpecifyTopicOfVisit(event.target.value);
                                }}
                              />
                              <p style={{ color: "red", fontSize: "12px" }}>{topicOfVisitError}</p>
                            </ArgonBox>
                          </Grid>
                        ) : null}

                        {/* vastu place */}
                        {type == 4 ? (
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Place
                              </ArgonTypography>
                              <ArgonInput
                                type="text"
                                placeholder="Place"
                                size="large"
                                value={place}
                                onChange={(event) => {
                                  setPlace(event.target.value);
                                }}
                              />
                            </ArgonBox>
                          </Grid>
                        ) : null}
                        {/* vastu Address */}
                        {type == 4 ? (
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Address
                              </ArgonTypography>
                              <ArgonInput
                                type="text"
                                placeholder="Place"
                                size="large"
                                value={address}
                                onChange={(event) => {
                                  setAddress(event.target.value);
                                }}
                              />
                            </ArgonBox>
                          </Grid>
                        ) : null}

                        {/* Uplaod Images */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              {type == 4 ? "Uplaod Images" : "Consultation Notes"}
                            </ArgonTypography>
                            <div
                              style={{
                                border: "0.5px solid #bdbfc2",
                                borderRadius: 7,
                                padding: 13,
                                paddingTop: 3,
                              }}
                            >
                              <input
                                type="file"
                                multiple
                                onChange={(event) => setUploadImages(event.target.files)}
                              />
                            </div>
                          </ArgonBox>
                          {uploadImagesList.map((item, index) => (
                            <div key={item} style={{ display: "flex", alignItems: "center" }}>
                              <a
                                href={`${global.config.DATA.filePath}/../uploads/${item.filePath}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <p
                                  style={{
                                    color: "#4b4b4b",
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    flex: "1",
                                    marginRight: "8px",
                                  }}
                                >
                                  {item.filePath}
                                </p>
                              </a>
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => {
                                  deleteUploadedImages(index, item._id);
                                }}
                                style={{ color: "red", fontSize: "12px" }}
                              />
                            </div>
                          ))}
                        </Grid>
                      </Grid>

                      <Grid container columnSpacing={3} rowSpacing={0}>
                        {/* remidies notes */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                              marginTop="12px"
                            >
                              Remedies
                              {type == 1 ? "(will show on report)" : ""}
                            </ArgonTypography>
                            <textarea
                              id="outlined-multiline-static"
                              label=""
                              multiline
                              rows={6}
                              placeholder="Remedies"
                              size="large"
                              value={remedies}
                              // fullWidth={true}
                              onChange={(event) => {
                                setRemedies(event.target.value);
                              }}
                              inputProps={{ style: { width: "100% !important", padding: 8 } }}
                              style={{
                                flex: "1",
                                padding: "8px",
                                color: "#344767",
                                fontFamily: "Open Sans, sans-serif",
                                fontSize: "13px",
                                fontWeight: "400",
                                width: "100% !important",
                                letterspacing: "inherit",
                                borderColor: "#E9EBED",
                                marginTop: 8,
                                borderRadius: 7,
                                borderWidth: "2px",
                              }}
                            />
                          </ArgonBox>
                        </Grid>

                        {/* meeting notes */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                              marginTop="12px"
                            >
                              Meeting Notes
                            </ArgonTypography>
                            <textarea
                              id="outlined-multiline-static"
                              label=""
                              multiline
                              rows={6}
                              placeholder="Meeting Notes"
                              size="large"
                              value={meetingNotes}
                              // fullWidth={true}
                              onChange={(event) => {
                                setMeetingNotes(event.target.value);
                              }}
                              inputProps={{ style: { width: "100% !important", padding: 8 } }}
                              style={{
                                flex: "1",
                                padding: "8px",
                                color: "#344767",
                                fontFamily: "Open Sans, sans-serif",
                                fontSize: "13px",
                                fontWeight: "400",
                                width: "100% !important",
                                letterspacing: "inherit",
                                borderColor: "#E9EBED",
                                marginTop: 8,
                                borderRadius: 7,
                                borderWidth: "2px",
                              }}
                            />
                            {/* <ArgonInput placeholder="Meeting Notes" size="large" value={meetingNotes} 
                         style={{ height: "60px" }}
                        onChange={(event) => { setMeetingNotes(event.target.value)}}/> */}
                          </ArgonBox>
                        </Grid>

                        {/* additional notes */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                                marginTop="12px"
                              >
                                Additional Notes
                              </ArgonTypography>
                              <textarea
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={6}
                                placeholder="Additional Notes"
                                size="large"
                                value={additionalNotes}
                                // fullWidth={true}
                                borderWidth="2px"
                                onChange={(event) => {
                                  setAdditionalNotes(event.target.value);
                                }}
                                inputProps={{ style: { width: "100% !important", padding: 8 } }}
                                style={{
                                  flex: "1",
                                  padding: "8px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#344767",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  width: "100% !important",
                                  letterspacing: "inherit",
                                  borderColor: "#E9EBED",
                                  marginTop: 8,
                                  borderRadius: 7,
                                  borderWidth: "2px",
                                }}
                              />
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Card>

                  {/* Select gemstone */}
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                      onClick={displayGemstone}
                    >
                      Gemstone <Icon style={{ fontSize: "30px !important" }}>add</Icon>
                    </ArgonTypography>
                    {showGemstone ? (
                      <Card style={{ padding: "30px" }}>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                          <Grid item xs={12} sm={4}>
                            <ArgonBox
                              bm={2}
                              onClick={() => {
                                setIsGemstoneOpen(!isGemstoneOpen);
                              }}
                            >
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Select Gemstone&nbsp;
                              </ArgonTypography>

                              <Select
                                open={isGemstoneOpen}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                multiple
                                value={gemstones}
                                onChange={(event) => {
                                  toSetZeroPriceToGemstone(event.target.value);
                                  setGemstones(event.target.value);
                                }}
                                label="Select an option"
                                renderValue={(selected) => selected.join(", ")}
                                IconComponent={() => (
                                  <FontAwesomeIcon
                                    icon={faCaretDown}
                                    style={{ color: "#999", position: "absolute", right: "8px" }}
                                  />
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      // width:  Math.min(window.innerWidth *0.7,400 )+"px", // Set the desired width for the menu
                                      width: "25%",
                                    },
                                  },
                                }}
                              >
                                {gemstonesList.map((item) => (
                                  <MenuItem
                                    style={{ display: "flex", fontSize: "3px", width: "100%" }}
                                    key={item}
                                    value={item}
                                  >
                                    <Checkbox checked={gemstones.indexOf(item) > -1} />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                        <ArgonBox>
                          {gemstones.map((item) => (
                            <Grid key={item} container columnSpacing={3} rowSpacing={0}>
                              {/* Purchase Price  */}
                              <Grid item xs={6} sm={3}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    {" Purchase Price"}&nbsp;
                                  </ArgonTypography>
                                  <ArgonInput
                                    inputProps={{ min: 0 }}
                                    type="number"
                                    placeholder={item + " Purchase Price"}
                                    size="large"
                                    value={parseInt(gemstonesDict[item].purchasePrice)}
                                    // value={gemstonesDict[item]["purchasePrice"]}
                                    onChange={(event) =>
                                      handleGemstonesPriceChange(
                                        item,
                                        "purchasePrice",
                                        event.target.value
                                      )
                                    }
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* selling price input box */}
                              <Grid item xs={6} sm={3}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    { " Selling Price"}&nbsp;
                                  </ArgonTypography>
                                  <ArgonInput
                                    inputProps={{ min: 0 }}
                                    type="number"
                                    placeholder={item + " Selling Price"}
                                    size="large"
                                    value={parseInt(gemstonesDict[item].salePrice)}
                                    // value={gemstonesDict[item]["purchasePrice"]}
                                    onChange={(event) =>
                                      handleGemstonesPriceChange(
                                        item,
                                        "salePrice",
                                        event.target.value
                                      )
                                    }
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* Type   */}
                              <Grid item xs={6} sm={2}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    {"Making Type"}&nbsp;
                                  </ArgonTypography>
                                  <select
                                    id="dropdown"
                                    style={{
                                      height: "45px",
                                      width: "100%",
                                      borderRadius: "8px",
                                      border: "1px solid #cfcccc",
                                      padding: "8px",
                                      color: "#344767",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      fontFamily: "Open Sans, sans-serif",
                                    }}
                                    value={gemstonesDict[item].gemstoneType}
                                    onChange={(event) =>
                                      handleGemstonesMakingType(item, event.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Ring">Ring</option>
                                    <option value="Pendent">Pendent</option>
                                    <option value="Bracelet">Bracelet</option>
                                  </select>
                                </ArgonBox>
                              </Grid>

                              {/* Making charges */}

                              <Grid item xs={6} sm={2}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    {"Making charges"}&nbsp;
                                  </ArgonTypography>
                                  <ArgonInput
                                    inputProps={{ min: 0 }}
                                    type="number"
                                    placeholder={"Making charges"}
                                    size="large"
                                    value={parseInt(gemstonesDict[item].makingCharges)}
                                    onChange={(event) =>
                                      handleGemstonesPriceChange(
                                        item,
                                        "makingCharges",
                                        event.target.value
                                      )
                                    }
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* is sold check box */}
                              <Grid item xs={6} sm={1}>
                                <ArgonBox
                                  mb={2}
                                  style={{ display: "flex", flexDirection: "column" }}
                                >
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                    marginTop="12px"
                                    paddingBottom="20px"
                                  >
                                    {"Sold"}&nbsp;
                                  </ArgonTypography>
                                  <Checkbox
                                    onClick={
                                      (event) =>
                                        // gemstonesDict[item].isSold=!gemstonesDict[item].isSold
                                        handleGemstonesSoldCheck(item, gemstonesDict[item].isSold)
                                      // handleGemstonesPriceChange(item, "salePrice", event.target.value)
                                    }
                                    checked={gemstonesDict[item].isSold}
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* Total of sell and making  */}
                              <Grid item xs={6} sm={1}>
                                <ArgonBox
                                  mb={2}
                                  style={{ display: "flex", flexDirection: "column" }}
                                >
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                    marginTop="12px"
                                    paddingBottom="20px"
                                  >
                                    Total&nbsp;
                                  </ArgonTypography>
                                  <ArgonTypography
                                    variant="caption"
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="12px"
                                    paddingBottom="20px"
                                  >
                                    {parseInt(
                                      gemstonesDict[item].makingCharges
                                        ? gemstonesDict[item].makingCharges
                                        : 0
                                    ) +
                                      parseInt(
                                        gemstonesDict[item].salePrice
                                          ? gemstonesDict[item].salePrice
                                          : 0
                                      )}
                                    &nbsp;
                                  </ArgonTypography>
                                </ArgonBox>
                              </Grid>
                            </Grid>
                          ))}
                        </ArgonBox>
                      </Card>
                    ) : null}
                  </Card>

                  {/* select Yantra */}
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                      onClick={displayYantra}
                    >
                      Yantra <Icon style={{ fontSize: "30px !important" }}>add</Icon>
                    </ArgonTypography>
                    {showYantra ? (
                      <Card style={{ padding: "30px" }}>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                          <Grid item xs={12} sm={4}>
                            <ArgonBox
                              mb={2}
                              onClick={() => {
                                setIsYantraOpen(!isYantraOpen);
                              }}
                            >
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Select Yantra &nbsp;
                              </ArgonTypography>
                              <Select
                                open={isYantraOpen}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                multiple
                                value={yantras}
                                onChange={(event) => {
                                  toSetZeroPriceToYantra(event.target.value);
                                  setYantras(event.target.value);
                                }}
                                label="Select an option"
                                renderValue={(selected) => selected.join(", ")}
                                IconComponent={() => (
                                  <FontAwesomeIcon
                                    icon={faCaretDown}
                                    style={{ color: "#999", position: "absolute", right: "8px" }}
                                  />
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      // width:  Math.min(window.innerWidth *0.7, 500), // Set the desired width for the menu
                                      width: "25%",
                                    },
                                  },
                                }}
                              >
                                {yantrasList.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox checked={yantras.indexOf(item) > -1} />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                        <ArgonBox>
                          {yantras.map((item) => (
                            <Grid key={item} container columnSpacing={3} rowSpacing={0}>
                              {/* purchase price */}
                              <Grid item xs={6} sm={3}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    {"Purchase Price"}&nbsp;
                                  </ArgonTypography>
                                  <ArgonInput
                                    inputProps={{ min: 0 }}
                                    type="number"
                                    placeholder={item + " Purchase Price"}
                                    size="large"
                                    value={parseInt(yantraDict[item].purchasePrice)}
                                    onChange={(event) =>
                                      handleYantrasPriceChange(
                                        item,
                                        "purchasePrice",
                                        event.target.value
                                      )
                                    }
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* sellprice */}
                              <Grid item xs={6} sm={3}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    {"Selling Price"}&nbsp;
                                  </ArgonTypography>
                                  <ArgonInput
                                    inputProps={{ min: 0 }}
                                    type="number"
                                    placeholder={item + " Selling Price"}
                                    size="large"
                                    value={parseInt(yantraDict[item].salePrice)}
                                    onChange={(event) =>
                                      handleYantrasPriceChange(
                                        item,
                                        "salePrice",
                                        event.target.value
                                      )
                                    }
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* Type   */}
                              <Grid item xs={6} sm={2}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    {"Making Type"}&nbsp;
                                  </ArgonTypography>
                                  <select
                                    id="dropdown"
                                    style={{
                                      height: "45px",
                                      width: "100%",
                                      borderRadius: "8px",
                                      border: "1px solid #cfcccc",
                                      padding: "8px",
                                      color: "#344767",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      fontFamily: "Open Sans, sans-serif",
                                    }}
                                    value={yantraDict[item].yantraType}
                                    onChange={(event) =>
                                      handleYantrasMakingType(item, event.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Ring">Ring</option>
                                    <option value="Pendent">Pendent</option>
                                    <option value="Bracelet">Bracelet</option>
                                  </select>
                                </ArgonBox>
                              </Grid>

                              {/* Making charges */}
                              <Grid item xs={6} sm={2}>
                                <ArgonBox mb={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                  >
                                    {"Making charges"}&nbsp;
                                  </ArgonTypography>
                                  <ArgonInput
                                    inputProps={{ min: 0 }}
                                    type="number"
                                    placeholder={item + " Purchase Price"}
                                    size="large"
                                    value={parseInt(yantraDict[item].makingCharges)}
                                    // value={gemstonesDict[item]["purchasePrice"]}
                                    onChange={(event) =>
                                      handleYantrasPriceChange(
                                        item,
                                        "makingCharges",
                                        event.target.value
                                      )
                                    }
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* yantra check box */}
                              <Grid item xs={6} sm={1}>
                                <ArgonBox
                                  mb={2}
                                  style={{ display: "flex", flexDirection: "column" }}
                                >
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                    marginTop="12px"
                                    paddingBottom="20px"
                                  >
                                    {"Sold"}&nbsp;
                                  </ArgonTypography>
                                  <Checkbox
                                    onClick={(event) =>
                                      handleYantrasSoldCheck(item, yantraDict[item].isSold)
                                    }
                                    checked={yantraDict[item].isSold}
                                  />
                                </ArgonBox>
                              </Grid>

                              {/* Total of sell and making  */}
                              <Grid item xs={6} sm={1}>
                                <ArgonBox
                                  mb={2}
                                  style={{ display: "flex", flexDirection: "column" }}
                                >
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="12px"
                                    marginTop="12px"
                                    paddingBottom="20px"
                                  >
                                    Total&nbsp;
                                  </ArgonTypography>
                                  <ArgonTypography
                                    variant="caption"
                                    color="red"
                                    fontWeight="bold"
                                    fontSize="12px"
                                    paddingBottom="20px"
                                  >
                                    {parseInt(
                                      yantraDict[item].makingCharges
                                        ? yantraDict[item].makingCharges
                                        : 0
                                    ) +
                                      parseInt(
                                        yantraDict[item].salePrice ? yantraDict[item].salePrice : 0
                                      )}
                                    &nbsp;
                                  </ArgonTypography>
                                </ArgonBox>
                              </Grid>
                            </Grid>
                          ))}
                        </ArgonBox>
                      </Card>
                    ) : null}
                  </Card>

                  {/* select Crystals */}
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                      onClick={displayCrystal}
                    >
                      Crystal <Icon style={{ fontSize: "30px !important" }}>add</Icon>
                    </ArgonTypography>
                    {showCrystal ? (
                      <Card style={{ padding: "30px" }}>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                          <Grid item xs={12} sm={4}>
                            <ArgonBox
                              mb={2}
                              onClick={() => {
                                setIsCrystalOpen(!isCrystalOpen);
                              }}
                            >
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Select Crstal &nbsp;
                              </ArgonTypography>
                              <Select
                                open={isCrystalOpen}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                multiple
                                value={crystals}
                                onChange={(event) => {
                                  toSetZeroPriceTocrystal(event.target.value);
                                  setCrystals(event.target.value);
                                }}
                                renderValue={(selected) => selected.join(", ")}
                                IconComponent={() => (
                                  <FontAwesomeIcon
                                    icon={faCaretDown}
                                    style={{ color: "#999", position: "absolute", right: "8px" }}
                                  />
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      width: Math.min(window.innerWidth * 0.7, 500), // Set the desired width for the menu
                                      width: "25%",
                                    },
                                  },
                                }}
                              >
                                {crystalsList.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox checked={crystals.indexOf(item) > -1} />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </ArgonBox>
                          </Grid>
                        </Grid>
                        <ArgonBox>
                          {crystals.map((item) => (
                            <ArgonBox key={item}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                {item}
                              </ArgonTypography>
                              <Grid container columnSpacing={3} rowSpacing={0}>
                                <Grid item xs={6} sm={3}>
                                  <ArgonBox mb={2}>
                                    <ArgonTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="bold"
                                      fontSize="12px"
                                    >
                                      Purchase Price
                                    </ArgonTypography>
                                    <ArgonInput
                                      inputProps={{ min: 0 }}
                                      type="number"
                                      placeholder={item + " Purchase Price"}
                                      size="large"
                                      value={parseInt(crystalDict[item].purchasePrice)}
                                      onChange={(event) =>
                                        handleCrystalsPriceChange(
                                          item,
                                          "purchasePrice",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </ArgonBox>
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                  <ArgonBox mb={2}>
                                    <ArgonTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="bold"
                                      fontSize="12px"
                                    >
                                      Selling Price
                                    </ArgonTypography>
                                    <ArgonInput
                                      inputProps={{ min: 0 }}
                                      type="number"
                                      placeholder={item + " Selling Price"}
                                      size="large"
                                      value={parseInt(crystalDict[item].salePrice)}
                                      onChange={(event) =>
                                        handleCrystalsPriceChange(
                                          item,
                                          "salePrice",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </ArgonBox>
                                </Grid>

                                {/* Type   */}
                                <Grid item xs={6} sm={2}>
                                  <ArgonBox mb={2}>
                                    <ArgonTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="bold"
                                      fontSize="12px"
                                    >
                                      Making Type
                                    </ArgonTypography>
                                    <select
                                      style={{
                                        height: "45px",
                                        width: "100%",
                                        borderRadius: "8px",
                                        border: "1px solid #cfcccc",
                                        padding: "8px",
                                        color: "#344767",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        fontFamily: "Open Sans, sans-serif",
                                      }}
                                      id="dropdown"
                                      value={crystalDict[item].yantraType}
                                      onChange={(event) =>
                                        handleCrystalsMakingType(item, event.target.value)
                                      }
                                    >
                                      <option value="">Select</option>
                                      <option value="Ring">Ring</option>
                                      <option value="Pendent">Pendent</option>
                                      <option value="Bracelet">Bracelet</option>
                                    </select>
                                  </ArgonBox>
                                </Grid>

                                {/* Making charges */}
                                <Grid item xs={6} sm={2}>
                                  <ArgonBox mb={2}>
                                    <ArgonTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="bold"
                                      fontSize="12px"
                                    >
                                      {"Making charges"}&nbsp;
                                    </ArgonTypography>
                                    <ArgonInput
                                      inputProps={{ min: 0 }}
                                      type="number"
                                      placeholder={item + " Purchase Price"}
                                      size="large"
                                      value={parseInt(crystalDict[item].makingCharges)}
                                      // value={gemstonesDict[item]["purchasePrice"]}
                                      onChange={(event) =>
                                        handleCrystalsPriceChange(
                                          item,
                                          "makingCharges",
                                          event.target.value
                                        )
                                      }
                                    />
                                  </ArgonBox>
                                </Grid>

                                {/* cristal sold */}
                                <Grid item xs={6} sm={1}>
                                  <ArgonBox
                                    mb={2}
                                    style={{ display: "flex", flexDirection: "column" }}
                                  >
                                    <ArgonTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="bold"
                                      fontSize="12px"
                                      marginTop="12px"
                                      paddingBottom="20px"
                                    >
                                      {"Sold"}&nbsp;
                                    </ArgonTypography>
                                    <Checkbox
                                      onClick={(event) =>
                                        handleCrystalsSoldCheck(item, crystalDict[item].isSold)
                                      }
                                      checked={crystalDict[item].isSold}
                                    />
                                  </ArgonBox>
                                </Grid>

                                {/* Total of sell and making  */}
                                <Grid item xs={6} sm={1}>
                                  <ArgonBox
                                    mb={2}
                                    style={{ display: "flex", flexDirection: "column" }}
                                  >
                                    <ArgonTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="bold"
                                      fontSize="12px"
                                      marginTop="12px"
                                      paddingBottom="20px"
                                    >
                                      Total&nbsp;
                                    </ArgonTypography>
                                    <ArgonTypography
                                      variant="caption"
                                      color="red"
                                      fontWeight="bold"
                                      fontSize="12px"
                                      paddingBottom="20px"
                                    >
                                      {parseInt(
                                        crystalDict[item].makingCharges
                                          ? crystalDict[item].makingCharges
                                          : 0
                                      ) +
                                        parseInt(
                                          crystalDict[item].salePrice
                                            ? crystalDict[item].salePrice
                                            : 0
                                        )}
                                      &nbsp;
                                    </ArgonTypography>
                                  </ArgonBox>
                                </Grid>
                              </Grid>
                            </ArgonBox>
                          ))}
                        </ArgonBox>
                      </Card>
                    ) : null}
                  </Card>

                  {/* payment received */}
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                      onClick={displayPayment}
                    >
                      Payment <Icon style={{ fontSize: "30px !important" }}>add</Icon>
                    </ArgonTypography>
                    {showPayment ? (
                      <Card style={{ padding: "30px" }}>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Amount Received
                              </ArgonTypography>
                              <ArgonInput
                                inputProps={{ min: 0 }}
                                type="number"
                                placeholder="Amount Received"
                                size="large"
                                value={consultationChargeReceived}
                                onChange={(event) => {
                                  const ChargeReceived = event.target.value;
                                  if (ChargeReceived < 0) {
                                    handleReceivedAmount(0);
                                    setconsultationChargeReceivedError(
                                      "Amount Received can not be negative"
                                    );
                                  } else {
                                    handleReceivedAmount(event.target.value);
                                    setconsultationChargeReceivedError("");
                                  }
                                }}
                              />
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {consultationChargeReceivedError}
                              </p>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Amount Received Date *&nbsp;
                              </ArgonTypography>
                              <ArgonInput
                                inputProps={{ min: 0 }}
                                type="date"
                                size="large"
                                value={amountReceivedDate}
                                onChange={(event) => {
                                  setAmountReceivedDate(event.target.value);
                                }}
                              />
                            </ArgonBox>
                          </Grid>
                        </Grid>
                        {amountReceivedList.length > 0 ? (
                          <ArgonButton
                            color="info"
                            size="small"
                            onClick={displayHistory}
                            style={{ width: "150px" }}
                          >
                            {showHistory ? "Hide" : "Show"} History
                          </ArgonButton>
                        ) : null}
                        {showHistory ? (
                          <Grid mt={1} lg={4} sm={12} xs={12}>
                            <Grid container>
                              <Grid item xs={12} sm={12} lg={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="#fee1d9"
                                  fontWeight="bold"
                                  fontSize="14px"
                                  style={{ textDecoration: "underline" }}
                                >
                                  Amount Received &nbsp;
                                </ArgonTypography>{" "}
                              </Grid>
                              <Grid item xs={12} sm={12} lg={3}>
                                <ArgonTypography
                                  variant="caption"
                                  color="#fee1d9"
                                  fontWeight="bold"
                                  fontSize="14px"
                                  style={{ textDecoration: "underline" }}
                                >
                                  Received Date &nbsp;
                                </ArgonTypography>{" "}
                              </Grid>
                            </Grid>
                            {amountReceivedList.map((payment, index) => (
                              <Grid container key={index}>
                                <Grid item xs={12} sm={12} lg={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="13px"
                                  >
                                    {payment.aReceived} &nbsp;
                                  </ArgonTypography>{" "}
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="13px"
                                  >
                                    {moment(payment.receiveDate).format("MMM Do YY, h:mm a")} &nbsp;
                                  </ArgonTypography>{" "}
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2}>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => {
                                      removeItemFromList(amountReceivedList, index);
                                    }}
                                    style={{ color: "#999", fontSize: 15 }}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        ) : null}
                      </Card>
                    ) : null}
                  </Card>

                  {/* Total bill and pending bill */}
                  <Grid container columnSpacing={3} rowSpacing={0}>
                    <Grid item xs={12} sm={4}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Total Bill
                        </ArgonTypography>
                        <ArgonInput
                          disabled={true}
                          type="text"
                          placeholder="Total Bill"
                          size="large"
                          value={
                            parseInt(consultationCharge ? consultationCharge : 0) +
                            parseInt(testDummy)
                          }
                          onChange={(event) => {
                            setTotalBill(event.target.value);
                          }}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Amount Pending
                        </ArgonTypography>
                        <ArgonInput
                          disabled={true}
                          type="text"
                          placeholder="Amount Pending"
                          size="large"
                          value={
                            submitOperation == "createConsultation"
                              ? (consultationCharge ? parseInt(consultationCharge) : 0) +
                                parseInt(testDummy) -
                                (consultationChargeReceived
                                  ? parseInt(consultationChargeReceived)
                                  : 0)
                              : (consultationCharge ? parseInt(consultationCharge) : 0) +
                                parseInt(testDummy) -
                                parseInt(pastConsultationChargeReceived) -
                                (consultationChargeReceived
                                  ? parseInt(consultationChargeReceived)
                                  : 0)
                          }
                          onChange={(event) => {
                            setTotalBill(event.target.value);
                          }}
                        />
                      </ArgonBox>
                    </Grid>
                  </Grid>

                  {/* Save button */}
                  <ArgonBox mt={4} mb={3} justifyContent="center">
                    <ArgonButton
                      color="info"
                      size="large"
                      onClick={
                        submitOperation == "createConsultation"
                          ? createConsultation
                          : updateConsultation
                      }
                    >
                      Save
                    </ArgonButton>
                    <p style={{ color: "red", fontSize: "12px" }}>{addCustomerError}</p>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default Consultation;
