// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/profile/components/Header";
import "assets/css/style.css";

// Data
import React, { useState, useEffect } from "react";
import axios from "axios";
import ArgonTypography from "components/ArgonTypography";
import { Button } from "reactstrap";
import { Grid, Icon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment/moment";
import ArgonButton from "components/ArgonButton";
import { Modal } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

// Images
import numerologyImage from "assets/images/numerology.png";
import astrologyImage from "assets/images/astrology.png";
import tarotCardImage from "assets/images/tarot-card.png";
import vastuImage from "assets/images/vastu.png";
import pdfimage from "assets/images/pdfimage.png";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SummarizeIcon from "@mui/icons-material/Summarize";
import signinImage from "assets/images/cover1.jpeg";
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

function Overview() {
  const [isOpen, setIsOpen] = useState(false);
  const colors = global.config.COLORS;
  const [responseData, setResponseData] = useState({});
  const [consultationData, setConsultationData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [birthPlace, setBirthPlace] = useState("");
  const [birthPlaceManual, setBirthPlaceManual] = useState("");
  const navigate = useNavigate();

  const { id } = queryString.parse(location.search);

  function fetchData() {
    axios
      .get(global.config.DATA.domain + "/customer/find/" + id)
      .then((response) => {
        const place = response.data["data"]["birthPlace"].split(",");
        setBirthPlaceManual(response.data["data"]["birthPlaceManual"])
        setBirthPlace(place[0].split("//")[0] + ", " + place[1].split("//")[0] + ", " + place[2]);
        setResponseData(response.data["data"]);
        setConsultationData(response.data["data"].consultations);
      })
      .catch((error) => {});
  }

  if (id) {
    useEffect(() => {
      document.title = "View Customer";
      fetchData();
    }, []);
  }

  // Consultation Calculations
  const consultationTotalBill = consultationData.reduce((total, row) => {
    return total + row.totalBill;
  }, 0);
  const consultationTotalPending = consultationData.reduce((total, row) => {
    return total + row.totalPending;
  }, 0);
  const consultationTotalReceived = consultationTotalBill - consultationTotalPending;

  const cId = responseData["_id"];

  const numerologyIcon = {
    icon: <img src={numerologyImage} style={{ width: "45px", height: "45px" }} />,
    iconStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: colors.numerologyColor,
      color: "#fff",
    },
  };
  const astrologyIcon = {
    icon: <img src={astrologyImage} style={{ width: "45px", height: "45px" }} />,
    iconStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: colors.astrologyColor,
      color: "#fff",
    },
  };
  const tarotCardIcon = {
    icon: <img src={tarotCardImage} style={{ width: "45px", height: "45px" }} />,
    iconStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: colors.tarotCardColor,
      color: "#fff",
    },
  };
  const vastuIcon = {
    icon: <img src={vastuImage} style={{ width: "45px", height: "45px" }} />,
    iconStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: colors.vastuColor,
      color: "#fff",
    },
  };

  const timeline = consultationData.map((item) => {
    let icon;
    switch (item.consultationType) {
      case 1:
        icon = numerologyIcon;
        break;
      case 2:
        icon = astrologyIcon;
        break;
      case 3:
        icon = tarotCardIcon;
        break;
      case 4:
        icon = vastuIcon;
        break;
    }
    return {
      timeLineId: item._id,
      icon: icon,
      date: item.dateOfConsultation,
      createdAtdate: item.createdAt,
      title: item.givenName,
      subtitle: item.topicOfVisit,
      place: item.place,
      desc: item.remedies,
      totalBill: item.totalBill,
      meetingNotes: item.meetingNotes,
      additionalNotes: item.additionalNotes,
      address: item.address,
      amtPending: item.totalPending,
      historyData: item.amountReceived,
      gemstoneData: item.gemstones,
      yantraData: item.yantras,
      crystalsData: item.crystals,
      uploadImageData: item.uploadImage,
      position: "right",
      timelineName: item.consultationType,
    };
  });
  const sortedTimeline = timeline.sort((a, b) => {
    const dateA = new Date(a.createdAtdate);
    const dateB = new Date(b.createdAtdate);
    return dateB - dateA;
  });

  function confirmDelete(value, timelineName) {
    if (confirm("Are you sure you want to detele this Consultation?")) {
      axios.get(global.config.DATA.domain + `/consultation/delete/${value}`).then((response) => {
        if (response.data.status) {
          fetchData();
        }
      });
    } else {
      // Do nothing!
    }
  }

  const [showButtons, setShowButtons] = useState(false);
  const handleButtonClick = () => {
    if (showButtons == false) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  };
  const handleOpenModel = (historyData) => {
    if (historyData !== undefined) {
      setSelectedData(historyData);
    } else {
      setSelectedData([]);
    }
    setIsOpen(true);
  };

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `url(${signinImage})`,
        backgroundPositionY: "50%",
      }}
    >
      <Header
        custID={responseData["_id"]}
        fullName={
          responseData["firstName"] +
          " " +
          responseData["middleName"] +
          " " +
          responseData["lastName"]
        }
        email={responseData["email"]}
        phone={responseData["phone"]}
        gender={responseData["gender"]}
        address={responseData["address"]}
        dateOfBirth={responseData["dateOfBirth"]}
        birthTime={responseData["birthTime"]}
        kundali={responseData["kundali"]}
        birthPlace={birthPlace}
        birthPlaceManual={birthPlaceManual}
        totalBill={consultationTotalBill}
        amountReceived={consultationTotalReceived}
        amountPending={consultationTotalPending}
      />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
          <ArgonButton color="black" size="medium" onClick={handleButtonClick}>
            + Add Consultation
          </ArgonButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
          {showButtons && (
            <div className="ButtonGroup">
              <Link to={`/consultation?cid=${cId}&type=${1}`}>
                <ArgonButton
                  style={{ backgroundColor: colors.numerologyColor }}
                  color="white"
                  size="medium"
                  onClick={handleButtonClick}
                >
                  <ArgonTypography
                    component="a"
                    variant="caption"
                    fontWeight="small"
                    fontSize="small"
                  >
                    + Numerology
                  </ArgonTypography>
                </ArgonButton>
              </Link>
              <Link to={`/consultation?cid=${cId}&type=${2}`}>
                <ArgonButton
                  style={{
                    marginLeft: "10px",
                    backgroundColor: colors.astrologyColor,
                    color: "white",
                  }}
                  color="white"
                  size="medium"
                  onClick={handleButtonClick}
                >
                  <ArgonTypography
                    component="a"
                    variant="caption"
                    fontWeight="small"
                    fontSize="small"
                  >
                    + Astrology
                  </ArgonTypography>
                </ArgonButton>
              </Link>
              <Link to={`/consultation?cid=${cId}&type=${3}`}>
                <ArgonButton
                  style={{ marginLeft: "10px", backgroundColor: colors.tarotCardColor }}
                  color="white"
                  size="medium"
                  onClick={handleButtonClick}
                >
                  <ArgonTypography
                    component="a"
                    variant="caption"
                    fontWeight="small"
                    fontSize="small"
                  >
                    + Tarot Card
                  </ArgonTypography>
                </ArgonButton>
              </Link>
              <Link to={`/consultation?cid=${cId}&type=${4}`}>
                <ArgonButton
                  style={{ marginLeft: "10px", backgroundColor: colors.vastuColor }}
                  color="white"
                  size="medium"
                  onClick={handleButtonClick}
                >
                  <ArgonTypography
                    component="a"
                    variant="caption"
                    fontWeight="small"
                    fontSize="small"
                  >
                    + Vastu
                  </ArgonTypography>
                </ArgonButton>
              </Link>
            </div>
          )}
        </Grid>
      </Grid>

      <ArgonBox mt={2} mb={3} style={{ textAlign: "center" }}>
        <VerticalTimeline>
          {sortedTimeline.length > 0 ? (
            sortedTimeline.map((t, i) => {
              const contentStyle = i === 0 ? {} : undefined;
              const arrowStyle = i === 0 ? { borderRight: "7px solid  #63ccee" } : undefined;

              return (
                <VerticalTimelineElement
                  key={i}
                  className="vertical-timeline-element--work"
                  contentStyle={contentStyle}
                  contentArrowStyle={arrowStyle}
                  // date={t.date && moment(t.date).format("MMM Do YY")}
                  position={t.position}
                  {...t.icon}
                >
                  {
                    <React.Fragment>
                      <Grid>
                        {t.timelineName == 1 ? (
                          <Grid
                            id="smBtn"
                            style={{
                              width: "15%",
                              "@media screen and (min-width: 400px)": {
                                width: "35%",
                              },
                              borderRadius: "20px",
                              fontSize: 13,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              backgroundColor: colors.numerologyColor,
                              textAlign: "center",
                              color: "#fff",
                              fontWeight: "700",
                            }}
                            size="medium"
                          >
                            Numerology
                          </Grid>
                        ) : (
                          <span></span>
                        )}
                        {t.timelineName == 2 ? (
                          <ArgonTypography
                            style={{
                              width: "15%",
                              borderRadius: "20px",
                              fontSize: 13,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              backgroundColor: "#b11d4f",
                              textAlign: "center",
                              color: "#fff",
                              fontWeight: "700",
                            }}
                            size="medium"
                          >
                            Astrology
                          </ArgonTypography>
                        ) : (
                          <span></span>
                        )}
                        {t.timelineName == 3 ? (
                          <ArgonTypography
                            style={{
                              width: "15%",
                              borderRadius: "20px",
                              fontSize: 13,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              backgroundColor: colors.tarotCardColor,
                              textAlign: "center",
                              color: "#fff",
                              fontWeight: "700",
                            }}
                            size="medium"
                          >
                            Tarot Card
                          </ArgonTypography>
                        ) : (
                          <span></span>
                        )}
                        {t.timelineName == 4 ? (
                          <ArgonTypography
                            style={{
                              width: "15%",
                              borderRadius: "20px",
                              fontSize: 13,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              backgroundColor: colors.vastuColor,
                              textAlign: "center",
                              color: "#fff",
                              fontWeight: "700",
                            }}
                            size="medium"
                          >
                            Vastu Consultation
                          </ArgonTypography>
                        ) : (
                          <span></span>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ textAlign: "start" }}>
                          <p style={{ fontSize: "14px", fontStyle: "italic" }}>
                            {t.date && moment(t.date).format("MMM Do YYYY")}
                          </p>
                          {t.timelineName == 1 ? (
                            t.title && (
                              <h5>
                                <span style={{ fontSize: "16px" }}>
                                  {t.title && responseData["firstName"]}
                                </span>{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                                {"  "}
                                <span style={{ fontSize: "20px" }}>{t.title && t.title}</span>
                              </h5>
                            )
                          ) : (
                            <span></span>
                          )}

                          {t.place ? (
                            <h6 className="vertical-timeline-element-subtitle">
                              Place : {t.place && t.place}
                            </h6>
                          ) : (
                            <h6 className="vertical-timeline-element-subtitle">
                              Topic Of Visit:{" "}
                              {t.subtitle &&
                                t.subtitle.replace("Other///", " ").replace("///", " ")}
                            </h6>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={3}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <Grid>
                            <Grid style={{ display: "flex" }}>
                              <b>{t.totalBill && t.totalBill}</b>
                            </Grid>
                            <span
                              style={{
                                fontSize: "14px",
                                display: "flex",
                              }}
                            >
                              Total (
                              <CurrencyRupeeIcon style={{ marginTop: "5px" }}></CurrencyRupeeIcon>)
                            </span>
                          </Grid>
                          {t.amtPending != "0" ? (
                            <Grid>
                              <Grid style={{ display: "flex", color: "red" }}>
                                <b>{t.amtPending}</b>
                              </Grid>
                              <span style={{ fontSize: "14px", display: "flex" }}>
                                Pending (
                                <CurrencyRupeeIcon style={{ marginTop: "5px" }}></CurrencyRupeeIcon>
                                )
                              </span>
                            </Grid>
                          ) : (
                            <Grid></Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={3}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Tooltip title="Edit" placement="top" arrow>
                            <Link
                              to={`/consultation?cid=${cId}&id=${t.timeLineId}&type=${t.timelineName}`}
                            >
                              <ArgonTypography
                                component="a"
                                variant="caption"
                                color="secondary"
                                fontWeight="medium"
                              >
                                <Button
                                  style={{
                                    fontSize: "18px",
                                    backgroundColor: "white",
                                    color: "black",
                                    border: 0,
                                    width: "25px",
                                  }}
                                >
                                  <Icon>edit</Icon>
                                </Button>
                              </ArgonTypography>
                            </Link>
                          </Tooltip>
                          <Tooltip title="Delete" placement="top" arrow>
                            <ArgonTypography
                              component="a"
                              href="#"
                              variant="caption"
                              color="secondary"
                              fontWeight="medium"
                              onClick={() => confirmDelete(t.timeLineId, t.timelineName)}
                            >
                              <Button
                                style={{
                                  fontSize: "18px",
                                  backgroundColor: "white",
                                  color: "black",
                                  border: 0,
                                  width: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                <DeleteIcon></DeleteIcon>
                              </Button>
                            </ArgonTypography>
                          </Tooltip>
                          <Tooltip title="Payment History" placement="top" arrow>
                            <ArgonTypography
                              component="a"
                              variant="caption"
                              color="secondary"
                              fontWeight="medium"
                            >
                              <Button
                                style={{
                                  fontSize: "18px",
                                  backgroundColor: "white",
                                  color: "black",
                                  border: 0,
                                  width: "25px",
                                  marginTop: "5px",
                                }}
                                onClick={() => handleOpenModel(t.historyData)}
                              >
                                <HistoryIcon></HistoryIcon>
                              </Button>
                            </ArgonTypography>
                          </Tooltip>
                          {t.timelineName == 1 && (
                            <Tooltip title="Report" placement="top" arrow>
                              {/* <Link
                                to={`/report?cid=${cId}&id=${t.timeLineId}&type=${t.timelineName}`}
                              > */}
                                <ArgonTypography
                                  component="a"
                                  variant="caption"
                                  color="secondary"
                                  style={{marginTop:5,marginLeft:3}}
                                  fontWeight="medium"
                                >
                                  <Button
                                 
                                    style={{

                                      fontSize: "18px",
                                      backgroundColor: "white",
                                      color: "black",
                                      border: 0,
                                      width: "25px",
                                    }}
                                    href={`/report?cid=${cId}&id=${t.timeLineId}&type=${t.timelineName}`}
                                  >
                                    <SummarizeIcon></SummarizeIcon>
                                  </Button>
                                </ArgonTypography>
                              {/* </Link> */}
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                      <Grid style={{ textAlign: "start" }}>
                        {t.desc && <p>Remedies : {t.desc}</p>}
                      </Grid>
                      <Grid style={{ textAlign: "start" }}>
                        {t.meetingNotes && <p>Meeting Notes : {t.meetingNotes}</p>}
                      </Grid>
                      <Grid style={{ textAlign: "start" }}>
                        {t.additionalNotes && <p>Additional Notes : {t.additionalNotes}</p>}
                      </Grid>
                      <Grid mt={1} style={{ textAlign: "start", display: "flex" }}>
                        {t.uploadImageData &&
                          t.uploadImageData.map((item, index) => (
                            <div key={item} style={{ alignItems: "center", marginRight: "20px" }}>
                              <a
                                href={`${global.config.DATA.filePath}/../uploads/${item.filePath}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {/* <img
                                    src={`${global.config.DATA.filePath}/../uploads/${item.filePath}`}
                                    style={{ width: "45px", height: "45px" }}
                                  ></img> */}
                                {[".png", ".jpg", ".jpeg"].includes(
                                  item.filePath.substring(item.filePath.lastIndexOf("."))
                                ) ? (
                                  <img
                                    src={`${global.config.DATA.filePath}/../uploads/${item.filePath}`}
                                    style={{ width: "45px", height: "45px" }}
                                    alt="Image"
                                  />
                                ) : (
                                  <img
                                    src={pdfimage}
                                    style={{ width: "45px", height: "45px" }}
                                    alt="pdf"
                                  />
                                )}
                              </a>
                            </div>
                          ))}
                      </Grid>
                      <Grid
                        container
                        style={{ display: "inline-flex", width: "100%", justifyContent: "end" }}
                      >
                        {t.gemstoneData.length > 0 ? (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={3}
                            style={{ display: "flex", marginLeft: "5px" }}
                          >
                            {t.gemstoneData.length > 0 ? (
                              <span
                                style={{ fontSize: "13px", marginTop: "18px", marginRight: "5px" }}
                              >
                                Gemstones:
                              </span>
                            ) : (
                              <span></span>
                            )}
                            <p>
                              {t.gemstoneData && t.gemstoneData.length == 0 ? (
                                <span></span>
                              ) : (
                                t.gemstoneData &&
                                t.gemstoneData
                                  .map((gemstone) => (
                                    <span
                                      style={{ fontSize: "14px", fontWeight: "700" }}
                                      key={gemstone._id}
                                    >
                                      {gemstone.type.name}
                                    </span>
                                  ))
                                  .reduce((prev, curr) => [prev, ", ", curr])
                              )}
                            </p>
                          </Grid>
                        ) : (
                          <span></span>
                        )}
                        {t.yantraData.length > 0 ? (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={3}
                            style={{ display: "flex", marginLeft: "5px" }}
                          >
                            {t.yantraData.length > 0 ? (
                              <span
                                style={{ fontSize: "13px", marginTop: "18px", marginRight: "5px" }}
                              >
                                Yantras:
                              </span>
                            ) : (
                              <span></span>
                            )}
                            <p>
                              {t.yantraData && t.yantraData.length == 0 ? (
                                <span></span>
                              ) : (
                                t.yantraData &&
                                t.yantraData
                                  .map((yantra) => (
                                    <span
                                      style={{ fontSize: "14px", fontWeight: "700" }}
                                      key={yantra._id}
                                    >
                                      {yantra.type.name}
                                    </span>
                                  ))
                                  .reduce((prev, curr) => [prev, ", ", curr])
                              )}
                            </p>
                          </Grid>
                        ) : (
                          <span></span>
                        )}
                        {t.crystalsData.length > 0 ? (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={3}
                            style={{ display: "flex", marginLeft: "5px" }}
                          >
                            {t.crystalsData.length > 0 ? (
                              <span
                                style={{ fontSize: "13px", marginTop: "18px", marginRight: "5px" }}
                              >
                                Crystal:
                              </span>
                            ) : (
                              <span></span>
                            )}
                            <p>
                              {t.crystalsData && t.crystalsData.length == 0 ? (
                                <span></span>
                              ) : (
                                t.crystalsData &&
                                t.crystalsData
                                  .map((crystal) => (
                                    <span
                                      style={{ fontSize: "14px", fontWeight: "700" }}
                                      key={crystal._id}
                                    >
                                      {crystal.type.name}
                                    </span>
                                  ))
                                  .reduce((prev, curr) => [prev, ", ", curr])
                              )}
                            </p>
                          </Grid>
                        ) : (
                          <span></span>
                        )}
                      </Grid>
                    </React.Fragment>
                  }
                </VerticalTimelineElement>
              );
            })
          ) : (
            <span
              style={{
                padding: "10px",
                textAlign: "center",
                position: "inherit",
                fontSize: "15px",
                fontWeight: 600,
              }}
            >
              Nothing Added yet.
            </span>
          )}
        </VerticalTimeline>
      </ArgonBox>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          center
          styles={{ modal: { maxWidth: "500px" } }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "500px",
              minHeight: "auto",
              marginTop: "100px",
              marginLeft: "500px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                backgroundColor: "#fee1d9",
                padding: 10,
              }}
            >
              <div style={{ fontSize: 19, fontWeight: 800 }}>Payment History</div>
              <div style={{ display: "flex", justifyContent: "end", padding: 5 }}>
                <CloseIcon onClick={() => setIsOpen(false)}></CloseIcon>
              </div>
            </div>
            <div style={{ padding: 30 }}>
              <Grid container>
                <Grid item xs={6} sm={6} lg={5}>
                  <ArgonTypography
                    variant="caption"
                    color="#fee1d9"
                    fontWeight="bold"
                    fontSize="14px"
                    style={{ textDecoration: "underline" }}
                  >
                    Amount Received &nbsp;
                  </ArgonTypography>{" "}
                </Grid>
                <Grid item xs={6} sm={6} lg={5}>
                  <ArgonTypography
                    variant="caption"
                    color="#fee1d9"
                    fontWeight="bold"
                    fontSize="14px"
                    style={{ textDecoration: "underline" }}
                  >
                    Received Date &nbsp;
                  </ArgonTypography>{" "}
                </Grid>
              </Grid>

              {selectedData.length > 0 ? (
                selectedData.reverse().map((payment, index) => (
                  <Grid container key={index}>
                    <Grid item xs={6} sm={6} lg={5}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        fontWeight="bold"
                        fontSize="13px"
                      >
                        {payment.aReceived} &nbsp;
                      </ArgonTypography>{" "}
                    </Grid>
                    <Grid item xs={6} sm={6} lg={5}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        fontWeight="bold"
                        fontSize="13px"
                      >
                        {moment(payment.receiveDate).format("MMM Do YYYY")} &nbsp;
                      </ArgonTypography>{" "}
                    </Grid>
                  </Grid>
                ))
              ) : (
                <p> No payments to display.</p>
              )}
            </div>
          </div>
        </Modal>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
