// @mui material components
import { useNavigate, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";

// Argon Dashboard 2 MUI components
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function Transactions() {
  const [inputEmail, setEmail] = useState("");
  const [inputPassword, setPassword] = useState("");
  const [inputName, setName] = useState("");
  const [inputConfirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [formOperation, setFormOperation] = useState("Add Admin");
  const [encryptedPassword,setEncryptedPassword]=useState("");
  const { id } = queryString.parse(location.search);
  const navigate = useNavigate();
  var md5 = require("md5");

  if (id) {
    useEffect(() => {
      axios
        .get(global.config.DATA.domain + "/admins/find/" + id)
        .then((response) => {
          if (response.data["status"] == true) {
            setFormOperation("Update Admin");
            setName(response.data.data["name"]);
            setEmail(response.data.data["email"]);
            setEncryptedPassword(response.data.data["password"]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
    if (inputEmail != "") {
      setEmailError("");
    }
  }
  function handleNameChange(event) {
    setName(event.target.value);
    if (inputName != "") {
      setNameError("");
    }
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
    if (inputPassword != "") {
      setPasswordError("");
    }
  }

  function handleConfirmPasswordChange(event) {
    setConfirmPassword(event.target.value);
    if (inputConfirmPassword != "") {
      setPasswordError("");
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  function createAdmin(event) {
    setEmailError("");
    setLoginError("");
    setNameError("");
    setPasswordError("");

    if (
      validateEmail(inputEmail) &&
      inputPassword != "" &&
      inputName != "" &&
      inputPassword == inputConfirmPassword
    ) {
      axios
        .post(global.config.DATA.domain + "/admins/register", {
          email: inputEmail,
          name: inputName,
          password: md5(inputPassword),
          confirmPassword: md5(inputConfirmPassword),
        })
        .then((response) => {
          if (response.data["status"] == "true") {
            navigate("/admins");
          } else {
            setLoginError("Invalid Email or Password else ");
          }
        })
        .catch((error) => {
          console.error(error);
          setLoginError("Invalid Email or Password in catch ");
        });
    } else {
      if (inputEmail == "") {
        setEmailError("Email Required");
      } else if (!validateEmail(inputEmail)) {
        setEmailError("Invalid Email");
      }
      if (inputName == "") {
        setNameError("Admin Name Required");
      }

      if (inputPassword == "") {
        setPasswordError("Password Required");
      } else if (inputPassword != inputConfirmPassword) {
        setPasswordError("Password does not match");
      }
    }
  }

  function updateAdmin(event) {
    setEmailError("");
    setLoginError("");
    setNameError("");
    setPasswordError("");

    if (
      validateEmail(inputEmail) &&
      (inputPassword.length>7 || inputPassword.length==0) &&
      inputName != "" &&
      inputPassword == inputConfirmPassword
    ) {
      axios
        .put(global.config.DATA.domain + "/admins/updateAdmin/" + id, {
          email: inputEmail,
          name: inputName,
          password: (inputPassword)? md5(inputPassword):encryptedPassword,
          confirmPassword: (inputPassword)? md5(inputPassword):encryptedPassword,
        })
        .then((response) => {
          if (response.data["status"] == true) {
            navigate("/admins");
          } else {
            setLoginError(response.data["message"]);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoginError("Invalid Email or Password in catchhh ");
        });
    } else {
      if (inputEmail == "") {
        setEmailError("Email Required");
      } else if (!validateEmail(inputEmail)) {
        setEmailError("Invalid Email");
      }
      if (inputName == "") {
        setNameError("Admin Name Required");
      }

      if (inputPassword == "") {
        setPasswordError("Password Required");
      } else if (inputPassword != inputConfirmPassword) {
        setPasswordError("Password does not match");
      }
      if(inputPassword.length<=7 || inputPassword.length==0){
        setPasswordError("Password must be at least 8 characters");
      }
    }
  }
  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Add Admin&apos;s
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox pt={3} pb={2} px={2}>
      <Card style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}>

      
      <ArgonTypography
          variant="caption"
          color="text"
          fontWeight="bold"
          textTransform="uppercase"
          paddingTop="10px"
          style={{
            backgroundColor: "#fee1d9",
            padding: "15px",
            fontSize: "14px",
            color: "#000",
          }}
        >
          Add Admin&apos;s
        </ArgonTypography>
        
        <ArgonBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={1}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <ArgonBox component="form" role="form">
          <Card style={{ padding: "30px" }}>
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={12} sm={4}>
                <ArgonBox mb={2}>
                  <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                    Email
                  </ArgonTypography>
                  <ArgonInput
                    type="email"
                    placeholder="Email"
                    size="large"
                    value={inputEmail}
                    onChange={handleEmailChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>{emailError}</p>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ArgonBox mb={2}>
                  <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                    Name
                  </ArgonTypography>
                  <ArgonInput
                    type="name"
                    placeholder="Name"
                    size="large"
                    value={inputName}
                    onChange={handleNameChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>{nameError}</p>
                </ArgonBox>
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={12} sm={4}>
                <ArgonBox mb={2}>
                  <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                    {id?("New "):("")}
                    Password
                  </ArgonTypography>
                  <ArgonInput
                    type="password"
                    placeholder="Password"
                    size="large"
                    value={inputPassword}
                    onChange={handlePasswordChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>{passwordError}</p>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ArgonBox mb={2}>
                  <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                    Confirm Password
                  </ArgonTypography>
                  <ArgonInput
                    type="password"
                    placeholder="Confirm Password"
                    size="large"
                    value={inputConfirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>{passwordError}</p>
                </ArgonBox>
              </Grid>
            </Grid>
            <ArgonBox mt={4} mb={3} justifyContent="center">
              <ArgonButton
                color="info"
                size="large"
                onClick={formOperation == "Add Admin" ? createAdmin : updateAdmin}
              >
                {formOperation}
              </ArgonButton>
              <p style={{ color: "red", fontSize: "12px" }}>{loginError}</p>
            </ArgonBox>
            </Card>
          </ArgonBox>
        </ArgonBox>
        
        </Card>
      </ArgonBox>
    </Card>
  );
}

export default Transactions;