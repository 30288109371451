import { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import { Grid } from "@mui/material";
import "../../styles/style.css";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import "config";

// Images and Icons
import loader from "assets/images/pdf/loader-numo.gif";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function ProfitReport() {
  const reportTemplateRef = useRef(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [doSearch, setDoSearch] = useState(false);
  const [searchError, setSearchError] = useState("");

  const [isLoading, setIsLoading] = useState("");
  const [responseData, setResponseData] = useState("");

  const handleExportPDFNew = async () => {
    setIsLoading(true);
    const content = document.querySelector("#element-to-print");

    const pageWidth = 20.5; // in cm
    const pageHeight = 30.5; // in cm

    const doc = new jsPDF("portrait", "pt", "a4");

    // Get all the pages to export
    const pages = document.querySelectorAll(".page1");

    // Loop through each page and export it to PDF
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      // Calculate the scroll position of the page
      const scrollPos = i > 0 ? page.offsetTop - pages[0].offsetTop : 0;

      // Export the page to canvas
      const canvas = await html2canvas(page, {
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        scrollY: scrollPos,
        useCORS: true,
        allowTaint: true,
        scale: 3.6, // 0.8 for a4 , 1.15 for a3
        quality: 1,
        logging: true,
        dpi: 300,
        padding: [50, 50, 50, 50],
      });

      // Add the canvas to the PDF
      if (i > 0) {
        doc.addPage();
      }
      doc.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        20,
        20,
        canvas.width / 6,
        canvas.height / 6,
        "",
        "FAST"
      );
    }

    var pdfName;
    if (startDate == "" && endDate == "") {
      pdfName = "profit_report_all";
    } else {
      pdfName = "profit_report_" + startDate + "_to_" + endDate;
    }
    // Save the PDF
    doc.save(pdfName, { quality: 2 });

    setIsLoading(false);
  };

  const fetchData = (requestData) => {
    axios
      .post(global.config.DATA.domain + "/businessReport", requestData)
      .then((response) => {
        if (response.data.status === true) {
          setResponseData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let requestData = {
      startDate: null,
      endDate: null,
    };

    fetchData(requestData);
  }, []);

  const isDateValid = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;
    const [year, month, day] = dateString.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getFullYear() === year &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getDate() === day
    );
  };

  const isStartDateBeforeEndDate = (startDate, endDate) => {
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    return startDateObject <= endDateObject;
  };

  const handleDateRangeChange = () => {
    if (doSearch) {
      let requestData = {
        startDate: startDate,
        endDate: endDate,
      };
      fetchData(requestData);
      setDoSearch(!doSearch);
      setStartDate("");
      setEndDate("");
    } else {
      if (isDateValid(startDate) && isDateValid(endDate)) {
        if (isStartDateBeforeEndDate(startDate, endDate)) {
          setDoSearch(!doSearch);
          if (!doSearch) {
            let requestData = {
              startDate: startDate,
              endDate: endDate,
            };
            fetchData(requestData);
          }
        } else {
          setSearchError("Date From should be less than Date To");
        }
      } else {
        setSearchError("Please enter both dates");
      }
    }
  };

  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Card sx={{ height: "100%" }}>
            {/* Loader */}
            <Grid lg={12} sm={12} style={{ textAlign: "center" }}>
              {isLoading && (
                <div>
                  <img
                    src={loader}
                    style={{ width: "150px", height: "150px", objectFit: "contain" }}
                  />
                  <ArgonTypography
                    variant="h6"
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{ marginBottom: 20 }}
                  >
                    We are preparing your report. Hold on tight!
                  </ArgonTypography>
                </div>
              )}
            </Grid>

            <div
              id="element-to-print"
              ref={reportTemplateRef}
              style={{
                padding: 10,
                boxShadow: "rgb(151, 149, 150) 1px 1px 3px",
              }}
            >
              <div id="page1" className="page1" style={{ fontFamily: "Poppins" }}>
                <ArgonBox>
                  <h3
                    style={{
                      marginTop: "30px",
                      marginBottom: "40px",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                  >
                    Profit Report
                  </h3>
                  <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={0}
                    style={{ paddingLeft: "20px", paddingBottom: "5px" }}
                  >
                    <Grid item xs={12} sm={2}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        fontWeight="bold"
                        fontSize="12px"
                      >
                        Date From&nbsp;
                      </ArgonTypography>
                      <ArgonInput
                        type="date"
                        size="small"
                        value={null || startDate}
                        onChange={(event) => {
                          setStartDate(event.target.value);
                          setSearchError("");
                          setDoSearch(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        fontWeight="bold"
                        fontSize="12px"
                      >
                        Date To&nbsp;
                      </ArgonTypography>
                      <ArgonInput
                        type="date"
                        size="small"
                        value={endDate}
                        onChange={(event) => {
                          setEndDate(event.target.value);
                          setSearchError("");
                          setDoSearch(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <ArgonButton
                        color="info"
                        size="small"
                        onClick={handleDateRangeChange}
                        style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}
                      >
                        {doSearch ? "clear" : "Search"}
                      </ArgonButton>
                    </Grid>
                  </Grid>
                  <ArgonTypography
                    variant="caption"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      paddingLeft: "20px",
                      paddingBottom: "15px",
                    }}
                  >
                    {searchError}
                  </ArgonTypography>
                  <h4
                    style={{
                      marginBottom: "25px",
                      marginTop: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                  >
                    Numerology :
                  </h4>
                  <div style={{ textAlign: "center" }}>
                    <Grid
                      container
                      style={{ fontSize: "17px", padding: "10px", justifyContent: "center" }}
                      lg={10}
                    >
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#ffede3",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Item
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Amount
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Profit
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Gemstone
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["1"]?.totalGemstoneAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["1"]?.totalGemstoneProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ fontSize: "17px", padding: "10px", backgroundColor: "#ffede3" }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Yantra
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["1"]?.totalYantraAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["1"]?.totalYantraProfit}
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Crystal
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["1"]?.totalCrystalAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["1"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#eac2ac",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Profit
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                          <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                          {responseData["1"]?.totalGemstoneProfit +
                            responseData["1"]?.totalYantraProfit +
                            responseData["1"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <h4
                    style={{
                      marginBottom: "25px",
                      marginTop: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                  >
                    Astrology :
                  </h4>
                  <div style={{ textAlign: "center" }}>
                    <Grid
                      container
                      style={{ fontSize: "17px", padding: "10px", justifyContent: "center" }}
                      lg={10}
                    >
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#ffede3",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Item
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Amount
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Profit
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Gemstone
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["2"]?.totalGemstoneAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["2"]?.totalGemstoneProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ fontSize: "17px", padding: "10px", backgroundColor: "#ffede3" }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Yantra
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["2"]?.totalYantraAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["2"]?.totalYantraProfit}
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Crystal
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["2"]?.totalCrystalAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["2"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#eac2ac",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Profit
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                          <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                          {responseData["2"]?.totalGemstoneProfit +
                            responseData["2"]?.totalYantraProfit +
                            responseData["2"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <h4
                    style={{
                      marginBottom: "25px",
                      marginTop: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                  >
                    Tarot Card :
                  </h4>
                  <div style={{ textAlign: "center" }}>
                    <Grid
                      container
                      style={{ fontSize: "17px", padding: "10px", justifyContent: "center" }}
                      lg={10}
                    >
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#ffede3",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Item
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Amount
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Profit
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Gemstone
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["3"]?.totalGemstoneAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["3"]?.totalGemstoneProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ fontSize: "17px", padding: "10px", backgroundColor: "#ffede3" }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Yantra
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["3"]?.totalYantraAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["3"]?.totalYantraProfit}
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Crystal
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["3"]?.totalCrystalAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["3"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#eac2ac",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Profit
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                          <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                          {responseData["3"]?.totalGemstoneProfit +
                            responseData["3"]?.totalYantraProfit +
                            responseData["3"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </ArgonBox>
              </div>
              <div id="page2" className="page1" style={{ fontFamily: "Poppins" }}>
                <ArgonBox>
                  <h4
                    style={{
                      marginBottom: "25px",
                      marginTop: 20,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                  >
                    Vastu :
                  </h4>
                  <div style={{ textAlign: "center" }}>
                    <Grid
                      container
                      style={{ fontSize: "17px", padding: "10px", justifyContent: "center" }}
                      lg={10}
                    >
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#ffede3",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Item
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Amount
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Profit
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Gemstone
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["4"]?.totalGemstoneAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["4"]?.totalGemstoneProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ fontSize: "17px", padding: "10px", backgroundColor: "#ffede3" }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Yantra
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["4"]?.totalYantraAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["4"]?.totalYantraProfit}
                        </Grid>
                      </Grid>
                      <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Crystal
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["4"]?.totalCrystalAmount}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          {responseData["4"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#eac2ac",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Profit
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                          <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                          {responseData["4"]?.totalGemstoneProfit +
                            responseData["4"]?.totalYantraProfit +
                            responseData["4"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <h4
                    style={{
                      marginBottom: "25px",
                      marginTop: 30,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                  >
                    Final Report :
                  </h4>
                  <div style={{ textAlign: "center" }}>
                    <Grid
                      container
                      style={{ fontSize: "17px", padding: "10px", justifyContent: "center" }}
                      lg={10}
                    >
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#ffede3",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Amount
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                          <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                          {responseData["1"]?.totalGemstoneAmount +
                            responseData["1"]?.totalYantraAmount +
                            responseData["1"]?.totalCrystalAmount +
                            responseData["2"]?.totalGemstoneAmount +
                            responseData["2"]?.totalYantraAmount +
                            responseData["2"]?.totalCrystalAmount +
                            responseData["3"]?.totalGemstoneAmount +
                            responseData["3"]?.totalYantraAmount +
                            responseData["3"]?.totalCrystalAmount +
                            responseData["4"]?.totalGemstoneAmount +
                            responseData["4"]?.totalYantraAmount +
                            responseData["4"]?.totalCrystalAmount}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          padding: "10px",
                          fontWeight: "bold",
                          backgroundColor: "#ffede3",
                        }}
                      >
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          Total Profit
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                          <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                          {responseData["1"]?.totalGemstoneProfit +
                            responseData["1"]?.totalYantraProfit +
                            responseData["1"]?.totalCrystalProfit +
                            responseData["2"]?.totalGemstoneProfit +
                            responseData["2"]?.totalYantraProfit +
                            responseData["2"]?.totalCrystalProfit +
                            responseData["3"]?.totalGemstoneProfit +
                            responseData["3"]?.totalYantraProfit +
                            responseData["3"]?.totalCrystalProfit +
                            responseData["4"]?.totalGemstoneProfit +
                            responseData["4"]?.totalYantraProfit +
                            responseData["4"]?.totalCrystalProfit}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </ArgonBox>
              </div>
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <ArgonButton
                  onClick={handleExportPDFNew}
                  variant="contained"
                  style={{
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  Export Report
                </ArgonButton>
              </div>
            </div>
          </Card>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default ProfitReport;
