// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { Icon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

// Argon Dashboard 2 MUI components
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import moment from "moment/moment";
import queryString from "query-string";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import { Checkbox, MenuItem, ListItemText } from "@mui/material";
import "config";
import { Phone } from "@mui/icons-material";
// Invoice Amount == Consultation Charge
// Additional Notes ==notes
function Training() {
  const [submitOperation, setSubmitOperation] = useState("Create Training");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [travelAmount, setTravelAmount] = useState("");
  const [travelAmountError, setTravelAmountError] = useState("");
  const [trainingDate, setTrainingDate] = useState(new Date().toISOString().split("T")[0]);
  const [consultationCharge, setconsultationCharge] = useState();
  const [addTravelAmount, setAddTravelAmount] = useState(true);

  const [cTypeSelectedOption, setCTypeSelectedOption] = useState("0");
  const [tTypeSelectedOption, setTTypeSelectedOption] = useState("0");
  const [paymentBySelectedOption, setPaymentBySelectedOption] = useState("0");
  const [companyName, setCompanyName] = useState();
  const [address, setAddress] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [inputPhone, setPhone] = useState("");
  const [inputEmail, setEmail] = useState("");

  const [cTypeSelectedOptionError, setCTypeSelectedOptionError] = useState("");
  const [tTypeSelectedOptionError, setTTypeSelectedOptionError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [totalBill, setTotalBill] = useState(0);
  const [consultationChargeReceived, setconsultationChargeReceived] = useState();
  const [pastConsultationChargeReceived, setPastConsultationChargeReceived] = useState(0);
  const [consultationChargeReceivedList, setconsultationChargeReceivedList] = useState([]);
  const [amountReceivedList, setAmountReceivedList] = useState([]);
  const [consultationChargeReceivedError, setconsultationChargeReceivedError] = useState("");

  const [amountReceivedDate, setAmountReceivedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [formOperation, setFormOperation] = useState("Add Customer");
  const [addCustomerError, setAddCustomerError] = useState("");
  const [consultationChargeError, setconsultationChargeError] = useState("");

  const [showHistory, setShowHistory] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [openCorporateBox, setOpenCorporateBox] = useState(false);
  const [openIndividualBox, setOpenIndividualBox] = useState(false);

  const navigate = useNavigate();

  const { id } = queryString.parse(location.search);

  // formate date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  // Load Training Data
  useEffect(() => {
    if (id) {
      if (id) {
        setSubmitOperation("Update Training");
      } else {
        setSubmitOperation("Create Training");
      }
      axios
        .get(global.config.DATA.domain + "/training/edit/" + id)
        .then((response) => {
          if (response.data["status"] == true) {
            setCTypeSelectedOption(response.data["data"]["consultationType"]);
            setTTypeSelectedOption(response.data["data"]["trainingType"]);
            if (response.data["data"]["trainingType"] == "Corporate") {
              setOpenCorporateBox(true);
            } else {
              setOpenIndividualBox(true);
            }
            setCompanyName(response.data["data"]["companyName"]);
            setAddress(response.data["data"]["address"]);
            setContactPerson(response.data["data"]["contactPerson"]);
            setPhone(response.data["data"]["phone"]);
            setAddTravelAmount(response.data["data"]["addTravelAmount"]);

            setEmail(response.data["data"]["email"]);
            setTrainingDate(formatDate(response.data["data"]["dateOfTraining"]));
            setInvoiceNumber(response.data["data"]["invoiceNumber"]);
            setconsultationCharge(response.data["data"]["invoiceAmount"]);
            let total = 0;
            if (response.data["data"]["amountReceived"]) {
              setAmountReceivedList(response.data["data"]["amountReceived"]);
              response.data["data"]["amountReceived"].map((item) => {
                total += parseInt(item["aReceived"]);
              });
              setconsultationChargeReceivedList(response.data["data"]["amountReceived"]);
            }
            setPastConsultationChargeReceived(total);
            setTravelAmount(response.data["data"]["travelAmount"]);
            setPaymentBySelectedOption(response.data["data"]["paymentBy"]);
            setAdditionalNotes(response.data["data"]["notes"]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function displayHistory() {
    setShowHistory(!showHistory);
  }
  function displayPayment() {
    setShowPayment(!showPayment);
  }

  function handleReceivedAmount(value) {
    
    if (
      (consultationCharge ? parseInt(consultationCharge) : 0) +
        (travelAmount ? parseInt(travelAmount) : 0) -
        parseInt(pastConsultationChargeReceived) -
        (value ? parseInt(value) : 0) <
      0
    ) {
      setconsultationChargeReceivedError("Amount Received should be smaller than Total Bill");
      setconsultationChargeReceived(0);
    }else if(value<0){
      setconsultationChargeReceivedError("Amount Received can not be negative");
      setconsultationChargeReceived(0);
    }else{
      setconsultationChargeReceived(value);
      setconsultationChargeReceivedError("");
    }
  }

  const handleCTypeSelectChange = (event) => {
    setCTypeSelectedOption(event.target.value);
  };
  const handleTTypeSelectChange = (event) => {
    const selectedOption = event.target.value;
    setTTypeSelectedOption(selectedOption);
    if (selectedOption === "Corporate") {
      setOpenCorporateBox(true);
    } else {
      setOpenCorporateBox(false);
    }
    if (selectedOption === "Individual") {
      setOpenIndividualBox(true);
    } else {
      setOpenIndividualBox(false);
    }
  };
  const handlePaymentBySelectChange = (event) => {
    setPaymentBySelectedOption(event.target.value);
  };
  function removeItemFromList(list, index) {
    if (confirm("Are you sure you want to detele this Entry?")) {
      if (index > -1) {
        setPastConsultationChargeReceived(pastConsultationChargeReceived - list[index].aReceived);
        list.splice(index, 1);
      }
      setAmountReceivedList(list);
      if (amountReceivedList.length < 1) setShowHistory(false);
    } else {
    }
  }
  const validateEmail = (email) => {
    if (email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    } else {
      return true;
    }
  };

  function updateTraining(event) {
    setCTypeSelectedOptionError("");
    setTTypeSelectedOptionError("");
    setPhoneError("");
    
    if (cTypeSelectedOption == "0" || tTypeSelectedOption == "0" || ((inputPhone && inputPhone.length < 10) || (inputPhone && inputPhone.length > 12)) || !validateEmail(inputEmail)) {
      if (cTypeSelectedOption == "0") {
        setCTypeSelectedOptionError("Please select consultation type");
      }
      if (tTypeSelectedOption == "0") {
        setTTypeSelectedOptionError("Please select Training type");
      }
      if (!validateEmail(inputEmail)) {
        setEmailError("Invalid Email");
      }
      
      {inputPhone.length < 10 && inputPhone.length > 1 ? (
        setPhoneError("The phone number should be between 10 and 12 digits in length.")
      ) : null}

    } else {
      let totalReceived = 0;
      if (consultationChargeReceived > 0) {
        consultationChargeReceivedList.push({
          aReceived: consultationChargeReceived,
          receiveDate: amountReceivedDate,
        });
        totalReceived = consultationChargeReceivedList.reduce(
          (acc, obj) => acc + parseInt(obj.aReceived),
          0
        );
      } else {
        totalReceived = consultationChargeReceivedList.reduce(
          (acc, obj) => acc + parseInt(obj.aReceived),
          0
        );
      }

      axios
        .put(global.config.DATA.domain + "/training/update/" + id, {
          consultationType: cTypeSelectedOption,
          trainingType: tTypeSelectedOption,
          companyName: companyName,
          address: address,
          contactPerson: contactPerson,
          phone: inputPhone,
          email: inputEmail,
          dateOfTraining: trainingDate,
          addTravelAmount: addTravelAmount,
          invoiceNumber: invoiceNumber,
          invoiceAmount: consultationCharge,
          amountReceived: consultationChargeReceivedList,
          pendingAmount:
            parseInt(consultationCharge) +
            (addTravelAmount && travelAmount ? parseInt(travelAmount) : 0) -
            totalReceived,
          travelAmount: travelAmount,
          paymentBy: paymentBySelectedOption,
          finalTotalAmount:addTravelAmount && travelAmount
          ? parseInt(consultationCharge) + parseInt(travelAmount)
          : parseInt(consultationCharge),
          notes: additionalNotes,
          isDeleted: false,
        })
        .then((response) => {
          if (response.data["status"] == true) {
            navigate(`/training`);
          } else {
            setAddCustomerError("training not added");
          }
        })
        .catch((error) => {
          console.error("error" + error);
          setAddCustomerError("Unable to add Training");
        });
    }
  }

  function createTraining(event) {
    setCTypeSelectedOptionError("");
    setTTypeSelectedOptionError("");
    setPhoneError("");

    

    if (cTypeSelectedOption == "0" || tTypeSelectedOption == "0" || (inputPhone && inputPhone.length < 10 || inputPhone.length > 12) || !validateEmail(inputEmail)) {
      if (cTypeSelectedOption == "0") {
        setCTypeSelectedOptionError("Please select consultation type");
      }
      if (tTypeSelectedOption == "0") {
        setTTypeSelectedOptionError("Please select Training type");
      }
      if (!validateEmail(inputEmail)) {
        setEmailError("Invalid Email");
      }
      
      {inputPhone.length < 10 && inputPhone.length > 1 ? (
        setPhoneError("The phone number should be between 10 and 12 digits in length.")
      ) : null}
    } else {
      let totalReceived = 0;
      if (consultationChargeReceived > 0) {
        consultationChargeReceivedList.push({
          aReceived: consultationChargeReceived,
          receiveDate: amountReceivedDate,
        });
        totalReceived = consultationChargeReceivedList.reduce(
          (acc, obj) => acc + parseInt(obj.aReceived),
          0
        );
      }
      axios
        .post(global.config.DATA.domain + "/training/create", {
          consultationType: cTypeSelectedOption,
          trainingType: tTypeSelectedOption,
          companyName: companyName,
          address: address,
          contactPerson: contactPerson,
          phone: inputPhone,
          email: inputEmail,
          dateOfTraining: trainingDate,
          invoiceNumber: invoiceNumber,
          addTravelAmount: addTravelAmount,
          invoiceAmount: consultationCharge,
          amountReceived: consultationChargeReceivedList,
          pendingAmount:
            parseInt(consultationCharge) +
            (addTravelAmount && travelAmount ? parseInt(travelAmount) : 0) -
            totalReceived,
          travelAmount: travelAmount,
          paymentBy: paymentBySelectedOption,
          finalTotalAmount:addTravelAmount && travelAmount
          ? parseInt(consultationCharge) + parseInt(travelAmount)
          : parseInt(consultationCharge),
          notes: additionalNotes,
          isDeleted: false,
        })
        .then((response) => {
          if (response.data["status"] == true) {
            navigate(`/training`);
          } else {
            setAddCustomerError("Training not added");
          }
        })
        .catch((error) => {
          console.error("error" + error);
          setAddCustomerError("Unable to add Training");
        });
    }
  }
  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Card sx={{ height: "100%" }}>
            <ArgonBox pt={3} pb={2} px={2}>
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                sx={{ listStyle: "none" }}
              >
                <ArgonBox component="form" role="form">
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      Training Details
                    </ArgonTypography>
                    <Card style={{ padding: "30px", marginBottom: "15px" }}>
                      <Grid container columnSpacing={3} rowSpacing={0}>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Consultation Type *&nbsp;
                            </ArgonTypography>
                            <br></br>
                            <select
                              style={{
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              defaultValue={cTypeSelectedOption}
                              value={cTypeSelectedOption}
                              onChange={handleCTypeSelectChange}
                            >
                              <option value="0" selected>
                                Select Consultation
                              </option>
                              <option value="1">Numerology</option>
                              <option value="2">Astrology</option>
                              <option value="3">Tarot Card</option>
                              <option value="4">Vastu Consultation</option>
                            </select>
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {cTypeSelectedOptionError}
                            </p>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Training Type *&nbsp;
                            </ArgonTypography>
                            <br></br>
                            <select
                              style={{
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              value={tTypeSelectedOption}
                              onChange={handleTTypeSelectChange}
                            >
                              <option value="0" selected>
                                Select Training Type
                              </option>
                              <option value="Individual">Individual</option>
                              <option value="Corporate">Corporate</option>
                            </select>
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {tTypeSelectedOptionError}
                            </p>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Date Of Training;
                            </ArgonTypography>
                            <ArgonInput
                              type="date"
                              size="large"
                              value={trainingDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(event) => {
                                setTrainingDate(event.target.value);
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Invoice Number&nbsp;
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              placeholder="Invoice Number"
                              size="large"
                              value={invoiceNumber}
                              onChange={(event) => {
                                setInvoiceNumber(event.target.value);
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Invoice Amount
                            </ArgonTypography>
                            <ArgonInput inputProps={{ min: 0 }}
                              type="number"
                              placeholder="Invoice Amount"
                              size="large"
                              value={consultationCharge}
                              onChange={(event) => {
                                const conCharges=event.target.value;
                                if(conCharges<0){
                                  setconsultationCharge(0); 
                                setconsultationChargeError("Consultation Charges can not be negative")
                                }else{
                                  setconsultationCharge(event.target.value); 
                                  setconsultationChargeError("")
                                }
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{consultationChargeError}</p>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Travel Amount
                            </ArgonTypography>
                            <ArgonInput inputProps={{ min: 0 }}
                              type="text"
                              placeholder="Travel Amount"
                              size="large"
                              value={travelAmount}
                              onChange={(event) => {
                                const travelCharges=event.target.value;
                                if(travelCharges<0){
                                  setTravelAmount(0); 
                                setTravelAmountError("Travel Amount can not be negative")
                                }else{
                                  setTravelAmount(event.target.value); 
                                  setTravelAmountError("")
                                }
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{travelAmountError}</p>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                              marginTop="12px"
                              paddingBottom="20px"
                            >
                              Add Travel Amount
                            </ArgonTypography>
                            <Checkbox
                              onClick={(event) => setAddTravelAmount(!addTravelAmount)}
                              checked={addTravelAmount}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Travel Amount Payment
                            </ArgonTypography>
                            <br></br>
                            <select
                              style={{
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              value={paymentBySelectedOption}
                              onChange={handlePaymentBySelectChange}
                            >
                              <option value="0" selected>
                                Select Payment Type
                              </option>
                              <option value="Individual">Self</option>
                              <option value="Corporate"> Paid by company</option>
                            </select>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                      <Grid container columnSpacing={3} rowSpacing={0}>
                        <Grid item xs={12} sm={12}>
                          <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Notes
                            </ArgonTypography>
                            <textarea
                              id="outlined-multiline-static"
                              label=""
                              multiline
                              rows={6}
                              placeholder="Notes"
                              size="large"
                              value={additionalNotes}
                              fullWidth={true}
                              onChange={(event) => {
                                setAdditionalNotes(event.target.value);
                              }}
                              inputProps={{ style: { width: "100% !important", padding: 8 } }}
                              style={{
                                flex: "1",
                                padding: "8px",
                                color: "#344767",
                                fontFamily: "Open Sans, sans-serif",
                                fontSize: "13px",
                                fontWeight: "400",
                                width: "100% !important",
                                letterspacing: "inherit",
                                borderColor: "#E9EBED",
                                marginTop: 8,
                                borderRadius: 7,
                                borderWidth: "2px",
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Card>
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      {openCorporateBox ? "Corporate " : openIndividualBox ? "Individual " : ""}
                      Details <Icon style={{ fontSize: "30px !important" }}>add</Icon>
                    </ArgonTypography>
                    <Card style={{ padding: "30px", marginBottom: "15px" }}>
                      <Grid container columnSpacing={3} rowSpacing={0}>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Company Name
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              placeholder="Company Name"
                              size="large"
                              value={companyName}
                              onChange={(event) => {
                                setCompanyName(event.target.value);
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Address
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              placeholder="Address"
                              size="large"
                              value={address}
                              onChange={(event) => {
                                setAddress(event.target.value);
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                        {openCorporateBox ? (
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Contact Person
                              </ArgonTypography>
                              <ArgonInput
                                type="text"
                                placeholder="Contact Person"
                                size="large"
                                value={contactPerson}
                                onChange={(event) => {
                                  setContactPerson(event.target.value);
                                }}
                              />
                            </ArgonBox>
                          </Grid>
                        ) : null}

                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Phone Number
                            </ArgonTypography>
                            <ArgonInput
                              type="number"
                              placeholder="Phone Number"
                              size="large"
                              value={inputPhone}
                              onChange={(event) => {
                                setPhone(event.target.value), setPhoneError("");
                              }}
                            />
                            {inputPhone && inputPhone.length > 12 ? (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                The phone number should be between 10 and 12 digits in length.
                              </p>
                            ) : null}
                            <p style={{ color: "red", fontSize: "12px" }}>{phoneError}</p>
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Email
                            </ArgonTypography>
                            <ArgonInput
                              type="email"
                              placeholder="Email"
                              size="large"
                              value={inputEmail}
                              onChange={(event) => {
                                setEmail(event.target.value);
                                setEmailError("");
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{emailError}</p>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Card>

                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                      onClick={displayPayment}
                    >
                      Payment <Icon style={{ fontSize: "30px !important" }}>add</Icon>
                    </ArgonTypography>
                    {showPayment ? (
                      <Card style={{ padding: "30px", marginBottom: "15px" }}>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Amount Received
                              </ArgonTypography>
                              <ArgonInput inputProps={{ min: 0 }}
                                type="number"
                                placeholder="Amount Received"
                                size="large"
                                value={consultationChargeReceived}
                                onChange={(event) => {
                                  handleReceivedAmount(event.target.value);
                                }}
                              />
                              <p style={{ color: "red", fontSize: "12px" }}>{consultationChargeReceivedError}</p>
                            </ArgonBox>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Amount Received Date *&nbsp;
                              </ArgonTypography>
                              <ArgonInput
                                type="date"
                                size="large"
                                value={amountReceivedDate}
                                onChange={(event) => {
                                  setAmountReceivedDate(event.target.value);
                                }}
                              />
                            </ArgonBox>
                          </Grid>
                        </Grid>
                        {amountReceivedList.length > 0 ? (
                          <ArgonButton
                            color="info"
                            size="small"
                            onClick={displayHistory}
                            style={{ width: "150px" }}
                          >
                            Show History
                          </ArgonButton>
                        ) : null}
                        {showHistory ? (
                          <Grid mt={1} lg={4} sm={12} xs={12}>
                            <Grid container>
                              <Grid item xs={12} sm={12} lg={2}>
                                <ArgonTypography
                                  variant="caption"
                                  color="#fee1d9"
                                  fontWeight="bold"
                                  fontSize="14px"
                                >
                                  Amount Received &nbsp;
                                </ArgonTypography>{" "}
                              </Grid>
                              <Grid item xs={12} sm={12} lg={3}>
                                <ArgonTypography
                                  variant="caption"
                                  color="#fee1d9"
                                  fontWeight="bold"
                                  fontSize="14px"
                                >
                                  Received Date &nbsp;
                                </ArgonTypography>{" "}
                              </Grid>
                            </Grid>
                            {amountReceivedList.map((payment, index) => (
                              <Grid container key={index}>
                                <Grid item xs={12} sm={12} lg={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="13px"
                                  >
                                    {payment.aReceived} &nbsp;
                                  </ArgonTypography>{" "}
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="bold"
                                    fontSize="13px"
                                  >
                                    {moment(payment.receiveDate).format("MMM Do YY, h:mm a")} &nbsp;
                                  </ArgonTypography>{" "}
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2}>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => {
                                      removeItemFromList(amountReceivedList, index);
                                    }}
                                    style={{ color: "#999", fontSize: 15 }}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        ) : null}
                      </Card>
                    ) : null}
                  </Card>
                  <Grid container columnSpacing={3} rowSpacing={0}>
                    <Grid item xs={12} sm={4}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Total Bill
                        </ArgonTypography>
                        <ArgonInput inputProps={{ min: 0 }}
                          disabled={true}
                          type="text"
                          placeholder="Total Bill"
                          size="large"
                          value={parseInt(consultationCharge) +
                            (addTravelAmount && travelAmount ? parseInt(travelAmount) : 0)
                          }
                          onChange={(event) => {
                            setTotalBill(event.target.value);
                          }}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Amount Pending
                        </ArgonTypography>
                        <ArgonInput inputProps={{ min: 0 }}
                          disabled={true}
                          type="text"
                          placeholder="Amount Pending"
                          size="large"
                          value={
                            submitOperation == "Create Training"
                              ? (consultationCharge ? parseInt(consultationCharge) : 0) +
                                (addTravelAmount && travelAmount ? parseInt(travelAmount) : 0) -
                                (consultationChargeReceived
                                  ? parseInt(consultationChargeReceived)
                                  : 0)
                              : (consultationCharge ? parseInt(consultationCharge) : 0) +
                                (addTravelAmount && travelAmount ? parseInt(travelAmount) : 0) -
                                parseInt(pastConsultationChargeReceived) -
                                (consultationChargeReceived
                                  ? parseInt(consultationChargeReceived)
                                  : 0)
                          }
                          onChange={(event) => {
                            setTotalBill(event.target.value);
                          }}
                        />
                      </ArgonBox>
                    </Grid>
                  </Grid>
                  <ArgonBox mt={4} mb={3} justifyContent="center">
                    <ArgonButton
                      color="info"
                      size="large"
                      onClick={
                        submitOperation == "Create Training" ? createTraining : updateTraining
                      }
                    >
                      {submitOperation}
                    </ArgonButton>
                    <p style={{ color: "red", fontSize: "12px" }}>{addCustomerError}</p>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default Training;
