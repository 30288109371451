// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI components
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import PaymentMethod from "layouts/add-admin/components/PaymentMethod";
import Invoices from "layouts/add-admin/components/Invoices";
import BillingInformation from "layouts/add-admin/components/BillingInformation";
import Transactions from "layouts/add-admin/components/Transactions";

function AddAdmin() {
  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
              <Transactions />
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default AddAdmin;
