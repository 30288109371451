// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import { Link } from "react-router-dom";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import React, { useState, useEffect } from "react";
import axios from "axios";
import "config";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Icon } from "@mui/material";
import { Tooltip } from "@material-ui/core";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import SummarizeIcon from "@mui/icons-material/Summarize";

function ContactTable() {
  const [responseData, setResponseData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [filterRowData, setfilterRowData] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [doSearch, setDoSearch] = useState(false);
  const [searchError, setSearchError] = useState("");

  // useEffect hook to make the API call when the component mounts
  useEffect(() => {
    document.title = "Contacts";
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(global.config.DATA.domain + "/allcontacts")
      .then((response) => {
        if (response.data.status) {
          setResponseData(response.data.data);
          setfilterRowData(response.data.data);
        }
      })
      .catch((error) => {});
  }

  function confirmDelete(value) {
    if (confirm("Are you sure you want to detele this numerology?")) {
      axios
        .get(global.config.DATA.domain + `allcontacts`)
        .then((response) => {
          if (response.data.status) {
            fetchData();
          }
        })
        .catch((error) => {});
    } else {
    }
  }

  const { columns } = {
    columns: [
      {
        name: "SR NO",
        align: "center",
        options: {
          filter: false,
        },
      },
      { name: "NAME", align: "center" },
      { name: "PHONE NO.", align: "center" },
      { name: "EMAIL", align: "center" },
      { name: "Mode", align: "center" },
      {
        name: "SUBJECT",
        align: "center",
        options: {
          filter: false,
        },
      },
      {
        name: "DATE",
        align: "center",
        options: {
          filter: false,
        },
      },
      {
        name: "MESSAGE",
        align: "center",
        options: {
          filter: false,
        },
      },
    ],
  };

  const { rows } = {
    rows: filterRowData.map((subTask, index) => {
      return {
        "SR NO": index + 1,
        NAME: subTask.name,
        "PHONE NO.": subTask.phone,
        EMAIL: subTask.email,
        Mode: subTask.extraone,
        SUBJECT: subTask.subject,
        DATE: subTask.createdAt.split("T")[0],
        MESSAGE: subTask.message,
      };
    }),
  };

  const isDateValid = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;
    const [year, month, day] = dateString.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getFullYear() === year &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getDate() === day
    );
  };

  const isStartDateBeforeEndDate = (startDate, endDate) => {
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    return startDateObject <= endDateObject;
  };

  const handleDateRangeChange = () => {
    if (isDateValid(startDate) && isDateValid(endDate)) {
      if (isStartDateBeforeEndDate(startDate, endDate)) {
        setDoSearch(!doSearch);
        if (!doSearch) {
          setfilterRowData([]);
          // Filter the table data based on the selected date range
          const updatedData = [];
          responseData.map((data) => {
            const rowDate = new Date(data.createdAt.split("T")[0]);
            const sDate = new Date(startDate);
            const eDate = new Date(endDate);
            if (rowDate >= sDate && rowDate <= eDate) {
              updatedData.push(data);
            }
          });
          setfilterRowData(updatedData);
        } else {
          setfilterRowData(responseData);
          setStartDate("");
          setEndDate("");
        }
      } else {
        setSearchError("Date From should be less than Date To");
      }
    } else {
      setSearchError("Please enter both dates");
    }
  };

  const options = {
    filter: true,
    // filterType: "dropdown",
    responsive: "standard",
    // fixedHeader: true,
    // fixedSelectColumn: false,
    // tableBodyHeight: "400px"
    selectableRows: false,
    search: true,
    center: true,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              paddingLeft: "20px",
              backgroundColor: "#fff",
              color: "#000",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "13px",
              fontSize: "15px",
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h6">Contacts List</ArgonTypography>
            </ArgonBox>
            <Grid
              container
              columnSpacing={3}
              rowSpacing={0}
              style={{ paddingLeft: "20px", paddingBottom: "5px" }}
            >
              <Grid item xs={12} sm={2}>
                <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                  Date From&nbsp;
                </ArgonTypography>
                <ArgonInput
                  type="date"
                  placeholder="Given Name"
                  size="small"
                  value={startDate}
                  onChange={(event) => {
                    setStartDate(event.target.value), setSearchError("");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                  Date To&nbsp;
                </ArgonTypography>
                <ArgonInput
                  type="date"
                  placeholder="Given Name"
                  size="small"
                  value={endDate}
                  onChange={(event) => {
                    setEndDate(event.target.value), setSearchError("");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <ArgonButton
                  color="info"
                  size="small"
                  onClick={handleDateRangeChange}
                  style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}
                >
                  {doSearch ? "clear" : "Search"}
                </ArgonButton>
              </Grid>
            </Grid>
            <ArgonTypography
              variant="caption"
              style={{ color: "red", fontSize: "12px", paddingLeft: "20px", paddingBottom: "15px" }}
            >
              {searchError}
            </ArgonTypography>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable title={""} columns={columns} data={rows.reverse()} options={options} />
            </ThemeProvider>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ContactTable;
