// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import ReactSelect from "react-select";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import { Icon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import { MenuItem } from "@mui/material";
import { useRef } from "react";

import "../../styles/style.css";
import "config";
import { Country, State, City } from "country-state-city";
// import csc from "country-state-city";

function AddCustomer() {
  const [hour, setHour] = useState(""); // Initialize with a default value
  const [minute, setMinute] = useState(""); // Initialize with a default value
  const [ampm, setAMPM] = useState(""); // Initialize with a default value
  // const [birthTime, setBirthTime] = useState('12:00 AM'); // Initialize with a default value

  const [selectedState, setSelectedState] = useState("Maharashtra");
  const [previousSelectedState, setPreviousSelectedState] = useState("Maharashtra");
  const [selectedCity, setSelectedCity] = useState("");
  const [previousselectedCity, setPreviousSelectedCity] = useState("");
  const [selectedCities, setSelectedCities] = useState("");

  const getstate = State.getStatesOfCountry("IN");
  const [stateOptions, getStateOption] = useState(getstate);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const countrys = Country.getAllCountries();
  const [countryCode, setCountryCode] = useState("IN");
  const [stateCode, setStateCode] = useState("");

  const [inputEmail, setEmail] = useState("");
  const [inputFirstName, setFirstName] = useState("");
  const [inputMiddleName, setMiddleName] = useState("");
  const [inputLastName, setLastName] = useState("");
  const [inputPhone, setPhone] = useState("");
  const [inputBirthDate, setBirthDate] = useState("");
  const [inputBirthTime, setBirthTime] = useState("");
  const [address, setAddress] = useState("");
  const [birthPlaceManual, setBirthPlaceManual] = useState("");
  const [inputCompanyName, setCompanyName] = useState("");
  const [addCustomerError, setAddCustomerError] = useState("");
  const [selectedReferenceOption, setSelectedReferenceOption] = useState("None");
  const [selectFlagOption, setselectFlagOption] = useState("Individual");
  const [parentName, setParentName] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [selectedGenderOption, setSelectedGenderOption] = useState("Select Gender");
  const [formOperation, setFormOperation] = useState("Add Customer");
  const [inputKundali, setKundali] = useState([]);
  const [kundaliResponse, setKundaliResponse] = useState("");
  const [inputConsultationNotes, setConsultationNotes] = useState("");
  const [ConsultationNotesResponse, setConsultationNotesResponse] = useState([]);
  const [consultationFilesResponse, setConsultationFilesResponse] = useState([]);
  const [inputConsultationFiles, setConsultationFiles] = useState("");

  const [customerReferenceName, setCustomerReferenceName] = useState("");
  const [customerReferenceId, setCustomerReferenceId] = useState("0");
  const [customerList, setCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [showCustomerList, setShowCustomerList] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dateChangeError, setdateChangeError] = useState("");

  const [isGenderOpen, setIsGenderOpen] = useState(false);
  const [isReferenceOpen, setIsReferenceOpen] = useState(false);
  const interviewDateRef = useRef();
  const [isVisitOpen, setIsVisitOpen] = useState(false);
  const [isGemstoneOpen, setIsGemstoneOpen] = useState(false);
  const [isYantraOpen, setIsYantraOpen] = useState(false);
  const [isCrystalOpen, setIsCrystalOpen] = useState(false);
  const [editDisableDetails, setEditDisableDetails] = useState(true);
  const [displayCountry, setdisplayCountry] = useState(false);
  const [displayState, setdisplayState] = useState(false);
  const [displayCity, setdisplayCity] = useState(false);

  const { id } = queryString.parse(location.search);
  const navigate = useNavigate();

  // Function to update the birthTime variable
  const updateBirthTime = (newHour, newMinute, newAMPM) => {
    setBirthTime(`${newHour}:${newMinute} ${newAMPM}`);
  };

  // Define functions to handle changes in hour, minute, and AM/PM
  const handleHourChange = (event) => {
    setHour(event.target.value);
    updateBirthTime(event.target.value, minute, ampm);
  };

  const handleMinuteChange = (event) => {
    setMinute(event.target.value);
    updateBirthTime(hour, event.target.value, ampm);
  };

  const handleAMPMChange = (event) => {
    setAMPM(event.target.value);
    updateBirthTime(hour, minute, event.target.value);
  };

  const fetchCustomerList = async () => {
    axios
      .get(global.config.DATA.domain + "/referCustomer")
      .then((response) => {
        if (response.data["status"] == true) {
          setCustomerList(response.data.data);
          setFilteredCustomerList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCountryChange = (country) => {
    const [countryName, countryCode] = country.value.toString().split("//");
    setSelectedCountry(countryName);
    setCountryCode(countryCode);
    const states = State.getStatesOfCountry(countryCode);
    getStateOption(states);
    setSelectedState("");
  };

  const handleStateChange = (state) => {
    const [stateName, stateCode] = state.value.toString().split("//");
    setSelectedState(stateName);
    setStateCode(stateCode);
    const cityOptions = City.getCitiesOfState(countryCode, stateCode).map((city) => ({
      name: city.name,
      label: city.name,
    }));
    setSelectedCities(cityOptions);
    setSelectedCity("");
  };

  const handleCityChange = (city) => {
    setSelectedCity(city.label);
  };

  useEffect(() => {
    document.title = "Add Customer";
    fetchCustomerList();
    var loadAllCities = City.getCitiesOfState("IN", "MH").map((city) => ({
      name: city.name,
      label: city.name,
    }));
    setSelectedCities(loadAllCities);
  }, []);

  // format date in  YYYY/MM/DD
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  // Get customer details
  if (id) {
    useEffect(() => {
      axios
        .get(global.config.DATA.domain + "/customer/find/" + id)
        .then((response) => {
          if (response.data["status"] == true) {
            setFormOperation("Update Customer");
            setFirstName(response.data["data"]["firstName"]);
            setMiddleName(response.data["data"]["middleName"]);
            setLastName(response.data["data"]["lastName"]);
            setSelectedReferenceOption(response.data["data"]["reference"]);
            setselectFlagOption(response.data["data"]["flag"]);
            setParentName(response.data["data"]["parentName"]);
            setAdditionalNotes(response.data["data"]["additionalNotes"]);
            setBirthDate(
              response.data["data"]["dateOfBirth"]
                ? formatDate(response.data["data"]["dateOfBirth"])
                : ""
            );
            if (
              response.data?.data?.referCustomer?.length > 0 &&
              response.data.data.referCustomer[0]?.customerName
            ) {
              setCustomerReferenceName(response.data["data"]["referCustomer"][0]["customerName"]);
            }
            if (
              response.data?.data?.referCustomer?.length > 0 &&
              response.data.data.referCustomer[0]?.customerName
            ) {
              setCustomerReferenceId(response.data["data"]["referCustomer"][0]["customerId"]);
            }
            const [country, state, city] = response.data["data"]["birthPlace"].split(",");
            setSelectedCountry(country.split("//")[0]);
            setCountryCode(country.split("//")[1]);
            setSelectedState(state.split("//")[0]);
            setStateCode(state.split("//")[1]);
            setPreviousSelectedState(state);
            setSelectedCity(city);
            setPreviousSelectedCity(city);
            setBirthTime(
              response.data["data"]["birthTime"] ? response.data["data"]["birthTime"] : ""
            );
            const [hour, minute, ampm] = response.data?.data?.birthTime.split(/[: ]/);
            setHour(hour);
            setMinute(minute);
            setAMPM(ampm);
console.log(hour)
console.log(minute)
console.log(ampm)

            setCompanyName(response.data["data"]["companyName"]);
            setEmail(response.data["data"]["email"]);
            setPhone(response.data["data"]["phone"]);
            setKundaliResponse(response.data["data"]["kundali"]);
            setSelectedGenderOption(response.data["data"]["gender"]);
            setAddress(response.data["data"]["address"]);
            setBirthPlaceManual(response.data["data"]["birthPlaceManual"]);
            setConsultationFilesResponse(response.data["data"]["consultationFiles"]);
            setConsultationNotesResponse(response.data["data"]["consultationNotes"]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  }

  // validate email id
  const validateEmail = (email) => {
    if (email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    } else {
      return true;
    }
  };

  function deleteConsultationNotes(index, fileid) {
    axios
      .get(global.config.DATA.domain + "/nfile/delete/" + fileid)
      .then((response) => {
        if (response.data["status"] == true) {
          const newConsultationNotesResponse = [...ConsultationNotesResponse];
          newConsultationNotesResponse.splice(index, 1);
          setConsultationNotesResponse(newConsultationNotesResponse);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function deleteConsultationFiles(index, fileid) {
    axios
      .get(global.config.DATA.domain + "/file/delete/" + fileid)
      .then((response) => {
        if (response.data["status"] == true) {
          const newConsultationFilesResponse = [...consultationFilesResponse];
          newConsultationFilesResponse.splice(index, 1);
          setConsultationFilesResponse(newConsultationFilesResponse);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleInputChange = (event) => {
    setCustomerReferenceName(event.target.value);
    var inputText = event.target.value;
    // Filter the customerList based on the input text
    const filteredList = customerList.filter((customer) =>
      customer.firstName.toLowerCase().includes(inputText.toLowerCase())
    );
    // Set the filtered list as the new customerList
    setFilteredCustomerList(filteredList);
  };

  const handleInputClick = () => {
    setShowCustomerList(true);
  };

  const handleCustomerSelect = (name, id) => {
    setCustomerReferenceName(name);
    setCustomerReferenceId(id);
    setShowCustomerList(false);
  };

  const handlePhoneNumber = (event) => {
    setPhone(event.target.value);
    setPhoneError("");
  };
  // update customer function
  function updateCustomer(event) {
    setEmailError("");
    setFirstNameError("");
    setPhoneError("");
    setGenderError("");
    setAddCustomerError("");

    if (!validateEmail(inputEmail)) {
      setEmailError("Invalid Email");
    }
    if (selectedGenderOption == "Select Gender") {
      setGenderError("Gender Required");
    }

    if (inputPhone.length > 12) {
      setPhoneError("Invalid Phone Number");
      // if (!inputPhone) {
      //   setPhoneError("Phone Number Required");
      // }
    }
    if (inputFirstName == "") {
      setFirstNameError("First Name Required");
    }
    if (inputLastName == "") {
      setLastNameError("Last Name Required");
    }
    if (
      validateEmail(inputEmail) &&
      inputFirstName &&
      inputLastName &&
      // inputPhone.length > 9 &&
      inputPhone.length < 13 &&
      id &&
      // dateChangeError != "Invalid Date" &&
      selectedGenderOption != "Select Gender"
    ) {
      const formData = new FormData();
      formData.append("kundaliFile", inputKundali[0]);
      for (let i = 0; i < inputConsultationNotes.length; i++) {
        formData.append("consultationNotes", inputConsultationNotes[i]);
      }
      for (let i = 0; i < inputConsultationFiles.length; i++) {
        formData.append("consultationFiles", inputConsultationFiles[i]);
      }
      axios
        .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response1) => {
          var kundaliName = "";
          if (response1.data["data"]["kundaliFile"]) {
            kundaliName = response1.data["data"]["kundaliFile"][0]["filename"];
          }
          const consultationNotesNames = [];
          if (response1.data["data"]["consultationNotes"]) {
            response1.data["data"]["consultationNotes"].forEach((file) => {
              consultationNotesNames.push({
                filePath: file.filename,
                isDeleted: false,
              });
            });
          }
          const consultationFilesName = [];
          if (response1.data["data"]["consultationFiles"]) {
            response1.data["data"]["consultationFiles"].forEach((file) => {
              consultationFilesName.push({
                filePath: file.filename,
                isDeleted: false,
              });
            });
          }
          if (response1.data["status"] == true) {
            axios
              .put(global.config.DATA.domain + "/customer/update/" + id, {
                firstName: inputFirstName,
                middleName: inputMiddleName,
                lastName: inputLastName,
                email: inputEmail,
                gender: selectedGenderOption,
                phone: inputPhone,
                dateOfBirth: inputBirthDate.toString(),
                birthTime: inputBirthTime,
                birthPlace:
                  selectedCountry +
                  "//" +
                  countryCode +
                  "," +
                  selectedState +
                  "//" +
                  stateCode +
                  "," +
                  selectedCity,
                companyName: inputCompanyName,
                kundali: kundaliName ? kundaliName : kundaliResponse,
                referCustomer: [
                  {
                    customerId: customerReferenceId,
                    customerName: customerReferenceName,
                  },
                ],
                address: address,
                birthPlaceManual: birthPlaceManual,
                reference: selectedReferenceOption,
                flag: selectFlagOption,
                parentName: parentName,
                additionalNotes: additionalNotes,
                consultationNotes: consultationNotesNames
                  ? consultationNotesNames
                  : ConsultationNotesResponse,
                consultationFiles: consultationFilesName
                  ? consultationFilesName
                  : consultationFilesResponse,
              })
              .then((response) => {
                if (response.data["status"] == true) {
                  navigate(`/viewCustomer?id=${response.data["data"]._id}`);
                } else {
                  setAddCustomerError(response.data["message"]);
                }
              })
              .catch((error) => {
                setAddCustomerError("Unable to add customer");
              });
          } else {
            setAddCustomerError("file not added");
          }
        })
        .catch((error) => {
          console.log(error);
          setAddCustomerError("Unable to add file");
        });
    }
  }

  // create customer function
  function createCustomer(event) {
    console.log(inputBirthTime)
    setEmailError("");
    setFirstNameError("");
    setPhoneError("");
    setGenderError("");
    setAddCustomerError("");
    // md5 encryption

    // Email is not valid, show an error message
    if (!validateEmail(inputEmail)) {
      setEmailError("Invalid Email");
    }

    if (inputFirstName == "") {
      setFirstNameError("First Name Required");
    }
    if (inputLastName == "") {
      setLastNameError("Last Name Required");
    }
    if (selectedGenderOption == "Select Gender") {
      setGenderError("Gender Required");
    }
    if (inputPhone.length > 12) {
      setPhoneError("Invalid Phone Number");
      // if (!inputPhone) {
      //   setPhoneError("Phone Number Required");
      // }
    } else if (
      validateEmail(inputEmail) &&
      inputFirstName &&
      inputLastName &&
      // inputPhone.length > 9 &&
      inputPhone.length < 13 &&
      // dateChangeError != "Invalid Date" &&
      selectedGenderOption != "Select Gender"
    ) {
      const formData = new FormData();
      formData.append("kundaliFile", inputKundali[0]);
      for (let i = 0; i < inputConsultationNotes.length; i++) {
        formData.append("consultationNotes", inputConsultationNotes[i]);
      }
      for (let i = 0; i < inputConsultationFiles.length; i++) {
        formData.append("consultationFiles", inputConsultationFiles[i]);
      }
      axios
        .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response1) => {
          var kundaliName = "";
          if (response1.data["data"]["kundaliFile"]) {
            kundaliName = response1.data["data"]["kundaliFile"][0]["filename"];
          }
          const consultationNotesNames = [];
          if (response1.data["data"]["consultationNotes"]) {
            response1.data["data"]["consultationNotes"].forEach((file) => {
              consultationNotesNames.push({
                filePath: file.filename,
                isDeleted: false,
              });
            });
          }
          const consultationFilesName = [];
          if (response1.data["data"]["consultationFiles"]) {
            response1.data["data"]["consultationFiles"].forEach((file) => {
              consultationFilesName.push({
                filePath: file.filename,
                isDeleted: false,
              });
            });
          }
          if (response1.data["status"] == true) {
            axios
              .post(global.config.DATA.domain + "/customer/create", {
                firstName: inputFirstName,
                middleName: inputMiddleName,
                lastName: inputLastName,
                email: inputEmail,
                gender: selectedGenderOption,
                phone: inputPhone,
                dateOfBirth: inputBirthDate.toString(),
                birthTime: inputBirthTime,
                birthPlace:
                  selectedCountry +
                  "//" +
                  countryCode +
                  "," +
                  selectedState +
                  "//" +
                  stateCode +
                  "," +
                  selectedCity,
                companyName: inputCompanyName,
                kundali: kundaliName,
                referCustomer: [
                  {
                    customerId: customerReferenceId,
                    customerName: customerReferenceName,
                  },
                ],
                address: address,
                birthPlaceManual: birthPlaceManual,
                reference: selectedReferenceOption,
                flag: selectFlagOption,
                parentName: parentName,
                additionalNotes: additionalNotes,
                consultationNotes: consultationNotesNames,
                consultationFiles: consultationFilesName,
              })
              .then((response) => {
                if (response.data["status"] == true) {
                  navigate(`/viewCustomer?id=${response.data["data"][0]._id}`);
                } else {
                  setAddCustomerError(response.data["message"]);
                }
              })
              .catch((error) => {
                setAddCustomerError("Unable to add customer");
              });
          } else {
            setAddCustomerError("file not added");
          }
        })
        .catch((error) => {
          console.log(error);
          setAddCustomerError("Unable to add file");
        });
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // Add your custom styles for the control container
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // Add your custom styles for the placeholder
      color: "#344767", // Example: changing the color to red
      fontSize: 13,
    }),
  };

  // UI code
  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          {/* <Grid item xs={12} md={5}> */}
          <Card sx={{ height: "100%" }}>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
              px={2}
            >
              <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Add Customer&apos;s
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox pt={1} pb={2} px={2}>
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={1}
                m={0}
                sx={{ listStyle: "none" }}
              >
                <ArgonBox component="form" role="form">
                  <Card
                    style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}
                  >
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      Basic Details
                    </ArgonTypography>
                    <Card style={{ padding: "30px" }}>
                      <Grid container columnSpacing={3} rowSpacing={0}>
                        {/* first name */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              First Name *
                            </ArgonTypography>
                            <ArgonInput
                              type="fullname"
                              placeholder="Full Name"
                              size="large"
                              value={inputFirstName}
                              onChange={(event) => {
                                setFirstName(event.target.value), setFirstNameError("");
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{firstNameError}</p>
                          </ArgonBox>
                        </Grid>

                        {/* middle name */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Middle Name
                            </ArgonTypography>
                            <ArgonInput
                              type="middlename"
                              placeholder="Middle Name"
                              size="large"
                              value={inputMiddleName}
                              onChange={(event) => {
                                setMiddleName(event.target.value);
                              }}
                            />
                          </ArgonBox>
                        </Grid>

                        {/* last name */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Last Name *
                            </ArgonTypography>
                            <ArgonInput
                              type="lastname"
                              placeholder="Last Name"
                              size="large"
                              value={inputLastName}
                              onChange={(event) => {
                                setLastName(event.target.value), setLastNameError("");
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{lastNameError}</p>
                          </ArgonBox>
                        </Grid>

                        {/* email */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Email
                            </ArgonTypography>
                            <ArgonInput
                              type="email"
                              placeholder="Email"
                              size="large"
                              value={inputEmail}
                              onChange={(event) => {
                                setEmail(event.target.value), setEmailError("");
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{emailError}</p>
                          </ArgonBox>
                        </Grid>

                        {/* phone number */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Phone Number
                            </ArgonTypography>
                            <ArgonInput
                              type="number"
                              placeholder="Phone Number"
                              size="large"
                              value={inputPhone}
                              onChange={handlePhoneNumber}
                              // onChange={(event) => {
                              //   setPhone(event.target.value), setPhoneError("");
                              // }}
                            />
                            {inputPhone.length != 0 ? (
                              inputPhone.length < 10 || inputPhone.length > 12 ? (
                                <p style={{ color: "red", fontSize: "12px" }}>
                                  Please provide a valid phone number with a length of 10 to 12
                                  digits
                                </p>
                              ) : null
                            ) : null}

                            <p style={{ color: "red", fontSize: "12px" }}>{phoneError}</p>
                          </ArgonBox>
                        </Grid>

                        {/* select gender */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox
                            mb={2}
                            onClick={() => {
                              setIsGenderOpen(!isGenderOpen);
                            }}
                          >
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Gender *
                            </ArgonTypography>
                            <select
                              style={{
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              id="dropdownreference"
                              open={isGenderOpen}
                              value={selectedGenderOption}
                              onChange={(event) => {
                                setSelectedGenderOption(event.target.value);
                                setGenderError("");
                              }}
                            >
                              <option value="Select Gender">
                                <em>Select Gender</em>
                              </option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </select>
                            <p style={{ color: "red", fontSize: "12px" }}>{genderError}</p>
                          </ArgonBox>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <ArgonBox
                            mb={2}
                            // onClick={() => {
                            //   setIsReferenceOpen(!isReferenceOpen);
                            // }}
                          >
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Customer Type
                            </ArgonTypography>
                            <select
                              style={{
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              autoWidth
                              // open={isReferenceOpen}
                              id="dropdownflag"
                              value={selectFlagOption}
                              onChange={(event) => setselectFlagOption(event.target.value)}
                            >
                              {/* <option value="None">
                                <em>None </em>
                              </option> */}
                              <option value="Individual" selected>
                                Individual
                              </option>
                              <option value="Baby">Baby</option>
                              {/* <option value="Company">Company</option> */}
                            </select>
                          </ArgonBox>
                        </Grid>
                        {selectFlagOption == "Baby" ? (
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Parent Name
                              </ArgonTypography>
                              <ArgonInput
                                type="parentName"
                                placeholder="Parent Name"
                                size="large"
                                value={parentName}
                                onChange={(event) => {
                                  setParentName(event.target.value);
                                }}
                              />
                            </ArgonBox>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Card>
                  </Card>

                  <Card style={{ boxShadow: "rgb(151, 149, 150) 1px 1px 3px", marginTop: 20 }}>
                    <ArgonTypography
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                      paddingTop="10px"
                      style={{
                        backgroundColor: "#fee1d9",
                        padding: "15px",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      Additional Details
                    </ArgonTypography>
                    <Card style={{ padding: "30px" }}>
                      <Grid container columnSpacing={3} rowSpacing={0}>
                        {/* date of birth */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Date of Birth
                            </ArgonTypography>
                            <ArgonInput
                              type="date"
                              size="large"
                              value={inputBirthDate}
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(event) => {
                                const selectedDate = event.target.value;
                                const currentDate = new Date().toISOString().split("T")[0];
                                setdateChangeError("");
                                if (selectedDate <= currentDate) {
                                  setBirthDate(selectedDate);
                                } else {
                                  setBirthDate("");
                                  setdateChangeError("Future date not valid");
                                }
                              }}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>{dateChangeError}</p>
                          </ArgonBox>
                        </Grid>

                        {/* birth time */}
                        {/* <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Birth Time
                            </ArgonTypography>
                            <ArgonInput
                              type="time"
                              placeholder="Birth Time"
                              size="large"
                              value={inputBirthTime}
                              onChange={(event) => setBirthTime(event.target.value)}
                            />
                          </ArgonBox>
                        </Grid> */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Birth Time
                            </ArgonTypography>
                            <br></br>
                            <select style={{
                                height: "45px",
                                width: "30%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                                marginRight:"10px"
                              }}
                              autoWidth
                               id="hour" value={hour} onChange={handleHourChange}>
                              {/* Add options for hours */}
                              <option value="" disabled>Select</option>
                              {Array.from({ length: 12 }, (_, i) => (
                                <option key={i} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                            <select style={{
                                height: "45px",
                                width: "30%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                                marginRight:"10px"
                              }}
                              autoWidth id="minute" value={minute} onChange={handleMinuteChange}>
                              {/* Add options for minutes */}
                              <option value="" disabled>Select</option>
                              {Array.from({ length: 60 }, (_, i) => (
                                <option key={i} value={i < 10 ? `0${i}` : `${i}`}>
                                  {i < 10 ? `0${i}` : `${i}`}
                                </option>
                              ))}
                            </select>
                            <select style={{
                                height: "45px",
                                width: "30%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              autoWidth id="ampm" value={ampm} onChange={handleAMPMChange}>
                              {/* Add options for AM/PM */}
                              <option value="" disabled>Select</option>
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </ArgonBox>
                        </Grid>

                        {/* Country */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Birth Country
                            </ArgonTypography>
                            {displayCountry ? (
                              <ReactSelect
                                id="country"
                                name="country"
                                label="Country"
                                placeholder="Select Country"
                                // styles={{

                                // }}
                                styles={customStyles}
                                autoFocus={displayCountry}
                                defaultMenuIsOpen={displayCountry}
                                defaultValue={selectedCountry}
                                onChange={(selectedOption) => {
                                  handleCountryChange(selectedOption);
                                }}
                                options={countrys.map((item) => ({
                                  label: item.name,
                                  value: item.name + "//" + item.isoCode,
                                }))}
                              />
                            ) : (
                              <ArgonBox
                                mb={2}
                                style={{
                                  height: "45px",
                                  border: "1px solid #cac5c5",
                                  borderRadius: "6px",
                                }}
                                onClick={() => {
                                  setdisplayCountry(true);
                                }}
                              >
                                <p style={{ marginLeft: "8px", marginTop: "10px", fontSize: 13 }}>
                                  {selectedCountry}
                                </p>
                              </ArgonBox>
                            )}
                          </ArgonBox>
                        </Grid>

                        {/* birth state */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Birth State
                            </ArgonTypography>
                            {displayState ? (
                              <ReactSelect
                                id="state"
                                name="state"
                                label="State"
                                placeholder="Select State"
                                autoFocus={displayState}
                                styles={customStyles}
                                defaultMenuIsOpen={displayState}
                                defaultValue={previousSelectedState || selectedState}
                                onChange={(selectedOption) => {
                                  handleStateChange(selectedOption);
                                }}
                                options={stateOptions.map((item) => ({
                                  label: item.name,
                                  value: item.name + "//" + item.isoCode,
                                }))}
                              />
                            ) : (
                              <ArgonBox
                                mb={2}
                                style={{
                                  height: "45px",
                                  border: "1px solid #cac5c5",
                                  borderRadius: "6px",
                                }}
                                onClick={() => {
                                  setdisplayState(true);
                                }}
                              >
                                <p style={{ marginLeft: "8px", marginTop: "10px", fontSize: 13 }}>
                                  {selectedState}
                                </p>
                              </ArgonBox>
                            )}
                          </ArgonBox>
                        </Grid>
                        {/* birth city */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Birth City
                            </ArgonTypography>
                            {displayCity ? (
                              <ReactSelect
                                id="city"
                                name="city"
                                label="city"
                                placeholder="Select City"
                                autoFocus={displayCity}
                                defaultValue={selectedCity}
                                defaultMenuIsOpen={displayCity}
                                styles={customStyles}
                                onChange={handleCityChange}
                                options={selectedCities.map((item) => ({
                                  label: item.name,
                                  value: item.name + "//" + item.isoCode,
                                }))}
                                // options={selectedCities}
                              />
                            ) : (
                              <ArgonBox
                                mb={2}
                                style={{
                                  height: "45px",
                                  border: "1px solid #cac5c5",
                                  borderRadius: "6px",
                                }}
                                onClick={() => setdisplayCity(true)}
                              >
                                <p style={{ marginLeft: "8px", marginTop: "10px", fontSize: 13 }}>
                                  {selectedCity}
                                </p>
                              </ArgonBox>
                            )}
                          </ArgonBox>
                        </Grid>

                        {/* Birth PLace */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Birth Place
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              placeholder="Birth Place"
                              size="large"
                              value={birthPlaceManual}
                              onChange={(event) => setBirthPlaceManual(event.target.value)}
                            />
                          </ArgonBox>
                        </Grid>

                        {/* address */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Address
                            </ArgonTypography>
                            <ArgonInput
                              type="text"
                              placeholder="Address"
                              size="large"
                              value={address}
                              onChange={(event) => setAddress(event.target.value)}
                            />
                          </ArgonBox>
                        </Grid>
                        {/* company name */}
                        <Grid item xs={12} sm={4}>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="bold"
                            fontSize="12px"
                          >
                            Company Name
                          </ArgonTypography>
                          <ArgonBox mb={2}>
                            <ArgonInput
                              type="text"
                              placeholder="Company Name"
                              size="large"
                              value={inputCompanyName}
                              onChange={(event) => setCompanyName(event.target.value)}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox
                            mb={2}
                            onClick={() => {
                              setIsReferenceOpen(!isReferenceOpen);
                            }}
                          >
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Reference
                            </ArgonTypography>
                            <select
                              style={{
                                height: "45px",
                                width: "100%",
                                borderRadius: "8px",
                                border: "1px solid #cfcccc",
                                padding: "8px",
                                color: "#344767",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Open Sans, sans-serif",
                              }}
                              autoWidth
                              open={isReferenceOpen}
                              id="dropdown"
                              value={selectedReferenceOption}
                              onChange={(event) => setSelectedReferenceOption(event.target.value)}
                            >
                              <option value="None">
                                <em>None </em>
                              </option>
                              <option value="Social media">Social media</option>
                              <option value="Customer referral">Customer referral</option>
                              <option value="Trainings">Trainings</option>
                              <option value="Network Meetings">Network Meetings</option>
                              <option value="Trade Shows">Trade Shows</option>
                            </select>
                          </ArgonBox>
                        </Grid>
                        {selectedReferenceOption == "Customer referral" ? (
                          // Specify Customer referral
                          <Grid item xs={12} sm={4}>
                            <ArgonBox mb={2}>
                              <ArgonTypography
                                variant="caption"
                                color="text"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Name of Customer referral
                              </ArgonTypography>
                              <ArgonInput
                                type="text"
                                placeholder="Name of Customer"
                                size="large"
                                value={customerReferenceName}
                                onChange={handleInputChange}
                                onClick={handleInputClick}
                              />
                              {showCustomerList && (
                                <ul type="none">
                                  {filteredCustomerList.map((customer) => (
                                    <li
                                      style={{
                                        paddingLeft: "10px",
                                        border: "1px solid #ddd3d3",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() =>
                                        handleCustomerSelect(customer.firstName, customer._id)
                                      }
                                      key={customer._id}
                                    >
                                      {customer.firstName}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </ArgonBox>
                          </Grid>
                        ) : null}
                        {/* kundli */}
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Kundali
                            </ArgonTypography>
                            <ArgonInput
                              type="file"
                              size="large"
                              multiple
                              // value={inputKundali}
                              onChange={(event) => setKundali(event.target.files)}
                            />
                          </ArgonBox>
                          {isNaN(kundaliResponse) ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <a
                                href={`${global.config.DATA.filePath}/../uploads/${kundaliResponse}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <p
                                  style={{
                                    color: "#4b4b4b",
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    flex: "1",
                                    marginRight: "8px",
                                  }}
                                >
                                  {kundaliResponse}
                                </p>
                              </a>
                              {/* <span style={{ color: "red", fontSize: "11px", fontWeight:"bold" }}>&#x2718;</span> */}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                              marginTop="12px"
                            >
                              Additional Notes
                            </ArgonTypography>
                            <textarea
                              id="outlined-multiline-static"
                              label=""
                              multiline
                              rows={6}
                              placeholder="Additional Notes"
                              size="large"
                              value={additionalNotes}
                              // fullWidth={true}
                              onChange={(event) => {
                                setAdditionalNotes(event.target.value);
                              }}
                              inputProps={{ style: { width: "100% !important", padding: 8 } }}
                              style={{
                                flex: "1",
                                padding: "8px",
                                color: "#344767",
                                fontFamily: "Open Sans, sans-serif",
                                fontSize: "13px",
                                fontWeight: "400",
                                width: "100% !important",
                                letterspacing: "inherit",
                                borderColor: "#E9EBED",
                                marginTop: 8,
                                borderRadius: 7,
                                borderWidth: "2px",
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                        {/* Consultation Notes */}
                        {/* <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Consultation Notes
                            </ArgonTypography>
                            <div style={{ border: '0.5px solid #bdbfc2', borderRadius: 7, padding: 13, paddingTop: 3 }}>
                              <input type="file" multiple onChange={(event) => setConsultationNotes(event.target.files)} />
                            </div>
                          </ArgonBox>
                          {
                            ConsultationNotesResponse.map((item, index) => (
                              <div key={item} style={{ display: "flex", alignItems: "center" }}>
                                <a href={`${global.config.DATA.filePath}/../uploads/${item.filePath}`} target="_blank" rel="noreferrer">
                                  <p style={{ color: "#4b4b4b", fontSize: "11px", fontWeight: "bold", flex: "1", marginRight: "8px" }} >{item.filePath}</p>
                                </a>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() => {
                                    deleteConsultationNotes(index, item._id);
                                  }}
                                  style={{ color: "red", fontSize: "12px" }}
                                />
                              </div>
                            ))
                          }
                        </Grid> */}

                        {/* Consultation files */}
                        {/* <Grid item xs={12} sm={4}>
                          <ArgonBox mb={2}>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="bold"
                              fontSize="12px"
                            >
                              Consultation Files
                            </ArgonTypography>
                            <div style={{ border: '0.5px solid #bdbfc2', borderRadius: 7, padding: 13, paddingTop: 3 }}>
                              <input type="file" multiple onChange={(event) => setConsultationFiles(event.target.files)} />
                            </div>
              
                          </ArgonBox>
                          {
                            consultationFilesResponse.map((item, index) => (
                              <div key={item} style={{ display: "flex", alignItems: "center" }}>
                                <a href={`${global.config.DATA.filePath}/../uploads/${item.filePath}`} target="_blank" rel="noreferrer">
                                  <p style={{ color: "#4b4b4b", fontSize: "11px", fontWeight: "bold", flex: "1", marginRight: "8px" }}>{item.filePath}</p>
                                </a>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() => {
                                    deleteConsultationFiles(index, item._id);
                                  }}
                                  style={{ color: "red", fontSize: "12px" }}
                                />
                              </div>
                            ))
                          }
                        </Grid> */}
                      </Grid>
                    </Card>
                  </Card>
                  <ArgonBox mt={4} mb={3} justifyContent="center">
                    <ArgonButton
                      color="info"
                      size="large"
                      onClick={formOperation == "Add Customer" ? createCustomer : updateCustomer}
                    >
                      {formOperation}
                    </ArgonButton>
                    <p style={{ color: "red", fontSize: "12px" }}>{addCustomerError}</p>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </Card>
          {/* </Grid> */}
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default AddCustomer;
