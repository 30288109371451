// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

function DetailedStaticsCard({ bgColor, title, count, percentage, icon, direction,countColor }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <Link to={percentage.linkk}>
      <ArgonTypography
        component="a"
        variant="caption"
      >
        <Card >
          <ArgonBox style={{height: "120px !important"}}
            bgColor={bgColor === "white" && darkMode ? "transparent" : bgColor}
            variant={bgColor === "white" && darkMode ? "contained" : "gradient"}
          >
            <ArgonBox  p={2}>
              <Grid container>
                {direction === "left" ? (
                  <Grid item>
                    <ArgonBox
                      variant="gradient"
                      bgColor={bgColor === "white" ? icon.color : "white"}
                      color={bgColor === "white" ? "white" : "dark"}
                      width="3rem"
                      height="3rem"
                      borderRadius="section"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {typeof icon.component === "string" ? (
                        <Icon fontSize="small" color="inherit">
                          {icon.component}
                        </Icon>
                      ) : (
                        <ArgonBox
                          fontSize="1.125rem"
                          display="grid"
                          placeItems="center"
                          color="inherit"
                        >
                          {icon.component}
                        </ArgonBox>
                      )}
                    </ArgonBox>
                  </Grid>
                ) : null}
                <Grid item xs={8} style={{height: "80px"}}>
                  <ArgonBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                    <ArgonTypography
                      variant="button"
                      color={bgColor === "white" ? "text" : "white"}
                      textTransform="uppercase"
                      fontWeight="medium"
                    >
                      {title}
                    </ArgonTypography>
                    {countColor==1?(
                      <ArgonTypography
                      variant="h5"
                      fontWeight="bold"
                      color={bgColor === "white" ? "success" : "white"}
                      mb={1}
                    >
                      {count}
                    </ArgonTypography>
                    ): countColor==2?(
                      <ArgonTypography
                      variant="h5"
                      fontWeight="bold"
                      color={bgColor === "white" ? "error" : "white"}
                      mb={1}
                    >
                      {count}
                    </ArgonTypography>
                    ):(
                      <ArgonTypography
                      variant="h5"
                      fontWeight="bold"
                      color={bgColor === "white" ? "dark" : "white"}
                      mb={1}
                    >
                      {count}
                    </ArgonTypography>
                    )}
                    
                  </ArgonBox>
                </Grid>
                {direction === "right" ? (
                  <Grid item xs={4}>
                    <ArgonBox
                      variant="gradient"
                      bgColor={bgColor === "white" ? icon.color : "white"}
                      color={bgColor === "white" ? "white" : "dark"}
                      width="3rem"
                      height="3rem"
                      borderRadius="section"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      ml="auto"
                    >
                      {typeof icon.component === "string" ? (
                        <Icon fontSize="small" color="inherit">
                          {icon.component}
                        </Icon>
                      ) : (
                        <ArgonBox
                          fontSize="1.125rem"
                          display="grid"
                          placeItems="center"
                          color="inherit"
                        >
                          {icon.component}
                        </ArgonBox>
                      )}
                    </ArgonBox>
                  </Grid>
                ) : null}
              </Grid>
              <ArgonTypography
                // component="a"
                variant="caption"
                fontWeight="small"
                fontSize="small"
                color="#63ccee"
                // color={bgColor === "white" ? "text" : "white"}
                ml={0.5}
                mt={-0.125}
              >
                {percentage.text}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        </Card>
      </ArgonTypography>
    </Link>
  );
}

// Setting default values for the props of DetailedStaticsCard
DetailedStaticsCard.defaultProps = {
  bgColor: "white",
  percentage: {
    linkk: "",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the DetailedStaticsCard
DetailedStaticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    linkk: PropTypes.string,
    text: PropTypes.string,
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "red"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
  countColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DetailedStaticsCard;
