// MUI layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Admins from "layouts/admins";

import Customers from "layouts/customers";
import AddCustomer from "layouts/addCustomer";

import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

import gemImage from "assets/images/gem.png";
import crystalImage from "assets/images/crystal.png";
import yantraImage from "assets/images/yantra.png";

// MUI components
import ArgonBox from "components/ArgonBox";
import AddAdmin from "layouts/add-admin";
import NumerologyTable from "layouts/numerology-table";
import AstrologyTable from "layouts/astrology_table";
import TarotCardTable from "layouts/tarotCard-table";
import VastuTable from "layouts/vastu_table";
import ContactTable from "layouts/contact-table";
import CustomerProfitTable from "layouts/customer-profit-table";
import TrainingTable from "layouts/training-table";
import Training from "layouts/addTraining";
import ReportGenerator from "layouts/practice";
import Consultation from "layouts/consultation";
import GemstoneTable from "layouts/gemstone-table";
import AddGemstone from "layouts/addGemstones";
import YantraTable from "layouts/yantra-table";
import AddYantra from "layouts/addYantra";
import CrystalTable from "layouts/crystal-table";
import AddCrystal from "layouts/addCrystal";
import AddBlog from "layouts/addBlog";
import Blogs from "layouts/blogs";
import AddAward from "layouts/addAward";
import Awards from "layouts/awards";

// Images and Icons
import numerologyImage from "assets/images/numerology.png";
import astrologyImage from "assets/images/astrology.png";
import tarotCardImage from "assets/images/tarot-card.png";
import vastuImage from "assets/images/vastu.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PeopleIcon from "@mui/icons-material/People";
import BookIcon from "@mui/icons-material/Book";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import BusinessReport from "layouts/business-report";
import ProfitReport from "layouts/profilt-report";
import AssessmentIcon from '@mui/icons-material/Assessment';
import ClassBannerTable from "layouts/class-banner-table";
import AddClassBanner from "layouts/add-class-banner";
import SchoolIcon from '@mui/icons-material/School';

const colors = global.config.COLORS;
const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <PersonalVideoIcon style={{ fontSize: "14px" }}></PersonalVideoIcon>,
    component: <Dashboard />,
  },
  {
    name: "Consultation",
    key: "Consultation",
    route: "/consultation",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <Consultation />,
  },
  {
    name: "Training",
    key: "training",
    route: "/training",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <TrainingTable />,
  },
  {
    name: "Add Training",
    key: "addTraining",
    route: "/addTraining",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <Training />,
  },
  {
    name: "report",
    key: "report",
    route: "/report",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <ReportGenerator />,
  },
  {
    type: "route",
    name: "Admins",
    key: "admins",
    route: "/admins",
    icon: <AdminPanelSettingsIcon style={{ fontSize: "14px" }}></AdminPanelSettingsIcon>,
    component: <Admins />,
  },
  {
    name: "Add Admin",
    key: "add-admin",
    route: "/add-admin",
    icon: <PersonAddIcon style={{ fontSize: "14px" }}></PersonAddIcon>,
    component: <AddAdmin />,
  },
  {
    type: "route",
    name: "Add Customer",
    key: "addCustomer",
    route: "/addCustomer",
    icon: <PersonAddIcon style={{ fontSize: "14px" }}></PersonAddIcon>,
    component: <AddCustomer />,
  },
  {
    type: "route",
    name: "Customers",
    key: "customers",
    route: "/customers",
    icon: <PeopleIcon style={{ fontSize: "14px" }}></PeopleIcon>,
    component: <Customers />,
  },
  {
    type: "route",
    name: "Blogs",
    key: "blogs",
    route: "/blogs",
    icon: <BookIcon style={{ fontSize: "14px" }}></BookIcon>,
    component: <Blogs />,
  },
  {
    name: "Add Blog",
    key: "addBlog",
    route: "/addBlog",
    icon: <BookIcon style={{ fontSize: "14px" }}></BookIcon>,
    component: <AddBlog />,
  },
  {
    // type: "route",
    name: "Awards",
    key: "awards",
    route: "/awards",
    icon: <MilitaryTechIcon style={{ fontSize: "14px" }}></MilitaryTechIcon>,
    component: <Awards />,
  },
  {
    name: "Add Award",
    key: "addAward",
    route: "/addAward",
    icon: <MilitaryTechIcon style={{ fontSize: "14px" }}></MilitaryTechIcon>,
    component: <AddAward />,
  },
  { type: "title1", title: "Total Profit", key: "totalProfit" },
  {
    type: "route",
    name: "Consultation Report",
    key: "consultationReport",
    route: "/consultationReport",
    icon: <AssessmentIcon style={{color:"#ff813b"}}></AssessmentIcon>,
    component: <BusinessReport />,
  },
  {
    type: "route",
    name: "Profit Report",
    key: "profitReport",
    route: "/profitReport",
    icon:<AssessmentIcon style={{color:"#ff813b"}}></AssessmentIcon>,
    component: <ProfitReport />,
  },
  {
    type: "route",
    name: "Individual Profit",
    key: "profit",
    route: "/profit",
    icon:<AssessmentIcon style={{color:"#ff813b"}}></AssessmentIcon>,
    component: <CustomerProfitTable />,
  },
  { type: "title", title: "Reports", key: "account-pages" },

  {
    name: "View Customer",
    key: "viewCustomer",
    route: "/viewCustomer",
    icon: <ArgonBox component="i" color="dark" fontSize="14px" className="ni ni-single-02" />,
    component: <Profile />,
  },
  {
    type: "route",
    name: "Numerology",
    key: "ViewNumerology",
    route: "/ViewNumerology",
    icon: (
      <img
        src={numerologyImage}
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: colors.numerologyColor,
          borderRadius: "20px",
        }}
      />
    ),
    component: <NumerologyTable />,
  },
  {
    type: "route",
    name: "Astrology",
    key: "ViewAstrology",
    route: "/ViewAstrology",
    icon: (
      <img
        src={astrologyImage}
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: colors.astrologyColor,
          borderRadius: "20px",
        }}
      />
    ),
    component: <AstrologyTable />,
  },
  {
    type: "route",
    name: "Tarot Card",
    key: "ViewTarotCard",
    route: "/ViewTarotCard",
    icon: (
      <img
        src={tarotCardImage}
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: colors.tarotCardColor,
          borderRadius: "20px",
        }}
      />
    ),
    component: <TarotCardTable />,
  },
  {
    name: "Contacts",
    key: "contacts",
    route: "/contacts",
    icon: <MilitaryTechIcon style={{ fontSize: "14px" }}></MilitaryTechIcon>,
    component: <ContactTable />,
  },
  {
    type: "route",
    name: "Vastu",
    key: "ViewVastu",
    route: "/ViewVastu",
    icon: (
      <img
        src={vastuImage}
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: colors.vastuColor,
          borderRadius: "20px",
        }}
      />
    ),
    component: <VastuTable />,
  },
  { type: "title", title: "CMS", key: "cms" },
  {
    type: "route",
    name: "Gemstone",
    key: "gemstone",
    route: "/gemstone",
    icon: (
      <img
        src={gemImage}
        style={{
          padding: "2px",
          width: "20px",
          height: "20px",
          backgroundColor: colors.gemstoneColor,
          borderRadius: "25px",
        }}
      />
    ),
    component: <GemstoneTable />,
  },
  {
    name: "Add Gemstone",
    key: "addGemstone",
    route: "/addGemstone",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <AddGemstone />,
  },
  {
    type: "route",
    name: "Yantra",
    key: "yantra",
    route: "/yantra",
    icon: (
      <img
        src={yantraImage}
        style={{
          padding: "2px",
          width: "20px",
          height: "20px",
          backgroundColor: colors.yantraColor,
          borderRadius: "20px",
        }}
      />
    ),
    component: <YantraTable />,
  },
  {
    name: "Add Yantra",
    key: "addYantra",
    route: "/addYantra",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <AddYantra />,
  },
  {
    type: "route",
    name: "Crystal",
    key: "crystal",
    route: "/crystal",
    icon: (
      <img
        src={crystalImage}
        style={{
          padding: "2px",
          width: "20px",
          height: "20px",
          backgroundColor: colors.crystalColor,
          borderRadius: "20px",
        }}
      />
    ),
    component: <CrystalTable />,
  },
  {
    name: "Add Crystal",
    key: "addCrystal",
    route: "/addCrystal",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <AddCrystal />,
  },
  {
    type: "route",
    name: "Class Banner",
    key: "classbanner",
    route: "/classbanner",
    icon: <SchoolIcon style={{ fontSize: "14px" }}></SchoolIcon>,
    component: <ClassBannerTable />,
  },
  {
    name: "Add ClassBanner",
    key: "addClassBanner",
    route: "/addClassBanner",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
    component: <AddClassBanner />,
  },
  {
    name: "",
    key: "sign-in",
    route: "/",
    component: <SignIn />,
  },
];

export default routes;
