// @mui material components


// Argon Dashboard 2 MUI components
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// @mui material components
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import "config";

function AddBlog() {
  const [inputTitle, setTitle] = useState("");
  const [inputCoverImage, setCoverImage] = useState("");
  const [inputAuthorName, setAuthorName] = useState("");
  const [inputDiscription, setDiscription] = useState("");
  const [inputShortDiscription, setShortDiscription] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [formOperation, setFormOperation] = useState("Create Blog");
  const [editImage, setEditImage] = useState("");
  const [titleError, setTitleError] = useState("");



  const { id } = queryString.parse(location.search);
  const navigate = useNavigate();
  var md5 = require("md5");

  if (id) {
    useEffect(() => {
      axios
        .get(global.config.DATA.domain + "/blog/edit/" + id)
        .then((response) => {
          if (response.data["status"] == true) {
            setFormOperation("Update Blog");
            setTitle(response.data.data["title"]);
            setEditImage(response.data.data["image"]);
            setDiscription(response.data.data["discription"]);
            setAuthorName(response.data.data["auther"]);
            setShortDiscription(response.data.data["shortdiscription"])
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
  }

  function updateBlog() {
    setTitleError("");
    if (inputTitle) {
      const formData = new FormData();
      for (let i = 0; i < inputCoverImage.length; i++) {
        formData.append("consultationFiles", inputCoverImage[i]);
      }
      axios
        .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response1) => {
          var imageName = "";
          if (response1.data.data["consultationFiles"]) {
            imageName = response1.data.data["consultationFiles"][0]["filename"];
          }
          if (response1.data["status"] == true) {
            axios
              .put(global.config.DATA.domain + "/blog/update/" + id, {
                auther: inputAuthorName,
                title: inputTitle,
                image: (imageName) ? imageName : editImage,
                discription: inputDiscription,
                shortdiscription: inputShortDiscription,
              })
              .then((response) => {
                if (response.data["status"] == true) {
                  navigate("/blogs");
                } else {
                  setSubmitError("Invalid Email or Password else ");
                }
              })
              .catch((error) => {
                console.error(error);
                setSubmitError("Invalid Email or Password in catch ");
              });
          } else {
            setSubmitError("file not added");
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitError("Unable to add file");
        });
    } else {
      setTitleError("Title Required *")
    }
  }


  function createBlog() {
    setTitleError("");
    if (inputTitle) {
      const formData = new FormData();
      for (let i = 0; i < inputCoverImage.length; i++) {
        formData.append("consultationFiles", inputCoverImage[i]);
      }
      axios
        .post(global.config.DATA.domain + "/uploadAllFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response1) => {
          var imageName = "";
          if (response1.data.data["consultationFiles"]) {
            imageName = response1.data.data["consultationFiles"][0]["filename"];
          }
          if (response1.data["status"] == true) {
            axios
              .post(global.config.DATA.domain + "/blog/create", {
                auther: inputAuthorName,
                title: inputTitle,
                image: imageName,
                discription: inputDiscription,
                shortdiscription: inputShortDiscription,
              })
              .then((response) => {
                if (response.data["status"] == true) {
                  navigate("/blogs");
                } else {
                  setSubmitError("Invalid Email or Password else ");
                }
              })
              .catch((error) => {
                console.error(error);
                setSubmitError("Invalid Email or Password in catch ");
              });
          } else {
            setSubmitError("file not added");
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitError("Unable to add file");
        });
    } else {
      setTitleError("Title Required *")
    }
  }


  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Card sx={{ height: "100%" }}>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
              px={2}
            >
              <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Add Blog&apos;s
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox pt={3} pb={2} px={2}>
              <Card style={{ marginBottom: "15px", boxShadow: "rgb(151, 149, 150) 1px 1px 3px" }}>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                  paddingTop="10px"
                  style={{
                    backgroundColor: "#f2f2f2",
                    padding: "15px",
                    fontSize: "14px",
                    color: "#000",
                  }}
                >
                  Blog Details
                </ArgonTypography>
                <Card style={{ padding: "30px", marginBottom: "15px" }}>
                  <Grid container columnSpacing={3} rowSpacing={0}>
                    <Grid item xs={12} sm={12}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Author Name
                        </ArgonTypography>
                        <ArgonInput
                          type="text"
                          placeholder="Enter Author Name"
                          size="large"
                          value={inputAuthorName}
                          onChange={(event) => {
                            setAuthorName(event.target.value);
                          }}
                        />
                        {/* <p style={{ color: "red", fontSize: "12px" }}>{nameError}</p> */}
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Blog Title
                        </ArgonTypography>
                        <ArgonInput
                          type="text"
                          placeholder="Enter Blog Title"
                          size="large"
                          value={inputTitle}
                          onChange={(event) => {
                            setTitle(event.target.value), setTitleError("")
                          }}
                        />
                        <p style={{ color: "red", fontSize: "12px" }}>{titleError}</p>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ArgonBox mb={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Upload Cover Image
                        </ArgonTypography>
                        <ArgonInput
                          type="file"
                          placeholder="Company Name"
                          size="large"
                          //   value={inputCoverImage}
                          onChange={(event) => setCoverImage(event.target.files)}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Short Discription
                        </ArgonTypography>
                        <textarea
                          id="outlined-multiline-static"
                          label=""
                          multiline
                          rows={2}
                          placeholder="Short Discription"
                          size="large"
                          value={inputShortDiscription}
                          fullWidth={true}
                          onChange={(event) => {
                            setShortDiscription(event.target.value);
                          }}
                          inputProps={{ style: { width: "100% !important", padding: 8 } }}
                          style={{ width: "100% !important", padding: "8px", borderColor: "#E9EBED", marginTop: 8, borderRadius: 7, borderWidth: "2px" }}
                        />
                      </ArgonBox>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <ArgonBox mb={2} style={{ display: "flex", flexDirection: "column" }}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Discription
                        </ArgonTypography>
                        <textarea
                          id="outlined-multiline-static"
                          label=""
                          multiline
                          rows={12}
                          placeholder="Discription"
                          size="large"
                          value={inputDiscription}
                          fullWidth={true}
                          onChange={(event) => {
                            setDiscription(event.target.value);
                          }}
                          inputProps={{ style: { width: "100% !important", padding: 8 } }}
                          style={{ width: "100% !important", padding: "8px", borderColor: "#E9EBED", marginTop: 8, borderRadius: 7, borderWidth: "2px" }}
                        />
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </Card>
              </Card>
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={1}
                m={0}
                sx={{ listStyle: "none" }}
              >
                <ArgonBox component="form" role="form">
                  <ArgonBox mt={4} mb={3} justifyContent="center">
                    <ArgonButton
                      color="info"
                      size="large"
                      onClick={formOperation != "Update Blog" ? createBlog : updateBlog}
                    >
                      Submit
                    </ArgonButton>
                    {/* <p style={{ color: "red", fontSize: "12px" }}>{addBlogError}</p> */}
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </Card>
          {/* </Grid> */}
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default AddBlog;