// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "config";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Icon } from "@mui/material";
import { Tooltip } from "@material-ui/core";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import SummarizeIcon from "@mui/icons-material/Summarize";

function NumerologyTable() {
  const [responseData, setResponseData] = useState([]);
  const [filterRowData, setfilterRowData] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [doSearch, setDoSearch] = useState(false);
  const [searchError, setSearchError] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);

  const tableRef = useRef(null);

  // useEffect hook to make the API call when the component mounts
  useEffect(() => {
    document.title = "Numerology";
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(global.config.DATA.domain + "/consultation/1")
      .then((response) => {
        if (response.data.status) {
          const sortedData = response.data.data.sort((a, b) => {
            return (
              new Date(a.consultation_data.updatedAt) - new Date(b.consultation_data.updatedAt)
            );
          });
          setResponseData(sortedData);
          setfilterRowData(sortedData);
        } else if (response.data.message == "No Data Found") {
          setResponseData([]);
          setfilterRowData([]);
        }
      })
      .catch((error) => {});
  }

  function confirmDelete(value) {
    if (confirm("Are you sure you want to detele this numerology?")) {
      axios
        .get(global.config.DATA.domain + `/consultation/delete/${value}`)
        .then((response) => {
          if (response.data.status) {
            fetchData();
          }
        })
        .catch((error) => {});
    } else {
    }
  }

  const { columns } = {
    columns: [
      { name: "Sr No", align: "center" },
      { name: "Customer Name", align: "center" },
      { name: "Topic of Visit", align: "center" },
      {
        name: "Date",
        align: "center",
        options: {
          filter: false,
        },
      },
      { name: "Total Bill", align: "center" },
      { name: "Pending Bill", align: "center" },
      {
        name: "ACTION",
        align: "center",
        options: {
          download: false,
          print:false,
          filter: false,
          sort: false,
        },
      },
    ],
  };

  const { rows } = {
    rows: filterRowData.map((subTask, index) => {
      let pendingBill;
      if (Array.isArray(subTask.consultation_data.amountReceived)) {
        pendingBill = subTask.consultation_data.amountReceived.reduce(
          (sum, cur) => sum + parseInt(cur.aReceived),
          0
        );
      } else {
        pendingBill = subTask.consultation_data.totalBill;
      }
      return {
        "Sr No": index + 1,
        "Customer Name": subTask["customers"][0]["name"],
        "Topic of Visit": subTask.consultation_data.topicOfVisit
          ? subTask.consultation_data.topicOfVisit.replace("Other///", " ").replace("///", " ")
          : "",
        Date: subTask.consultation_data.dateOfConsultation
          ? subTask.consultation_data.dateOfConsultation.split("T")[0]
          : "",
        "Total Bill": subTask.consultation_data.totalBill,
        "Pending Bill": pendingBill,
        ACTION: (
          <ArgonBox display="flex" justifyContent="start" alignItems="center">
            <Tooltip title="Edit" placement="top" arrow>
              <Link
                to={`/consultation?cid=${subTask["customers"][0]["id"]}&id=${subTask.consultation_data._id}&type=${subTask.consultation_data.consultationType}`}
              >
                <ArgonTypography
                  component="a"
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                >
                  <Icon style={{ color: "black", fontSize: "20px" }}>edit</Icon>
                </ArgonTypography>
              </Link>
            </Tooltip>
            <Tooltip title="Report" placement="top" arrow>
              
                <ArgonTypography
                  component="a"
                  href={`/report?cid=${subTask["customers"][0]["id"]}&id=${subTask.consultation_data._id}&type=${subTask.consultation_data.consultationType}`} 
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                >
                  <SummarizeIcon
                    style={{ color: "black", fontSize: "20px", marginLeft: "10px" }}
                  ></SummarizeIcon>
                </ArgonTypography>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <ArgonTypography
                component="a"
                variant="caption"
                color="secondary"
                fontWeight="small"
                pl={1}
                onClick={() => confirmDelete(subTask.consultation_data._id)}
              >
                <DeleteIcon style={{ color: "black", fontSize: "20px" }}></DeleteIcon>
              </ArgonTypography>
            </Tooltip>
          </ArgonBox>
        ),
      };
    }),
  };

  const isDateValid = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;
    const [year, month, day] = dateString.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getFullYear() === year &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getDate() === day
    );
  };

  const isStartDateBeforeEndDate = (startDate, endDate) => {
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    return startDateObject <= endDateObject;
  };

  const handleDateRangeChange = () => {
    if (isDateValid(startDate) && isDateValid(endDate)) {
      if (isStartDateBeforeEndDate(startDate, endDate)) {
        setDoSearch(!doSearch);
        if (!doSearch) {
          setfilterRowData([]);
          // Filter the table data based on the selected date range
          const updatedData = [];
          responseData.map((data) => {
            const rowDate = new Date(data.consultation_data.dateOfConsultation.split("T")[0]);
            const sDate = new Date(startDate);
            const eDate = new Date(endDate);
            if (rowDate >= sDate && rowDate <= eDate) {
              updatedData.push(data);
            }
          });
          setfilterRowData(updatedData);
        } else {
          setfilterRowData(responseData);
          setStartDate("");
          setEndDate("");
        }
      } else {
        setSearchError("Date From should be less than Date To");
      }
    } else {
      setSearchError("Please enter both dates");
    }
  };

  const handleTableChange = (action, tableState) => {
    let totalAmount = 0;
    let pendingAmount = 0;
    tableState.displayData.map((data) => {
      totalAmount = totalAmount + data.data[4];
      pendingAmount = pendingAmount + data.data[5];
    });
    setTotalAmount(totalAmount);
    setPendingAmount(pendingAmount);
  };

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    // fixedHeader: true,
    // fixedSelectColumn: false,
    // tableBodyHeight: "400px"
    selectableRows: false,
    search: true,
    center: true,
    downloadOptions: {
      filename: "Numerology.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onTableChange: handleTableChange,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              paddingLeft: "20px",
              backgroundColor: "#fff",
              color: "#000",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "13px",
              fontSize: "15px",
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h6">Numerology List</ArgonTypography>
            </ArgonBox>
            <Grid
              container
              columnSpacing={3}
              rowSpacing={0}
              style={{ paddingLeft: "20px", paddingBottom: "5px" }}
            >
              <Grid item xs={12} sm={2}>
                <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                  Date From&nbsp;
                </ArgonTypography>
                <ArgonInput
                  type="date"
                  placeholder="Given Name"
                  size="small"
                  value={startDate}
                  onChange={(event) => {
                    setStartDate(event.target.value);
                    setSearchError("");
                    setDoSearch(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                  Date To&nbsp;
                </ArgonTypography>
                <ArgonInput
                  type="date"
                  placeholder="Given Name"
                  size="small"
                  value={endDate}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                    setSearchError("");
                    setDoSearch(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <ArgonButton
                  color="info"
                  size="small"
                  onClick={handleDateRangeChange}
                  style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}
                >
                  {doSearch ? "clear" : "Search"}
                </ArgonButton>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={1}>
                <ArgonTypography fontWeight="large" style={{ fontSize: "20px" }}>
                  <b>{totalAmount}</b>
                </ArgonTypography>
                <ArgonTypography fontWeight="small" fontSize="small">
                  Total(<CurrencyRupeeIcon></CurrencyRupeeIcon>)
                </ArgonTypography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <ArgonTypography fontWeight="large" style={{ fontSize: "20px", color: "red" }}>
                  <b>{pendingAmount}</b>
                </ArgonTypography>
                <ArgonTypography fontWeight="small" fontSize="small">
                  Pending(<CurrencyRupeeIcon></CurrencyRupeeIcon>)
                </ArgonTypography>
              </Grid>
            </Grid>
            <ArgonTypography
              variant="caption"
              style={{ color: "red", fontSize: "12px", paddingLeft: "20px", paddingBottom: "15px" }}
            >
              {searchError}
            </ArgonTypography>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                ref={tableRef}
                title={""}
                columns={columns}
                data={rows.reverse()}
                options={options}
              />
            </ThemeProvider>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NumerologyTable;
