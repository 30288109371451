import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// MUI example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link } from "react-router-dom";

// MUI base styles
import breakpoints from "assets/theme/base/breakpoints";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import moment from "moment/moment";
import { Icon } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function Header({
  custID,
  fullName,
  email,
  phone,
  gender,
  address,
  dateOfBirth,
  birthTime,
  kundali,
  birthPlace,
  birthPlaceManual,
  totalBill,
  amountPending,
}) {
  // const formattedTotalBill = parseInt(totalBill);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  var formattedDateOfBirth;
  if (dateOfBirth == null) {
    formattedDateOfBirth = " ";
  } else {
    formattedDateOfBirth = moment(dateOfBirth).format("MMM Do YYYY");
  }

  if (birthTime == null) {
    birthTime = " ";
  }
  return (
    <ArgonBox position="relative">
      <DashboardNavbar absolute light />
      <ArgonBox height="125px" />
      <Card
        sx={{
          py: 2,
          px: 2,
          boxShadow: ({ boxShadows: { md } }) => md,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} lg={6}>
            <ArgonBox height="100%" mt={0.5} lineHeight={1}>
              <ArgonTypography>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>{fullName}</span> |{" "}
                <span style={{ fontSize: "14px" }}>{gender}</span>
              </ArgonTypography>
              <ArgonTypography style={{ fontSize: "16px", fontWeight: "small" }}>
                {phone + " | " + email}
              </ArgonTypography>
              <ArgonTypography
                style={{
                  fontWeight: "small",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid container>
                  {address && (
                    <Grid item xs={12}>
                      <ArgonTypography style={{ fontSize: "14px", fontWeight: "small" }}>
                        {" "}
                        {address}{" "}
                      </ArgonTypography>
                    </Grid>
                  )}
                  <Grid item xs={12} style={{ display: "flex" }}>
                    {kundali && (
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={1}
                        style={{ marginRight: "6px", marginTop: "9px" }}
                      >
                        <Tooltip title="Open Kundali" arrow>
                          <a
                            href={`${global.config.DATA.filePath}/../uploads/${kundali}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                color: "black",
                              }}
                            >
                              kundali
                            </p>
                          </a>
                        </Tooltip>
                      </Grid>
                    )}

                    <Grid item xs={2} sm={2} md={2}>
                      <Tooltip title="Edit Customer" arrow>
                        <Link to={`/addCustomer?id=${custID}`}>
                          <ArgonTypography component="a" style={{ fontSize: "12px" }}>
                            Edit <Icon>edit</Icon>
                          </ArgonTypography>
                        </Link>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </ArgonTypography>
            </ArgonBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={1}
            sx={{ ml: "auto", justifyContent: "center", textAlign: "center" }}
          >
            <ArgonTypography fontWeight="large" style={{ fontSize: "20px" }}>
              <b>{totalBill}</b>
            </ArgonTypography>
            <ArgonTypography fontWeight="small" fontSize="small">
              Total (<CurrencyRupeeIcon></CurrencyRupeeIcon>)
            </ArgonTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={1}
            sx={{ ml: "auto", justifyContent: "center", textAlign: "center" }}
          >
            <ArgonTypography fontWeight="large" style={{ fontSize: "20px", color: "red" }}>
              <b>{amountPending}</b>
            </ArgonTypography>
            <ArgonTypography fontWeight="small" fontSize="small">
              Pending(<CurrencyRupeeIcon></CurrencyRupeeIcon>)
            </ArgonTypography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ ml: "auto", justifyContent: "center", textAlign: "center" }}
          >
            <Card style={{ backgroundColor: "#f2f2f2", padding: "10px"}}>
              <ArgonTypography style={{ fontSize: "12px", fontWeight: "small" }}>
                Birth Details
              </ArgonTypography>
              {birthPlace == "," && birthTime == " " && formattedDateOfBirth == " " ? (
                <ArgonTypography fontWeight="small" fontSize="medium">
                  No Given Details
                </ArgonTypography>
              ) : (
                <Grid container justify="center" style={{ display: "flex", justifyContent: "center" }}>
                  {formattedDateOfBirth!=" "?
                  <Grid item xs={12} sm={4}>
                    <ArgonTypography variant="h5" fontWeight="medium" fontSize="medium">
                      {(formattedDateOfBirth? `${formattedDateOfBirth} `:"")}
                    </ArgonTypography>
                    <div style={{ display: "flex", justifyContent: "center",bottom: "0" }}>
                      <ArgonTypography style={{ fontSize: "12px" }}>Date</ArgonTypography>
                    </div>
                  </Grid>
                  :null}
                  {birthTime?
                  <Grid item xs={12} sm={2} >
                    <ArgonTypography variant="h5" fontWeight="medium" fontSize="medium">
                      {(birthTime? `${birthTime} `:"")}
                    </ArgonTypography>
                    <div style={{ display: "flex", justifyContent: "center",bottom: "0" }}>
                      <ArgonTypography style={{ fontSize: "12px" }}>Time</ArgonTypography>
                    </div>
                  </Grid>
                  :null}
                  {birthPlace!=" " || birthPlaceManual!=" "?
                  <Grid item xs={12} sm={6}>
                    <ArgonTypography variant="h5" fontWeight="medium" fontSize="medium">
                      {(birthPlace? `${birthPlace}`:"")}{(birthPlaceManual? `${birthPlaceManual}`:"")}
                    </ArgonTypography>
                    <div style={{ display: "flex", justifyContent: "center",bottom: "0" }}>
                      <ArgonTypography style={{ fontSize: "12px" }}>Place</ArgonTypography>
                    </div>
                  </Grid>
                  :null}
                </Grid>
                // <ArgonTypography variant="h5" fontWeight="medium" fontSize="medium">
                //   {(formattedDateOfBirth?formattedDateOfBirth + " | ":"") + (birthTime? birthTime + " | ":"") + birthPlace}
                // </ArgonTypography>
              )}
              {/* <ArgonTypography
                style={{
                  fontWeight: "small",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                
              </ArgonTypography> */}
            </Card>
          </Grid>
        </Grid>
      </Card>
    </ArgonBox>
  );
}
Header.propTypes = {
  custID: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  birthPlace: PropTypes.string,
  birthPlaceManual: PropTypes.string,
  birthTime: PropTypes.string,
  kundali: PropTypes.string,
  dateOfBirth: PropTypes.string,
  address: PropTypes.string,
  gender: PropTypes.string,
  totalBill: PropTypes.number,
  amountPending: PropTypes.number,
};

export default Header;
