module.exports = global.config = {
    DATA: {
        welcome: {
            en: "Welcome",
        },
        // domain: "http://localhost:7789/api",
        domain: "https://apis.kulkarnni.com/api",
        // filePath: "http://localhost:7789/uploads",
        filePath: "https://apis.kulkarnni.com/uploads",
        userName: "",
    },
    COLORS: {
        websiteColor: "#149cf3",
        numerologyColor: "#63ccee",
        astrologyColor: "#e91e63",
        tarotCardColor: "#75e65b",
        vastuColor: "#e6e272",
        gemstoneColor: "#e6e272",
        yantraColor: "#212329",
        crystalColor: "#1768E4",
    },
};