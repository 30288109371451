import { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import { Grid } from "@mui/material";
import "../../styles/style.css";

// add-admin page components
import BaseLayout from "layouts/add-admin/components/BaseLayout";
import "config";

// Images and icons
import loader from "assets/images/pdf/loader-numo.gif";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function BusinessReport() {
  const reportTemplateRef = useRef(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [doSearch, setDoSearch] = useState(false);
  const [searchError, setSearchError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [numerologyBusinessDone, setNumerologyBusinessDone] = useState(0);
  const [astrologyBusinessDone, setAstrologyBusinessDone] = useState(0);
  const [tarotCardBusinessDone, setTarotCardBusinessDone] = useState(0);
  const [vastuBusinessDone, setVastuBusinessDone] = useState(0);

  const [numerologyPendingAmount, setNumerologyPendingAmount] = useState(0);
  const [astrologyPendingAmount, setAstrologyPendingAmount] = useState(0);
  const [tarotCardPendingAmount, setTarotCardPendingAmount] = useState(0);
  const [vastuPendingAmount, setVastuPendingAmount] = useState(0);

  const [numerologyTrainingBusinessDone, setNumerologyTrainingBusinessDone] = useState(0);
  const [astrologyTrainingBusinessDone, setAstrologyTrainingBusinessDone] = useState(0);
  const [tarotCardTrainingBusinessDone, setTarotCardTrainingBusinessDone] = useState(0);

  const [numerologyTrainingPendingAmount, setNumerologyTrainingPendingAmount] = useState(0);
  const [astrologyTrainingPendingAmount, setAstrologyTrainingPendingAmount] = useState(0);
  const [tarotCardTrainingPendingAmount, setTarotCardTrainingPendingAmount] = useState(0);

  const [consultationBusinessDone, setconsultationBusinessDone] = useState(0);
  const [consultationPendingAmount, setconsultationPendingAmount] = useState(0);
  const [consultationTrainingBusinessDone, setconsultationTrainingBusinessDone] = useState(0);
  const [consultationTrainingPendingAmount, setconsultationTrainingPendingAmount] = useState(0);

  const fetchData = (requestData) => {
    setNumerologyBusinessDone("0");
    setNumerologyPendingAmount("0");
    setAstrologyBusinessDone("0");
    setAstrologyPendingAmount("0");
    setTarotCardBusinessDone("0");
    setTarotCardPendingAmount("0");
    setVastuBusinessDone("0");
    setVastuPendingAmount("0");

    setNumerologyTrainingBusinessDone("0");
    setNumerologyTrainingPendingAmount("0");
    setAstrologyTrainingBusinessDone("0");
    setAstrologyTrainingPendingAmount("0");
    setTarotCardTrainingBusinessDone("0");
    setTarotCardTrainingPendingAmount("0");
    axios
      .post(global.config.DATA.domain + "/profitReport", requestData)
      .then((response) => {
        if (response.data.status === true) {
          let totalBusinessConsultation = 0;
          let totalBusinessConsultationTraining = 0;
          let totalPendingConsultation = 0;
          let totalPendingConsultationTraining = 0;

          response.data.data.consultationData.map((item) => {
            totalBusinessConsultation = totalBusinessConsultation + item.totalAmount;
            totalPendingConsultation = totalPendingConsultation + item.pendingAmount;
            if (item.consultationType == 1) {
              setNumerologyBusinessDone(item.totalAmount);
              setNumerologyPendingAmount(item.pendingAmount);
            } else if (item.consultationType == 2) {
              setAstrologyBusinessDone(item.totalAmount);
              setAstrologyPendingAmount(item.pendingAmount);
            } else if (item.consultationType == 3) {
              setTarotCardBusinessDone(item.totalAmount);
              setTarotCardPendingAmount(item.pendingAmount);
            } else if (item.consultationType == 4) {
              setVastuBusinessDone(item.totalAmount);
              setVastuPendingAmount(item.pendingAmount);
            }
          });

          response.data.data.trainingData.map((item) => {
            totalBusinessConsultationTraining =
              totalBusinessConsultationTraining + item.totalAmount;
            totalPendingConsultationTraining =
              totalPendingConsultationTraining + item.pendingAmount;
            if (item.consultationType == 1) {
              setNumerologyTrainingBusinessDone(item.totalAmount);
              setNumerologyTrainingPendingAmount(item.pendingAmount);
            } else if (item.consultationType == 2) {
              setAstrologyTrainingBusinessDone(item.totalAmount);
              setAstrologyTrainingPendingAmount(item.pendingAmount);
            } else if (item.consultationType == 3) {
              setTarotCardTrainingBusinessDone(item.totalAmount);
              setTarotCardTrainingPendingAmount(item.pendingAmount);
            }
          });
          setconsultationBusinessDone(totalBusinessConsultation);
          setconsultationPendingAmount(totalPendingConsultation);
          setconsultationTrainingBusinessDone(totalBusinessConsultationTraining);
          setconsultationTrainingPendingAmount(totalPendingConsultationTraining);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let requestData = {
      startDate: null,
      endDate: null,
    };

    fetchData(requestData);
  }, []);
  const handleExportPDFNew = async () => {
    setIsLoading(true);
    const content = document.querySelector("#element-to-print");

    const pageWidth = 20.5; // in cm
    const pageHeight = 30.5; // in cm

    const doc = new jsPDF("portrait", "pt", "a4");

    // Get all the pages to export
    const pages = document.querySelectorAll(".page1");

    // Loop through each page and export it to PDF
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      // Calculate the scroll position of the page
      const scrollPos = i > 0 ? page.offsetTop - pages[0].offsetTop : 0;

      // Export the page to canvas
      const canvas = await html2canvas(page, {
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        scrollY: scrollPos,
        useCORS: true,
        allowTaint: true,
        scale: 3.6, // 0.8 for a4 , 1.15 for a3
        quality: 1,
        logging: true,
        dpi: 300,
        padding: [50, 50, 50, 50],
      });

      // Add the canvas to the PDF
      if (i > 0) {
        doc.addPage();
      }
      doc.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        20,
        20,
        canvas.width / 6,
        canvas.height / 6,
        "",
        "FAST"
      );
    }
    var pdfName;
    if (startDate == "" && endDate == "") {
      pdfName = "consultation_report_all";
    } else {
      pdfName = "consultation_report_" + startDate + "_to_" + endDate;
    }
    // Save the PDF
    doc.save(pdfName, { quality: 2 });

    setIsLoading(false);
  };
  const isDateValid = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;
    const [year, month, day] = dateString.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getFullYear() === year &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getDate() === day
    );
  };

  const isStartDateBeforeEndDate = (startDate, endDate) => {
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    return startDateObject <= endDateObject;
  };

  const handleDateRangeChange = () => {
    if (doSearch) {
      let requestData = {
        startDate: null,
        endDate: null,
      };
      fetchData(requestData);
      setDoSearch(!doSearch);
      setStartDate("");
      setEndDate("");
    } else {
      if (isDateValid(startDate) && isDateValid(endDate)) {
        if (isStartDateBeforeEndDate(startDate, endDate)) {
          setDoSearch(!doSearch);
          if (!doSearch) {
            let requestData = {
              startDate: startDate,
              endDate: endDate,
            };
            fetchData(requestData);
          }
        } else {
          setSearchError("Date From should be less than Date To");
        }
      } else {
        setSearchError("Please enter both dates");
      }
    }
  };

  return (
    <BaseLayout stickyNavbar>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Card sx={{ height: "100%" }}>
            {/* Loader */}
            <Grid lg={12} sm={12} style={{ textAlign: "center" }}>
              {isLoading && (
                <div>
                  <img
                    src={loader}
                    style={{ width: "150px", height: "150px", objectFit: "contain" }}
                  />
                  <ArgonTypography
                    variant="h6"
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{ marginBottom: 20 }}
                  >
                    We are preparing your report. Hold on tight!
                  </ArgonTypography>
                </div>
              )}
            </Grid>

            <div
              id="element-to-print"
              ref={reportTemplateRef}
              style={{
                padding: 10,
                boxShadow: "rgb(151, 149, 150) 1px 1px 3px",
              }}
            >
              <div id="page1" className="page1" style={{ fontFamily: "Poppins" }}>
                <ArgonBox>
                  <h3
                    style={{
                      // textAlign: "center",
                      marginTop: "40px",
                      marginBottom: "50px",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      letterSpacing: 2,
                    }}
                  >
                    Consultation Report
                  </h3>
                  <div>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={0}
                      style={{ paddingLeft: "20px", paddingBottom: "5px" }}
                    >
                      <Grid item xs={12} sm={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Date From&nbsp;
                        </ArgonTypography>
                        <ArgonInput
                          type="date"
                          size="small"
                          value={startDate}
                          onChange={(event) => {
                            setStartDate(event.target.value);
                            setSearchError("");
                            setDoSearch(false);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Date To&nbsp;
                        </ArgonTypography>
                        <ArgonInput
                          type="date"
                          size="small"
                          value={endDate}
                          onChange={(event) => {
                            setEndDate(event.target.value);
                            setSearchError("");
                            setDoSearch(false);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <ArgonButton
                          color="info"
                          size="small"
                          onClick={handleDateRangeChange}
                          style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}
                        >
                          {doSearch ? "clear" : "Search"}
                        </ArgonButton>
                      </Grid>
                    </Grid>
                    <ArgonTypography
                      variant="caption"
                      style={{
                        color: "red",
                        fontSize: "12px",
                        paddingLeft: "20px",
                        paddingBottom: "15px",
                      }}
                    >
                      {searchError}
                    </ArgonTypography>
                    <h4
                      style={{
                        marginBottom: "25px",
                        marginTop: 20,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        letterSpacing: 2,
                      }}
                    >
                      Consultation
                    </h4>
                    <div style={{ textAlign: "center" }}>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          justifyContent: "center",
                        }}
                        lg={10}
                      >
                        <Grid
                          container
                          style={{
                            fontSize: "17px",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#ffede3",
                          }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Consultation
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Business Done
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Pending Amount
                          </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Numerology
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {numerologyBusinessDone}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {numerologyPendingAmount}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{ fontSize: "17px", padding: "10px", backgroundColor: "#ffede3" }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Astrology
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {astrologyBusinessDone}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {astrologyPendingAmount}
                          </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Tarot Card
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {tarotCardBusinessDone}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {tarotCardPendingAmount}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{ fontSize: "17px", padding: "10px", backgroundColor: "#ffede3" }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Vastu
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {vastuBusinessDone}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {vastuPendingAmount}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{
                            fontSize: "17px",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#eac2ac",
                          }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                            <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                            {consultationBusinessDone}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            style={{ color: "#ec4400", fontSize: "23px" }}
                          >
                            <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                            {consultationPendingAmount}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <h4
                      style={{
                        marginBottom: "25px",
                        marginTop: 30,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        letterSpacing: 2,
                      }}
                    >
                      Consultation Training
                    </h4>
                    <div style={{ textAlign: "center" }}>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          justifyContent: "center",
                        }}
                        lg={10}
                      >
                        <Grid
                          container
                          style={{
                            fontSize: "17px",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#ffede3",
                          }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Training
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Business Done
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Pending Amount
                          </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Numerology Training
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {numerologyTrainingBusinessDone}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {numerologyTrainingPendingAmount}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{ fontSize: "17px", padding: "10px", backgroundColor: "#ffede3" }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Astrology Training
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {astrologyTrainingBusinessDone}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {astrologyTrainingPendingAmount}
                          </Grid>
                        </Grid>
                        <Grid container style={{ fontSize: "17px", padding: "10px" }}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Tarot Card Training
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {tarotCardTrainingBusinessDone}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            {tarotCardTrainingPendingAmount}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{
                            fontSize: "17px",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#eac2ac",
                          }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                            <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                            {consultationTrainingBusinessDone}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            style={{ color: "#ec4400", fontSize: "23px" }}
                          >
                            <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                            {consultationTrainingPendingAmount}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <h4
                      style={{
                        marginBottom: "25px",
                        marginTop: 30,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        letterSpacing: 2,
                      }}
                    >
                      Consultation Report:
                    </h4>
                    <div style={{ textAlign: "center" }}>
                      <Grid
                        container
                        style={{
                          fontSize: "17px",
                          justifyContent: "center",
                        }}
                        lg={10}
                      >
                        <Grid
                          container
                          style={{
                            fontSize: "17px",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#ffede3",
                          }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Total Business Done
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} style={{ fontSize: "23px" }}>
                            <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                            {consultationBusinessDone + consultationTrainingBusinessDone}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{
                            fontSize: "17px",
                            padding: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#ffede3",
                          }}
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            Total Pending Amount
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            style={{ color: "#ec4400", fontSize: "23px" }}
                          >
                            <CurrencyRupeeIcon style={{ paddingTop: 3 }}></CurrencyRupeeIcon>
                            {consultationPendingAmount + consultationTrainingPendingAmount}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </ArgonBox>
              </div>
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <ArgonButton
                  onClick={handleExportPDFNew}
                  variant="contained"
                  style={{
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  Export Report
                </ArgonButton>
              </div>
            </div>
          </Card>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default BusinessReport;
