// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import React, { useState, useEffect } from "react";
import axios from "axios";
import "config";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function CustomerProfitTable() {
  const [initialLoadedData, setinitialLoadedData] = useState([]);
  const [filtterData, setfiltterData] = useState([]);
  const [ReportStoneData, setReportStoneData] = useState([]);
  const [dropDownValue, setDropdownValue] = useState("");
  const [columnName, setColumnName] = useState("Stones");

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [doSearch, setDoSearch] = useState(false);
  const [searchError, setSearchError] = useState("");

  const [totalProfitOfStones, setTotalProfitOfStones] = useState(0);

  // useEffect hook to make the API call when the component mounts
  useEffect(() => {
    document.title = "Profit";
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(global.config.DATA.domain + "/consultation/0")
      .then((response) => {
        if (response.data.status) {
          const sortedData = response.data.data.sort((a, b) => {
            return (
              new Date(a.consultation_data.updatedAt) - new Date(b.consultation_data.updatedAt)
            );
          });
          let allRecords = [];
          sortedData.map((subTask, index) => {
            const gemstoneProfits = [];
            const crystalProfits = [];
            const yantraProfits = [];
            if (
              subTask.consultation_data.gemstones &&
              subTask.consultation_data.gemstones.length > 0
            ) {
              setDropdownValue("0");
              subTask.consultation_data.gemstones.forEach((gemstone, index1) => {
                if (gemstone.isSold) {
                  const salePrice = parseFloat(gemstone.salePrice);
                  const purchasePrice = parseFloat(gemstone.purchasePrice);
                  const makingCharges = parseFloat(gemstone.makingCharges);
                  const profit = salePrice + makingCharges - purchasePrice;
                  gemstoneProfits.push(profit);
                  allRecords.push({
                    consultation: subTask,
                    stone: gemstone,
                    profit: profit,
                  });
                }
              });
            }
            if (
              subTask.consultation_data.crystals &&
              subTask.consultation_data.crystals.length > 0
            ) {
              setDropdownValue("0");
              subTask.consultation_data.crystals.forEach((crystal, index1) => {
                if (crystal.isSold) {
                  const salePrice = parseFloat(crystal.salePrice);
                  const purchasePrice = parseFloat(crystal.purchasePrice);
                  const makingCharges = parseFloat(crystal.makingCharges);
                  const profit = salePrice + makingCharges - purchasePrice;
                  crystalProfits.push(profit);
                  allRecords.push({
                    consultation: subTask,
                    stone: crystal,
                    profit: profit,
                  });
                }
              });
            }
            if (subTask.consultation_data.yantras && subTask.consultation_data.yantras.length > 0) {
              setDropdownValue("0");
              subTask.consultation_data.yantras.forEach((yantra, index1) => {
                if (yantra.isSold) {
                  const salePrice = parseFloat(yantra.salePrice);
                  const purchasePrice = parseFloat(yantra.purchasePrice);
                  const makingCharges = parseFloat(yantra.makingCharges);
                  const profit = salePrice + makingCharges - purchasePrice;
                  yantraProfits.push(profit);
                  allRecords.push({
                    consultation: subTask,
                    stone: yantra,
                    profit: profit,
                  });
                }
              });
            }
          });
          setReportStoneData(allRecords);
          setfiltterData(allRecords);
          setinitialLoadedData(sortedData);
        }
      })
      .catch((error) => {});
  }
  const consultationTypeNames = {
    1: "Numerology",
    2: "Astrology",
    3: "Tarot Card",
    4: "Vastu",
  };
  const { columns } = {
    columns: [
      { name: "Sr No", align: "center" },
      { name: "Customer", align: "center" },
      { name: "Consultation", align: "center" },
      { name: "Items", align: "center" },
      { name: "Purchase Price", align: "center" },
      { name: "Selling Price", align: "center" },
      { name: "Making Charges", align: "center" },
      { name: "Profit", align: "center" },
      {
        name: "Date",
        align: "center",
        options: {
          filter: false,
        },
      },
    ],
  };

  const { rows } = {
    rows: ReportStoneData.map((subTask, index) => {
      let consultationType = "";
      switch (subTask.consultation.consultation_data.consultationType) {
        case 1:
          consultationType = "Numerology";
          break;
        case 2:
          consultationType = "Astrology";
          break;
        case 3:
          consultationType = "Tarot";
          break;
        case 4:
          consultationType = "Vastu";
          break;
      }
      return {
        "Sr No": index + 1,
        Customer: subTask.consultation["customers"][0]["name"],
        Consultation: consultationType,
        Items: subTask.stone.type.name,
        "Purchase Price": subTask.stone.purchasePrice,
        "Selling Price": subTask.stone.salePrice,
        "Making Charges": subTask.stone.makingCharges,
        Profit: subTask.profit,
        Date: subTask.consultation.consultation_data.dateOfConsultation
          ? subTask.consultation.consultation_data.dateOfConsultation.split("T")[0]
          : "",
      };
    }),
  };

  const isDateValid = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;
    const [year, month, day] = dateString.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getFullYear() === year &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getDate() === day
    );
  };

  const isStartDateBeforeEndDate = (startDate, endDate) => {
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    return startDateObject <= endDateObject;
  };

  const handleDateRangeChange = () => {
    if (isDateValid(startDate) && isDateValid(endDate)) {
      if (isStartDateBeforeEndDate(startDate, endDate)) {
        setDoSearch(!doSearch);
        if (!doSearch) {
          setReportStoneData([]);
          // Filter the table data based on the selected date range
          const updatedData = [];
          ReportStoneData.map((data) => {
            const rowDate = new Date(
              data.consultation.consultation_data.dateOfConsultation.split("T")[0]
            );
            const sDate = new Date(startDate);
            const eDate = new Date(endDate);
            if (rowDate >= sDate && rowDate <= eDate) {
              updatedData.push(data);
            }
          });
          setReportStoneData(updatedData);
        } else {
          setReportStoneData(filtterData);
          setStartDate("");
          setEndDate("");
        }
      } else {
        setSearchError("Date From should be less than Date To");
      }
    } else {
      setSearchError("Please enter both dates");
    }
  };

  const handleStoneChange = (event) => {
    if (event == 0) {
      let allRecords = [];
      initialLoadedData.map((subTask, index) => {
        const gemstoneProfits = [];
        const crystalProfits = [];
        const yantraProfits = [];
        if (subTask.consultation_data.gemstones && subTask.consultation_data.gemstones.length > 0) {
          subTask.consultation_data.gemstones.forEach((gemstone, index1) => {
            if (gemstone.isSold) {
              const salePrice = parseFloat(gemstone.salePrice);
              const purchasePrice = parseFloat(gemstone.purchasePrice);
              const makingCharges = parseFloat(gemstone.makingCharges);
              const profit = salePrice + makingCharges - purchasePrice;
              gemstoneProfits.push(profit);
              allRecords.push({
                consultation: subTask,
                stone: gemstone,
                profit: profit,
              });
            }
          });
        }
        if (subTask.consultation_data.crystals && subTask.consultation_data.crystals.length > 0) {
          setDropdownValue("0");
          subTask.consultation_data.crystals.forEach((crystal, index1) => {
            if (crystal.isSold) {
              const salePrice = parseFloat(crystal.salePrice);
              const purchasePrice = parseFloat(crystal.purchasePrice);
              const makingCharges = parseFloat(crystal.makingCharges);
              const profit = salePrice + makingCharges - purchasePrice;
              crystalProfits.push(profit);
              allRecords.push({
                consultation: subTask,
                stone: crystal,
                profit: profit,
              });
            }
          });
        }
        if (subTask.consultation_data.yantras && subTask.consultation_data.yantras.length > 0) {
          setDropdownValue("0");
          subTask.consultation_data.yantras.forEach((yantra, index1) => {
            if (yantra.isSold) {
              const salePrice = parseFloat(yantra.salePrice);
              const purchasePrice = parseFloat(yantra.purchasePrice);
              const makingCharges = parseFloat(yantra.makingCharges);
              const profit = salePrice + makingCharges - purchasePrice;
              yantraProfits.push(profit);
              allRecords.push({
                consultation: subTask,
                stone: yantra,
                profit: profit,
              });
            }
          });
        }
      });
      setReportStoneData(allRecords);
      setfiltterData(allRecords);
    } else if (event == 1) {
      let allRecords = [];
      initialLoadedData.map((subTask, index) => {
        const gemstoneProfits = [];
        if (subTask.consultation_data.gemstones && subTask.consultation_data.gemstones.length > 0) {
          setColumnName("Gemstones");
          subTask.consultation_data.gemstones.forEach((gemstone, index1) => {
            if (gemstone.isSold) {
              const salePrice = parseFloat(gemstone.salePrice);
              const purchasePrice = parseFloat(gemstone.purchasePrice);
              const makingCharges = parseFloat(gemstone.makingCharges);
              const profit = salePrice + makingCharges - purchasePrice;
              gemstoneProfits.push(profit);
              allRecords.push({
                consultation: subTask,
                stone: gemstone,
                profit: profit,
              });
            }
          });
        }
      });
      setReportStoneData(allRecords);
      setfiltterData(allRecords);
    } else if (event == 2) {
      let allRecords = [];
      initialLoadedData.map((subTask, index) => {
        const yantraProfits = [];
        if (subTask.consultation_data.yantras && subTask.consultation_data.yantras.length > 0) {
          setColumnName("Yantras");
          subTask.consultation_data.yantras.forEach((yantra) => {
            if (yantra.isSold) {
              const salePrice = parseFloat(yantra.salePrice);
              const purchasePrice = parseFloat(yantra.purchasePrice);
              const makingCharges = parseFloat(yantra.makingCharges);
              const profit = salePrice + makingCharges - purchasePrice;
              yantraProfits.push(profit);
              allRecords.push({
                consultation: subTask,
                stone: yantra,
                profit: profit,
              });
            }
          });
        }
      });
      setReportStoneData(allRecords);
      setfiltterData(allRecords);
    } else if (event == 3) {
      let allRecords = [];
      initialLoadedData.map((subTask, index) => {
        const crystalProfits = [];
        if (subTask.consultation_data.crystals && subTask.consultation_data.crystals.length > 0) {
          setColumnName("Crystals");
          subTask.consultation_data.crystals.forEach((crystal) => {
            if (crystal.isSold) {
              const salePrice = parseFloat(crystal.salePrice);
              const purchasePrice = parseFloat(crystal.purchasePrice);
              const makingCharges = parseFloat(crystal.makingCharges);
              const profit = salePrice + makingCharges - purchasePrice;
              crystalProfits.push(profit);
              allRecords.push({
                consultation: subTask,
                stone: crystal,
                profit: profit,
              });
            }
          });
        }
      });
      setReportStoneData(allRecords);
      setfiltterData(allRecords);
    }
  };

  const handleTableChange = (action, tableState) => {
    let totalProfit = 0;
    tableState.displayData.map((data) => {
      totalProfit = totalProfit + data.data[6];
    });
    setTotalProfitOfStones(totalProfit);
  };

  const options = {
    filter: true,
    // filterType: "dropdown",
    responsive: "standard",
    // fixedHeader: true,
    // fixedSelectColumn: false,
    // tableBodyHeight: "400px"
    selectableRows: false,
    search: true,
    center: true,
    downloadOptions: {
      filename: "profit.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onTableChange: handleTableChange,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              paddingLeft: "20px",
              backgroundColor: "#fff",
              color: "#000",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "13px",
              fontSize: "15px",
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h6">Profit Table</ArgonTypography>
            </ArgonBox>
            <Grid
              container
              columnSpacing={3}
              rowSpacing={0}
              style={{ paddingLeft: "20px", paddingBottom: "5px" }}
            >
              <Grid item xs={12} sm={2} mt={1}>
                <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                  Date From&nbsp;
                </ArgonTypography>
                <ArgonInput
                  type="date"
                  placeholder="Given Name"
                  size="small"
                  value={startDate}
                  onChange={(event) => {
                    setStartDate(event.target.value);
                    setSearchError("");
                    setDoSearch(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2} mt={1}>
                <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                  Date To&nbsp;
                </ArgonTypography>
                <ArgonInput
                  type="date"
                  placeholder="Given Name"
                  size="small"
                  value={endDate}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                    setSearchError("");
                    setDoSearch(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1} mt={1}>
                <ArgonButton
                  color="info"
                  size="small"
                  onClick={handleDateRangeChange}
                  style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}
                >
                  {doSearch ? "clear" : "Search"}
                </ArgonButton>
              </Grid>
              <Grid item xs={12} sm={2}>
                <ArgonTypography variant="caption" color="text" fontWeight="bold" fontSize="12px">
                  Select Items
                </ArgonTypography>
                <select
                  style={{
                    height: "30px",
                    borderRadius: "8px",
                    border: "1px solid #cfcccc",
                    padding: "7px",
                    paddingBottom: "2px",
                    color: "#344767",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  value={dropDownValue}
                  onChange={(event) => {
                    handleStoneChange(event.target.value), setDropdownValue(event.target.value);
                  }}
                >
                  <option value="0" selected>
                    All Stones
                  </option>
                  <option value="1">Gemstone</option>
                  <option value="2">Yantra</option>
                  <option value="3">Crystal</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={2} mt={2}>
                <ArgonTypography fontWeight="large" style={{ fontSize: "20px" }}>
                  <b>{totalProfitOfStones}</b>
                </ArgonTypography>
                <ArgonTypography fontWeight="small" fontSize="small">
                  Total Profit(<CurrencyRupeeIcon></CurrencyRupeeIcon>)
                </ArgonTypography>
              </Grid>
            </Grid>
            <ArgonTypography
              variant="caption"
              style={{ color: "red", fontSize: "12px", paddingLeft: "20px", paddingBottom: "15px" }}
            >
              {searchError}
            </ArgonTypography>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable title={""} columns={columns} data={rows.reverse()} options={options} />
            </ThemeProvider>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomerProfitTable;
