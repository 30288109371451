// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Link } from "react-router-dom";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import React, { useState, useEffect } from "react";
import axios from "axios";
import "config";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Icon } from "@mui/material";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";

function GemstoneTable() {
  const [responseData, setResponseData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [filterRowData, setfilterRowData] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [doSearch, setDoSearch] = useState(false);
  const [searchError, setSearchError] = useState("");

  // useEffect hook to make the API call when the component mounts
  useEffect(() => {
    document.title = "Gemstone";
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(global.config.DATA.domain + "/gemstone")
      .then((response) => {
        if (response.data.status) {
          setResponseData(response.data.data);
          setfilterRowData(response.data.data);
        } else if (response.data.message == "No Data Found") {
          setResponseData([]);
          setfilterRowData([]);
        }
      })
      .catch((error) => {});
  }

  function confirmDelete(value) {
    if (confirm("Are you sure you want to detele this gemstone?")) {
      axios
        .get(global.config.DATA.domain + `/gemstone/delete/${value}`)
        .then((response) => {
          if (response.data.status) {
            fetchData();
          }
        })
        .catch((error) => {});
    } else {
    }
  }

  const { columns } = {
    columns: [
      { name: "Sr No", align: "center" },
      { name: "Name", align: "center" },
      { name: "Discription", align: "center" },
      {
        name: "Image",
        align: "center",
        options: {
          filter: false,
        },
      },
      {
        name: "ACTION",
        align: "center",
        options: {
          download: false,
          print:false,
          filter: false,
          sort: false,
        },
      },
    ],
  };

  const { rows } = {
    rows: filterRowData.map((subTask, index) => {
      const words = subTask.discription.split(' ').slice(0, 4);
      const shortenedDescription = words.join(' ') + '...';
      return {
        "Sr No": index + 1,
        Name: subTask.name,
        Discription: shortenedDescription,
        Image: (
          <img
            src={`${global.config.DATA.filePath}/../uploads/${subTask.image}`}
            style={{ width: 80, height: 80, objectFit: "cover" }}
          ></img>
        ),
        ACTION: (
          <ArgonBox display="flex" justifyContent="start" alignItems="center">
            <Link to={`/addGemstone?id=${subTask._id}`}>
              <ArgonTypography
                component="a"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                <Icon style={{ color: "black", fontSize: "20px" }}>edit</Icon>
              </ArgonTypography>
            </Link>
            <ArgonTypography
              component="a"
              variant="caption"
              color="secondary"
              fontWeight="small"
              pl={1}
              onClick={() => confirmDelete(subTask._id)}
            >
              <DeleteIcon style={{ color: "black", fontSize: "20px" }}></DeleteIcon>
            </ArgonTypography>
          </ArgonBox>
        ),
      };
    }),
  };

  const options = {
    filter: true,
    // filterType: "dropdown",
    responsive: "standard",
    // fixedHeader: true,
    // fixedSelectColumn: false,
    // tableBodyHeight: "400px"
    selectableRows: false,
    search: true,
    center: true,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              paddingLeft: "20px",
              backgroundColor: "#fff",
              color: "#000",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "13px",
              fontSize: "15px",
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h6">Gemstone List</ArgonTypography>
              <Link to="/addGemstone">
                <ArgonButton
                  color="info"
                  size="small"
                  style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}
                >
                  <Icon>add</Icon>Add Gemstone
                </ArgonButton>
              </Link>
            </ArgonBox>
            <Grid
              container
              columnSpacing={3}
              rowSpacing={0}
              style={{ paddingLeft: "20px", paddingBottom: "5px" }}
            ></Grid>
            <ArgonTypography
              variant="caption"
              style={{ color: "red", fontSize: "12px", paddingLeft: "20px", paddingBottom: "15px" }}
            >
              {searchError}
            </ArgonTypography>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable title={""} columns={columns} data={rows.reverse()} options={options} />
            </ThemeProvider>
          </Card>
        </ArgonBox>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GemstoneTable;
